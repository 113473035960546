import React from 'react'
import Firebase from 'firebase/app'
import { useDocument } from 'react-firebase-hooks/firestore'

import ErrorPage from 'views/_components/ErrorPage'
import { LoadingPage } from 'styles/loading'

// OK LISTEN UP
// basically, firestore seems to get users from the cache often,
// and when it does, it doesn't include any knownMemberOrgIds.
// I don't know why exactly, but it doesn't and that's that.
// When that happens, we assume it's new user (but it's often not)
// and we show onboarding, but just for a second.
// So
// UNLESS WE HAVE THE FULL USER INFO, WE SHOULD JUST SHOW LOADING
// Also we use SnapshotListenOptions to request that we're updated
// when Firebase has a non-cached version.
// This means that we show something eventually instead of loading forever sometimes

const EnsureUserLoaded = ({userId, children}) => {

  const [userSnapshot, userLoading, userError] = useDocument(
    Firebase.firestore().doc(`/users/${userId}`),
    { snapshotListenOptions: { includeMetadataChanges: true }, }
  )

  if (userLoading) {
    return <LoadingPage>Loading</LoadingPage>
  }
  
  if (userError) {
    return <ErrorPage error={userError} />
  }

  const user = userSnapshot.data()
  const isFromCache = userSnapshot.metadata && userSnapshot.metadata.fromCache

  if (isFromCache) {
    return <LoadingPage>Loading</LoadingPage>
  }

  return children({user})
}

export default EnsureUserLoaded
