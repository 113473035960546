const DEMO_CARDS = [
  {
    content: `This is a test idea!`,
  },
  {
    content: `Drag me onto another card to combine us`,
  },
  {
    content: `Drag me *past* another card to reorder me`,
  },
]

export default DEMO_CARDS
