import Firebase from 'firebase/app'

const moveCard = async ({ cardRef, destinationSlideRef }) => {  

  const destinationCardCount = await destinationSlideRef
    .collection('cards')
    .get()
    .then(reactionsSnap => reactionsSnap.docs.length)

  const oldCardData = await cardRef
    .get()
    .then(cardSnap => cardSnap.data())
  
  const oldCardReactions = await cardRef
    .collection('reactions')
    .get()
    .then(reactionsSnap => reactionsSnap.docs.map(reactionSnap => ({
      id: reactionSnap.id,
      ref: reactionSnap.ref,
      data: reactionSnap.data(),
    })))
  
  const newCardRef = destinationSlideRef
    .collection('cards')
    .doc(cardRef.id)  

  const newCardData = {
    ...oldCardData,
    order: destinationCardCount + 1,
  }
  
  // Batch write
  // https://firebase.google.com/docs/firestore/manage-data/transactions#batched-writes
  const batch = Firebase.firestore().batch()
  
  // create a new card with the old card's data
  // don't use helpers because we don't want to update timestamps, createdBy values, etc
  batch.set(newCardRef, newCardData)

  // for each reaction on the old card...
  oldCardReactions.forEach( oldCardReaction => {
    // ...create a reaction on the new card
    const newCardReactionRef = newCardRef.collection('reactions').doc(oldCardReaction.id)
    batch.set(newCardReactionRef, oldCardReaction.data)

    // ...delete the old reaction
    // (if we just left it, if the card moved back, it would still be there)
    batch.delete(oldCardReaction.ref)
  })

  // delete the old card
  batch.delete(cardRef)

  return batch.commit()
}

export default moveCard
