import React from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet'

const ErrorLayout = ({ title, children }) => {
  return <ErrorLayoutWrapper>

    <Helmet>
      <title>{title || 'Error'} | Shuffleboard</title>
    </Helmet>

    {children}

  </ErrorLayoutWrapper>
}

const ErrorLayoutWrapper = styled.div`
  padding: 2rem;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 30rem;
  margin: 0 auto;

  @media (max-width: 599px) {
    padding: 1rem;
  }
`

export default ErrorLayout
