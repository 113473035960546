import Firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/functions'
import { FirestoreProvider } from 'react-firestore'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/react'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import 'tippy.js/dist/tippy.css'

import 'styles/variables.css'
import 'styles/global.css'
import AppState from 'views/AppState'
import DebugState from 'views/DebugState'
import Router from 'views/Router'

console.log('Environment: '+process.env.REACT_APP_ENV)

if (process.env.REACT_APP_ENV === 'live') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
  })
  
  // https://app.logrocket.com/edodwo/shuffleboard/settings/integrations/
  LogRocket.init(process.env.REACT_APP_LOGROCKET_CODE)
  setupLogRocketReact(LogRocket)
  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra("sessionURL", sessionURL)
    })
  })
}

// connect our app to firebase 
const dbConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
}
Firebase.initializeApp(dbConfig)

window.Firebase = Firebase // for browser de-bugging

// Google Analytics
// https://github.com/react-ga/react-ga#api
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID)

ReactDOM.render(
  <FirestoreProvider firebase={Firebase}>
    <DebugState>
      <AppState>
        <Router />
      </AppState>
    </DebugState>
  </FirestoreProvider>, document.getElementById('app-root'))