import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import Clipboard from 'react-clipboard.js'

import GetSerializedReportData from 'actions/GetSerializedReportData'
import { Button } from 'styles/buttons'
import LogError from 'actions/LogError'

// https://stackoverflow.com/a/34890276/1061063
const groupByArray = function(xs, key) {
  return xs.reduce(function (rv, x) {
    let v = key instanceof Function ? key(x) : x[key];
    let el = rv.find((r) => r && r.key === v);
    if (el) {
      el.values.push(x);
    } else {
      rv.push({ key: v, values: [x] });
    }
    return rv;
  }, []);
}

const defaultColor = 'white'

// TODO - get a list of all authors on the board
// then assign each a unique color based on common colors
// ie: authorColor = STICKY_COLORS[index % STICKY_COLORS.length]
// const STICKY_COLORS = [
//   'lightyellow',
//   'yellow',
//   'orange',
//   'lightgreen',
//   'limegreen',
//   'green',
//   'teal',
//   'lightpink',
//   'pink',
//   'purple',
//   'red',
//   'lightblue',
//   'aqua',
//   'blue',
//   'black',
// ]

const ShareTableButton = ({ orgId, boardId }) => {
  const defaultButtonText = 'Copy cells'
  let [ copyTableText, setCopyTableText ] = useState(defaultButtonText)

  let [reportDataLoading, setReportDataLoading] = useState(undefined)
  let [reportDataError, setReportDataError] = useState(undefined)
  let [reportData, setReportData] = useState(undefined)

  useEffect( () => {
    setReportDataLoading(true)
    GetSerializedReportData({
      orgId,
      boardId,
    })
      .then( result => {
        setReportDataError(undefined)
        // debugger
        const slideData = result.board.slides
          .filter(slide => slide.type === 'cards')
          .map(slide => ({
            ...slide,
            cardGroups: groupByArray(slide.cards, 'createdBy'),
          }))
        setReportData(slideData)
      })
      .catch( error => {
        setReportDataError(error)
        setReportData(undefined)
        LogError('get report data for export', error, true)
      })
      .finally( () => {
        setReportDataLoading(false)
        console.log('setting loading false')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>
    <Clipboard
      data-clipboard-target={'#share-table'}
      onSuccess={() => {
        if (reportDataLoading || reportDataError) { return }
        setCopyTableText('Copied!')
        setTimeout( () => setCopyTableText(defaultButtonText), 1000)
      }}
      component="span"
    >
      <Button
        wide
        to=""
        secondary="secondary"
        disabled={reportDataLoading || reportDataError}
      >
        {copyTableText}
      </Button>  
    </Clipboard>

    { reportData &&
      <TableWrapper>
        <Table id="share-table">

          {/* start slides */}
          {reportData.map(slide => {
            return <Body key={slide.id}>
              <Row>
                <Cell color={defaultColor}>
                  {slide.prompt || 'Unnamed slide'}
                </Cell>
              </Row>

              {/* start authors */}
              {slide.cardGroups.map( (cardGroup, index) => {
                const authorColor = defaultColor
                return <Row key={index}>
                  <Cell  color={authorColor}>
                    {cardGroup.values[0].author.displayName}
                  </Cell>

                  {/* start ideas */}
                  {cardGroup.values.map( card => {
                    return <Cell key={card.id} color={authorColor}>
                      {card.content}
                      {card._voteCount > 0 && ` (${card._voteCount} vote${card._voteCount !== 1 ? 's' : ''})`}
                    </Cell>
                  })}
                  {/* end ideas */}

                </Row>
              })}
              {/* end authors */}

              <Row>{/* spacer */}</Row>
            </Body>
          })}
          {/* end slides */}

        </Table>
      </TableWrapper>
    }
  </>
}

const TableWrapper = styled.div`
  position: fixed;
  top: 0;
  left: -999999px;
  height: 300px;
  width: 300px;
  overflow: hidden;
`
const Table = styled.table`
  font-family: sans-serif;
  font-size: 12px;
  color: black;
`
const Body = styled.tbody`
  font-family: inherit;
  font-size: inherit;
  color: inherit;
`
const Row = styled.tr`
  font-family: inherit;
  font-size: inherit;
  color: inherit;
`
const Cell = styled.td`
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  
  background: ${p => p.color || defaultColor};
  word-wrap: break-word;
  vertical-align: top;
`

export default ShareTableButton
