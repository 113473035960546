const ROLE_TYPES = [
  {
    id: 'project-management',
    label: `Project management, coordinator, or community lead`,
  },
  {
    id: 'engineering',
    label: `Engineering/agile lead`,
  },
  {
    id: 'culture',
    label: `Human resources, culture, or D&I`,
  },
  {
    id: 'design',
    label: `Product, design, or UX`,
  },
  {
    id: 'growth',
    label: `Marketing or sales`,
  },
  {
    id: 'executive',
    label: `Executive/corporate leadership`,
  },
  {
    id: 'educator',
    label: `Educator/teacher`,
  },
  {
    id: 'other',
    label: `Something else`,
  },
]

export default ROLE_TYPES
