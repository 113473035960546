// Global state component that saves the current orgId
// Naive because it doesn't really know if the user has access or the org is real
// The state is at the top level of the app so that switching routes doesn't loose the org state

import React, { useEffect, useState } from 'react'
import Firebase from 'firebase/app'

import LogError from 'actions/LogError'
import updateUser from 'actions/updateUser'

export const AppStateContext = React.createContext()

const AppState = ({children}) => {

  const [orgId, setOrgId] = useState(null)
  const [auth, setAuth] = useState(null)
  
  const switchOrg = orgId => {
    if (auth && orgId) {
      console.log(`setting user's most recent org`)
      updateUser(auth.uid, { mostRecentOrgId: orgId })
        .catch( error => LogError('update the most recent org', error, true) )
    }
    setOrgId(orgId)
  }

  useEffect( () => {

    const handleAuthChange = auth => {
      setAuth(auth)
      if (!auth) {
        switchOrg(null)
        return
      }
    }

    const handleAuthChangeError = error => {
      LogError('update auth state/orgId in AppState', error, true)
      setAuth(null)
      switchOrg(null)
    }

    const unsubscribe = Firebase.auth().onAuthStateChanged(handleAuthChange, handleAuthChangeError)
    
    return () => unsubscribe()
  })

  // TODO - use this auth in other places instead of getting it all over again
  // may have to provide auth loading and error states though
  return <AppStateContext.Provider value={{ orgId, switchOrg, auth }}>
    {children}
  </AppStateContext.Provider>
}

export default AppState