import React from 'react'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import updateBoard from 'actions/updateBoard'
import ToggleSwitch from 'views/_components/ToggleSwitch'
import {
  DropdownMenuAction,
} from 'styles/dropdown'

const ShowCardAuthors = ({
  board,
  boardRef,
}) => {
  const cardAuthorsAreShown = board.displayOptions && board.displayOptions.showCardAuthors

  return <DropdownMenuAction onClick={() => {
    LogEvent('show-hide-card-authors')
    updateBoard(boardRef, {displayOptions: {showCardAuthors: !cardAuthorsAreShown}})
      .catch( error => LogError('change contributor visibility', error) )
  }}>
    <i className="material-icons-round">account_circle</i>
    Show card authors
    <ToggleSwitch on={cardAuthorsAreShown} />
  </DropdownMenuAction>
}

export default ShowCardAuthors