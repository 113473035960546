import Firebase from 'firebase/app'
import * as Promise from 'bluebird' // TODO - remove once we stop supporting IE? Do we support IE?

import OrderCardsOrSlides from 'actions/helpers/OrderCardsOrSlides'

const getSerializedReportData = async ({orgId, boardId}) => {
  let reportData = {}

  // reportData.organization = await getOrganization(orgId)
  reportData.subscribed = await getSubscriptionStatus(orgId)
  reportData.board = await getBoard({orgId, boardId})
  reportData.board.author = await getBoardRunner(reportData.board)
  reportData.board.members = await getBoardMembers(reportData.board)
  reportData.board.slides = await getSlides(reportData.board.ref)

  console.log('sending serialized report data...')

  return reportData
}

// helpers

// const getOrganization = async orgId => {
//   const org = await Firebase.firestore()
//     .doc(`organizations/${orgId}/`)
//     .get()
//     .then(snapshot => snapshot.data())
//   return org
// }

const getSubscriptionStatus = async orgId => {
  const subscription = await Firebase.firestore()
    .doc(`subscriptions/${orgId}/`)
    .get()
    .then(snapshot => snapshot.data())
  return subscription && subscription.subscribed
}

const getBoard = async ({orgId, boardId}) => {
  const board = await Firebase.firestore()
    .doc(`organizations/${orgId}/boards/${boardId}`)
    .get()
    .then(snapshot => ({
      id: snapshot.id,
      ref: snapshot.ref,
      exists: snapshot.exists,
      ...snapshot.data(),
    }))
  return board
}

const getBoardRunner = async board => {
  const boardRunner = await Firebase.firestore()
    .doc(`users/${board.createdBy}`)
    .get()
    .then(snapshot => snapshot.data())
  return boardRunner
}

const getBoardMembers = async board => {
  const membersSnap = await Firebase.firestore()
    .collection(`${board.ref.path}/members`)
    .get()

  const members = await Promise.all(membersSnap.docs.map(getBoardMember))
  return members.filter(m => m.id !== board.createdBy)
}

const getBoardMember = async memberSnap => {
  const memberUser = await Firebase.firestore()
    .doc(`users/${memberSnap.id}`)
    .get()
    .then(snapshot => snapshot.data())

  return {
    id: memberSnap.id,
    ref: memberSnap.ref,
    ...memberSnap.data(),
    ...memberUser,
  }
}

const getSlides = async boardRef => {
  const slidesSnap = await Firebase.firestore()
    .collection(`${boardRef.path}/slides`)
    .get()

  const slides = await Promise.all(slidesSnap.docs.map(getSlide))

  return slides.sort(OrderCardsOrSlides)
}

const getSlide = async slideSnap => {
  const slide = {
    id: slideSnap.id,
    ref: slideSnap.ref,
    ...slideSnap.data(),
  }
  if (slide.type === 'cards') {
    slide.cards = await getCards(slide.ref)
  }
  return slide
}

const getCards = async slideRef => {
  const cardsSnap = await Firebase.firestore()
    .collection(`${slideRef.path}/cards`)
    .get()

  const cards = await Promise.all(cardsSnap.docs.map(getCard))
  const topLevelCards = cards.filter(c => !c.parentCard).sort(OrderCardsOrSlides)
  return topLevelCards.map(tlc => ({
    ...tlc,
    childCards: cards.filter(c => c.parentCard === tlc.id),
  }))
}

const getCard = async cardSnap => {
  const card = {
    id: cardSnap.id,
    ref: cardSnap.ref,
    ...cardSnap.data(),
  }
  
  card.author = await getCardAuthor(card.createdBy)
  return card
}

const getCardAuthor = async uid => {
  const cardAuthor = await Firebase.firestore()
    .doc(`users/${uid}`)
    .get()
    .then(snapshot => snapshot.data())
  return cardAuthor
}

export default getSerializedReportData
