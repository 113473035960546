// make sure all cards/slides in the app are ordered correctly

const OrderCardsOrSlides = (a, b) => {
  if ( (a.order || a.order === 0) && (b.order || b.order === 0) ) {
    return a.order - b.order
  }

  // any time one item has an order and the other doesn't, show the item with order
  if (a.order || a.order === 0) {
    return a.order
  }
  if (b.order || b.order === 0) {
    return b.order
  }

  // all comparisons that don't have an order, sort by date created but keep them at end
  // https://stackoverflow.com/a/10124053
  if (a.createdOn && b.createdOn) {
    return (a.createdOn.toMillis() - b.createdOn.toMillis()) + 1*1000*1000
  }

  return 1*1000*1000*1000
}

export default OrderCardsOrSlides