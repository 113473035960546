import React from 'react'
import styled from 'styled-components/macro'

import BubbleHeadListItem from 'views/_components/BubbleHeadListItem'

const BubbleHeadList = ({
  users,
  small,
  large,
}) => {

  const MAX_HEADS = 10

  const heads = users
    .sort( (a,b) => {
      const aDate = a.createdOn || a.updatedOn
      const bDate = b.createdOn || b.updatedOn
      return bDate.toMillis() - aDate.toMillis()
    })
    .slice(0, MAX_HEADS)

  const headDelta = users.length - MAX_HEADS
  const numberOfHeadsNotShown = (headDelta > 0) && headDelta

  return <BubbleHeadListWrapper small={small}>
    {
      heads.map(userFragment => (
        <BubbleHeadListItem
          key={userFragment.id}
          userId={userFragment.id}
          small={small}
          large={large}
          absent={userFragment.absent}
          isFacilitator={userFragment.isFacilitator}
          />
      ))
    }
    {numberOfHeadsNotShown &&
      <OverflowHead small={small}>
        +{numberOfHeadsNotShown}
      </OverflowHead>
    }
  </BubbleHeadListWrapper>
}

const OverflowHead = styled.div`
  margin: 0 3px 0 0;
  display: inline-block;
  position: relative;
  user-select: none;

  width: ${       p => p.small ? '20px' : '24px'};
  height: ${      p => p.small ? '20px' : '24px'};
  line-height: ${ p => p.small ? '20px' : '24px'};
  font-size: ${   p => p.small ?  '9px' : '11px'};
  font-weight: 500;
  text-align: center;
  background-color: var(--background-item);
  background-size: cover;
  border-radius: 50%;
  text-transform: uppercase;
`

const BubbleHeadListWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0;
`

export default BubbleHeadList
