import { prepareDocForCreate } from './helpers/firestoreHelpers'

const createCard = async ({slideRef, values}) => {

  if (!values.order) {
    console.log(`getting num of cards for slideRef ${slideRef.id}`)
    values.order = await slideRef
      .collection('cards')
      .get()
      .then(snap => snap.size)
  }

  console.log(`values.order`, values.order)

  return slideRef
    .collection('cards')
    .add(prepareDocForCreate({
      _voteCount: 0,
      _votes: [],
      ...values,
    }))
}

export default createCard
