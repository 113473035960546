import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import Firebase from 'firebase/app'
import Moment from 'moment'
import Clipboard from 'react-clipboard.js'

import LogErrorAdmin from 'actions/LogErrorAdmin'
import AdminLayout from 'views/_layouts/AdminLayout'
import {
  AdminHeader,
  TwoCol,
  Table,
  TBody,
  Row,
  Cell,
  A,
  Empty,
  Copy,
} from 'styles/admin'

const AdminUser = () => {

  const history = useHistory()

  const location = useLocation()
  const searchParams = queryString.parse(location.search)
  const userId = searchParams?.userId && decodeURIComponent(searchParams.userId)
  const userEmail = searchParams?.userEmail && decodeURIComponent(searchParams.userEmail)

  const [isLoading, setIsLoading] = useState(false)
  const [userInfo, setUserInfo] = useState(null)

  const getUserInfo = () => {
    setIsLoading(true)
    const adminGetUserInfo = Firebase.functions().httpsCallable('adminGetUserInfo')
    adminGetUserInfo({ userId, userEmail })
      .then(result => {
        setUserInfo(result.data)
        setIsLoading(false)
        console.log(result.data)
      })
      .catch( error => {
        setUserInfo(null)
        setIsLoading(false)
        LogErrorAdmin('get user info', error)
      })
  }

  const logOutUser = async () => {
    if (!userInfo) {
      alert('Get user info first')
    }
    const adminRevokeRefreshTokensForUser = Firebase.functions().httpsCallable('adminRevokeRefreshTokensForUser')
    adminRevokeRefreshTokensForUser({ userId: userInfo.uid })
      .then( () => {
        alert(`OK - revoked refresh tokens for user`)
      })
      .catch( error => {
        LogErrorAdmin('revoke refresh token', error)
        alert(`ERROR - could not log user out. See console for details.`)
      })
  }

  const signInAsUser = async event => {
    event.preventDefault()
    const adminSignInAsUser = Firebase.functions().httpsCallable('adminSignInAsUser')
    adminSignInAsUser({ userId: userInfo.uid, userEmail: userInfo.email })
      .then(result => Firebase.auth().signInWithCustomToken(result.data.token) )
      .then( () => history.push('/boards') )
      .catch( error => LogErrorAdmin('sign in with this user id', error) )
  }

  useEffect( () => {
    if (!userInfo && (userId || userEmail)) {
      getUserInfo()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, userId, userEmail])

  let email, emailUsername, emailDomain, emailDomainRoot
  if (userInfo) {
    email = userInfo.email
    emailUsername = email.split('@')[0]
    emailDomain = email.split('@')[1]
    emailDomainRoot = email.split('@')[1].split('.')[0]
  }

  return <AdminLayout title="User">

    {isLoading ? 'loading' :
    !userInfo ? '<-- type info' :
      <div>

        <AdminHeader>
          <div>
            <img
              src={userInfo.photoURL || 'https://cultureamp.design/static/a489d86dba895745f93a8d1268fe713f/avatar.svg'}
              alt={userInfo.displayName}
              style={{width: '100px', height: '100px', }}
            />
          </div>
          <div>
            <p>User</p>
            <h1>{userInfo.displayName}</h1>
            <Clipboard component={Copy} data-clipboard-text={userInfo.id}>{userInfo.id}</Clipboard>
          </div>
        </AdminHeader>

        <TwoCol>
          <div>
            <Table>
              <TBody>
                <Row>
                  <Cell th>Email address</Cell>
                  <Cell>{userInfo.email}</Cell>
                </Row>
                <Row>
                  <Cell th>Email verified</Cell>
                  <Cell>{userInfo.emailVerified ? '💌 verified' : '📧 not verified'}</Cell>
                </Row>
                <Row>
                  <Cell th>Highest role</Cell>
                  <Cell>
                    {
                      userInfo.highestRole === 'owner' ? '👑 owner' :
                      userInfo.highestRole === 'member' ? '👩‍💼 member' :
                      userInfo.highestRole === 'participant' ? '🗣 participant' :
                      '???'
                    }
                  </Cell>
                </Row>
                <Row>
                  <Cell th>Auth provider</Cell>
                  <Cell>{userInfo.authProviders}</Cell>
                </Row>
                <Row>
                  <Cell th>Notifications</Cell>
                  <Cell>{userInfo.notifications || '(default)'}</Cell>
                </Row>
              </TBody>
            </Table>
          </div>
          <div>
            <button onClick={signInAsUser}>🔑 Sign in as this user</button>
            <br />
            <button onClick={logOutUser}>🥾 Log out from all sessions</button>
            <br />
            <A target="_blank" disabled={!userInfo.acContactId} href={`https://getshuffleboard.activehosted.com/app/contacts/${userInfo.acContactId}`}>🛄 ActiveCampaign contact</A>
            <br />
            <A target="_blank" href={`https://console.firebase.google.com/u/0/project/shuffleboard-${process.env.REACT_APP_ENV}/firestore/data~2Fusers~2F${userInfo.uid}`}>🔥 Firestore console</A>
            <br />
            <A target="_blank" href={`https://www.google.com/search?q=${email}`}>🔎 {email}</A>
            <br />
            <A target="_blank" href={`https://www.google.com/search?q=${emailUsername}`}>🔎 {emailUsername}</A>
            <br />
            <A target="_blank" href={`https://www.google.com/search?q=${emailDomainRoot}`}>🔎 {emailDomainRoot}</A>
            <br />
            <A target="_blank" href={`https://www.google.com/search?q=${emailDomain}`}>🔎 {emailDomain}</A>
            <br />
            <A target="_blank" href={`https://${emailDomain}`}>🌎 {emailDomain}</A>
            <br />
          </div>
        </TwoCol>

        <Table>
          <TBody>
            <Row>
              <Cell th>Creation&nbsp;time</Cell>
              <Cell>{Moment(userInfo.creationTime).fromNow()}</Cell>
              <Cell>{userInfo.creationTime}</Cell>
            </Row>
            <Row>
              <Cell th>Last&nbsp;refresh&nbsp;time</Cell>
              <Cell>{Moment(userInfo.lastRefreshTime).fromNow()}</Cell>
              <Cell>{userInfo.lastRefreshTime}</Cell>
            </Row>
            <Row>
              <Cell th>Last&nbsp;sign-in&nbsp;time</Cell>
              <Cell>{Moment(userInfo.lastSignInTime).fromNow()}</Cell>
              <Cell>{userInfo.lastSignInTime}</Cell>
            </Row>
          </TBody>
        </Table>

        <h2>Organizations ({userInfo.organizations.length || 0})</h2>

        {(!userInfo.organizations || userInfo.organizations.length === 0) ?
          <Empty>not a member of any organization</Empty>
          :
          <Table>
            <TBody>
              <Row>
                <Cell th>Current</Cell>
                <Cell th>ID</Cell>
                <Cell th>Name</Cell>
                <Cell th>Level</Cell>
                <Cell th>Joined</Cell>
                <Cell th></Cell>
              </Row>
              {userInfo.organizations.map(org => <Row key={org.id}>
                <Cell>
                  {org.id === userInfo.mostRecentOrgId && '👉'}
                </Cell>
                <Cell>
                  <Clipboard component={Copy} data-clipboard-text={org.id}>{org.id}</Clipboard>
                </Cell>
                <Cell>
                  <A href={`/admin/org?orgId=${org.id}`}>{org.name}</A>
                </Cell>
                <Cell>
                  {
                    org.level === 'owner' ? <span>👑&nbsp;owner</span> :
                    org.level === 'member' ? <span>👩‍💼&nbsp;member</span> :
                    org.level === 'participant' ? <span>🗣&nbsp;participant</span> :
                    '???'
                  }
                </Cell>
                <Cell>{Moment(org.joinedOn).fromNow()}</Cell>
                <Cell>{org.joinedOn}</Cell>
              </Row>)}
            </TBody>
          </Table>
        }

        <h2>Boards created ({userInfo.boardsCreated.length || 0})</h2>

        {(!userInfo.boardsCreated || userInfo.boardsCreated.length === 0) ?
          <Empty>no boards created</Empty>
          :
          <Table>
            <TBody>
              <Row>
                <Cell th>ID</Cell>
                <Cell th>Name</Cell>
                <Cell th>Created</Cell>
                <Cell th></Cell>
              </Row>
              {userInfo.boardsCreated.map(board => <Row key={board.id}>
                <Cell>
                  <Clipboard component={Copy} data-clipboard-text={board.id}>{board.id}</Clipboard>
                </Cell>
                <Cell>
                  <A href={`/admin/board?orgId=${board.orgId}&boardId=${board.id}`}>{board.name}</A>
                </Cell>
                <Cell>{Moment(board.createdOn).fromNow()}</Cell>
                <Cell>{board.createdOn}</Cell>
              </Row>)}
            </TBody>
          </Table>
        }

      </div>
    }
  </AdminLayout>
}

export default AdminUser
