import React from 'react'
import { FirestoreDocument } from 'react-firestore'
import styled from 'styled-components/macro'

import LogError from 'actions/LogError'
import FirebaseAuth from 'views/_util/FirebaseAuth'

const LoggedInAs = () => {

  return <LoggedInAsWrapper>
    <FirebaseAuth>
      { ({isLoading, error, auth}) => {
        if (isLoading) {
          return 'Loading'
        }
        if (error) {
          LogError('get your user information', error, true)
          return 'Log in error'
        }
        if (!auth) {
          return `You're not logged in` // should never happen
        } else {
          return <FirestoreDocument path={`/users/${auth.uid}`}>
            {({isLoading, error, data}) => {
              if (isLoading) {
                return 'Loading'
              }
              if (error) {
                LogError('get your user information', error, true)
                return 'Log in error'
              }

              return `Logged in as ${data.displayName}`
            }}
          </FirestoreDocument>
        }
      }}
    </FirebaseAuth>
  </LoggedInAsWrapper>
}

const LoggedInAsWrapper = styled.div`
  text-align: center;
  margin: .5rem 0;
  font-size: var(--s);
`

export default LoggedInAs
