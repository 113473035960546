import { saveAs } from 'file-saver'
import {
  Document,
  Paragraph,
  TextRun,
  Packer,
} from 'docx'

import LogError from 'actions/LogError'
import GetSerializedReportData from 'actions/GetSerializedReportData'

// style constants
const textPrimary = '454554'
const textSecondary = '73738c'
const fontFamilySans = 'Arial'
const fontFamilySerif = 'Constantia'

// util
const fontSize = pts => pts * 2

const getDocx = (props) => {
  console.log('getting docx...')
  return doDownload(props)
    .catch( error => LogError('generate docx', error) )
}

const doDownload = async ({ orgId, boardId }) => {
  
  // get report data
  const reportData = await GetSerializedReportData({ orgId, boardId })

  // set up empty docx file
  const doc = new Document({
    title: reportData?.board?.name || '',
    creator: reportData?.board?.author?.displayName || '',
    lastModifiedBy: 'Shuffleboard app - https://getshuffleboard.com/',
  })
  const sections = []

  // title
  const titleSection = getBoardTitleSection(reportData.board)
  sections.push(titleSection)

  // slides
  reportData.board.slides
    .filter(slide => slide.type === 'cards')
    .forEach(slide => {
      const slideSection = getSlideSection(slide)
      sections.push(slideSection)
    })

  // add all sections to the doc
  sections.forEach(section => {
    doc.addSection(section)
  })

  // export the file into a .docx file
  const blob = await Packer.toBlob(doc)

  // save file
  saveAs(blob, `${reportData.board.name} - Shuffleboard.docx`)
}

const getBoardTitleSection = board => {
  const children = []

  const titleText = new Paragraph({
    spacing: {
      before: 2000,
      after: 240,
    },
    children: [
      new TextRun({
        text: board.name || 'Unnamed board',
        font: fontFamilySans,
        size: fontSize(32),
        color: textPrimary,
        bold: true,
      }),
    ],
  })
  children.push(titleText)
  
  if (board?.author) {
    const subTitleText = new Paragraph({
      children: [
        new TextRun({
          text: `Hosted by ${board.author.displayName || ''}`,
          font: fontFamilySans,
          size: fontSize(14),
          color: textSecondary,
        }),
      ],
    })
    children.push(subTitleText)
  }

  return {
    properties: {},
    children,
  }
}

const getSlideSection = slide => {
  
  const children = []

  const slideTitleParagraph = new Paragraph({
    children: [
      new TextRun({
        text: slide.prompt || 'Unnamed slide',
        font: fontFamilySerif,
        size: fontSize(24),
        color: textPrimary,
        bold: true,
      }),
    ],
  })
  children.push(slideTitleParagraph)

  if (slide.subPrompt) {
    const slideSubtitleParagraph = new Paragraph({
      children: [
        new TextRun({
          font: fontFamilySans,
          text: slide.subPrompt,
          size: fontSize(18),
          color: textSecondary,
        }),
      ],
    })
    children.push(slideSubtitleParagraph)
  }

  if (slide.cards) {
    slide.cards.forEach(card => {
      children.push(getCardParagraph(card))
      if (card.childCards.length > 0) {
        const childCardParagraphs = getChildCardParagraphs(card.childCards)
        childCardParagraphs.forEach(childCardParagraph => children.push(childCardParagraph))
      }
    })
  }
  
  return {
    properties: {},
    children,
  }
}

const getCardParagraph = card => {
  const cardVoteCount = (card._voteCount) || 0
  const titleText = new TextRun({
    text: card.content || '',
    font: fontFamilySans,
    size: fontSize(14),
    color: textPrimary,
    spacing: {
      after: 80,
    },
  })
  const metaText = new TextRun({
    text: `${card.author ? card.author.displayName : 'Anonymous'} · ${cardVoteCount} vote${cardVoteCount !== 1 ? 's' : ''}`,
    font: fontFamilySans,
    size: fontSize(11),
    color: textSecondary,
    break: 1,
  })
  return new Paragraph({
    spacing: {
      before: 240,
    },
    children: [
      titleText,
      metaText,
    ],
  })
}

const getChildCardParagraphs = childCards => {
  return childCards.map(childCard => {
    const text = new TextRun({
      text: `${childCard.content} · ${childCard.author ? childCard.author.displayName : 'Anonymous'}`,
      font: fontFamilySans,
      size: fontSize(11),
      color: textSecondary,
    })
    return new Paragraph({
      bullet: { level: 0 },
      children: [ text ],  
    })
  })
}

export default getDocx