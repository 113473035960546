import React, { useState, useEffect } from 'react'
import Firebase from 'firebase/app'
import styled from 'styled-components/macro'
import TextareaAutosize from 'react-textarea-autosize'
import { useDebouncedCallback } from 'use-debounce'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import updateCard from 'actions/updateCard'
import deleteCard from 'actions/deleteCard'
import Dropdown from 'views/_components/Dropdown'
import {
  DropdownMenuAction,
  DropdownUpperRightWrapper,
  DropdownToggleWrapper,
} from 'styles/dropdown'

const ChildCard = ({
  cardAuthor,
  cardRef,
  card,
  isFacilitator,
}) => {

  const [ contentState, setContentState ] = useState(card.content)

  const updateContentInDatabaseDebounced = useDebouncedCallback( () => {
    console.log('update content in db')
    updateCard(cardRef, { content: contentState })
      .catch( error => LogError('update this card content', error) )
  }, 1000)

  // if we get new info from the database, set the state right away
  useEffect( () => {
    setContentState(card.content)
  }, [card.content])
  
  return <ChildCardWrapper>

    <div>

      {/* TODO - allow edits if you wrote the card? */}

      { isFacilitator ?
        <ChildCardContentEditable
          value={contentState}
          placeholder="Card content"
          maxLength={1000}
          onChange={event => {
            console.log('input in content')
            const content = event.target.value
            setContentState(content)
            updateContentInDatabaseDebounced(content)
          }}
        />
      :
        <ChildCardContent>{card.content}</ChildCardContent>
      }

      <ChildCardAuthor>{cardAuthor.displayName}</ChildCardAuthor>
    </div>

    { isFacilitator &&
      <DropdownUpperRightWrapper>
        <Dropdown
          toggle={
            <DropdownToggleWrapper>
              <i className="material-icons-round">more_vert</i>
            </DropdownToggleWrapper>
          }
        >
          { ({close}) => <>
            <DropdownMenuAction onClick={event => {
              event.stopPropagation()
              close && close()
              LogEvent('unstack-card')            
              updateCard(cardRef, {
                parentCard: Firebase.firestore.FieldValue.delete()
              })
                .catch( error => LogError('remove this card from this stack', error) )
            }}>
              <i className="material-icons-round">link_off</i>
              Unlink idea
            </DropdownMenuAction>

            <DropdownMenuAction destructive="destructive" onClick={event => {
              event.stopPropagation()
              const reallyDelete = window.confirm(`Are you sure you want to delete this card? You can't undo this.`)
              if (reallyDelete) {
                close && close()
                LogEvent('delete-card')              
                deleteCard(cardRef)
                  .catch( error => LogError('delete this child card', error) )
              }
            }}>
              <i className="material-icons-round">delete_outline</i>
              Delete card
            </DropdownMenuAction>
          </>}
        </Dropdown>
      </DropdownUpperRightWrapper>
    }
    
  </ChildCardWrapper>
}

const ChildCardWrapper = styled.div`
  position: relative;

  display: grid;
  grid-template-columns: auto max-content;
  grid-column-gap: 1rem;
  align-items: center;
  
  padding: .5rem 0;
  border-top: 1px solid var(--hairline);
  color: var(--text-primary);
  background: white;
`
const ChildCardContent = styled.span`
  display: block;
  background: none;
  color: var(--text-primary);
  font-size: var(--m);
  font-weight: 500;
`
const ChildCardContentEditable = styled(TextareaAutosize)`
  display: block;
  background: none;
  color: var(--text-primary);
  font-size: var(--m);
  font-weight: 500;
  
  font-family: var(--font-family-sans);
  line-height: 1.4;
  width: calc(100% - 2rem);
  margin: 0;
  /* padding: .5rem; */
  border: none;
  border-radius: 2px;
  resize: none;
  cursor: pointer;
  
  /* &:hover {
    box-shadow: inset 0 0 0 1px var(--hairline);
  } */
  
  outline: none;
  &:focus {
    box-shadow: inset 0 0 0 1px var(--hairline-dark);
  }
`
const ChildCardAuthor = styled.div`
  color: var(--text-secondary);
  font-size: var(--s);
`

export default ChildCard
