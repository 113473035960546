// provide data on current user's subscription

import React from 'react'
import { FirestoreDocument, FirestoreCollection } from 'react-firestore'

import { AppStateContext } from 'views/AppState'

const MAX_BOARDS = 2

// TODO - pass in an org ID for this?
const SubscriptionStatus = ({children}) => {
  
  return <AppStateContext.Consumer>
    {({orgId}) => {
      return <FirestoreDocument path={`subscriptions/${orgId}/`}>
        {({ isLoading, error, data }) => {
          if (isLoading) {
            return children({
              isLoading: true,
              error: null,
              subscribed: null,
              boardsRemaining: null,
            })
          }
          if (error) {
            return children({
              isLoading: false,
              error,
              subscribed: null,
              boardsRemaining: null,
            })
          }
          return <FirestoreCollection path={`organizations/${orgId}/boards`}>
            {({ isLoading, error, snapshot }) => {
              if (isLoading) {
                return children({
                  isLoading: true,
                  error: null,
                  subscribed: data.subscribed,
                  boardsRemaining: null,
                })
              }
              if (error) {
                return children({
                  isLoading: false,
                  error,
                  subscribed: data.subscribed,
                  boardsRemaining: null,
                })
              }
        
              let boards = snapshot.docs
              let boardsRemaining = 'Unlimited'

              if (!data.subscribed) {
                const legalBoards = boards.slice(0, MAX_BOARDS)
                boardsRemaining = Math.max(0, MAX_BOARDS - legalBoards.length)
              }

              return children({
                isLoading: false,
                error: null,
                subscribed: data.subscribed,
                freeAccount: Boolean(data._notes),
                boardsRemaining,
              })
            }}
          </FirestoreCollection>
        }}
      </FirestoreDocument>
    }}
  </AppStateContext.Consumer>
}

export default SubscriptionStatus
