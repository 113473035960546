import React, { useState, useEffect } from 'react'

import LogError from 'actions/LogError'
import OnboardingTourPopup from 'views/_components/OnboardingTourPopup'
import updateUser from 'actions/updateUser'
import { Button } from 'styles/buttons'

const BoardTourNavStep = ({
  user,
}) => {

  const [visible, setVisible] = useState(false)
  useEffect( () => {
    setTimeout( () => setVisible(true), 2.5*1000)
  })

  return <OnboardingTourPopup right="right" visible={visible}>
    <div style={{padding: '1.5rem 2rem'}} onClick={ () => {
      updateUser(user.id, { hasClosedBoardTourNavStep: true })
        .catch( error => LogError('end onboarding', error) )
    }}>
      <p>
        When you're ready, change slides here
      </p>
      <Button dark="dark" wide>OK</Button>
    </div>
  </OnboardingTourPopup>
}

export default BoardTourNavStep
