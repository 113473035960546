import styled from 'styled-components/macro'

const CloseX = styled.div`
  position: ${p => p.fixed ? 'fixed' : 'absolute'};
  right: ${p => p.shifted ? '-1.5rem' : '.5rem'};
  top: ${p => p.shifted ? '-1.5rem' : '.5rem'};

  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  box-shadow: ${p => p.shifted && '0 2px 4px var(--shadow)'};
  
  background: ${p => p.soft ? 'transparent' : 'white'};
  color: ${p => p.soft ? 'white' : 'var(--text-secondary)'};
  opacity: ${p => p.shifted ? 1 : p.soft ? .3 : .8};
  transition: opacity .2s ease;
  
  cursor: pointer;
  user-select: none;
  z-index: 10;
  
  &:hover {
    background: ${p => p.soft ? 'transparent' : 'var(--hover)'};
    opacity: ${p => p.soft ? .5 : 1};
  }

  @media(max-width: 599px) {
    right: .25rem;
    top: .25rem;
  }
`
const Well = styled.div`
  background: ${p => p.yellow ? 'var(--yellow-60)' : 'var(--well)'};
  padding: 1rem;
  margin: 0 auto 1rem;
  text-align: ${p => p.centered ? 'center' : 'left'};
  max-width: ${p => p.centered ? '30rem' : 'auto'};
  border-radius: 6px;
`
const Banner = styled.div`
  margin: 0 0 1rem;
  padding: 1rem;

  font-size: var(--s);
  font-weight: 500;
  color: var(--text-primary);
  background: white;
  border: 1px solid var(--hairline-dark);
  border-radius: 4px;
  box-shadow: 0 2px 4px var(--shadow);
`

export {
  CloseX,
  Well,
  Banner,
}
