import React, { useState } from 'react'

import Modal from 'views/_components/Modal'
import updateUser from 'actions/updateUser'
import LogError from 'actions/LogError'

// steps
import Slides from 'views/board/onboarding_modal/Slides'
import Invite from 'views/board/onboarding_modal/Invite'
import Drag from 'views/board/onboarding_modal/Drag'
import Feedback from 'views/board/onboarding_modal/Feedback'
import Reports from 'views/board/onboarding_modal/Reports'

const BoardOnboardingModal = ({user}) => {
  
  const steps = [
    Slides,
    Invite,
    Drag,
    Feedback,
    Reports,
  ]
  const [currentStep, changeStep] = useState(0)

  const StepComponent = steps[currentStep]
  
  return <Modal
    noPadding
    wide
    softCloseX
    onClose={ () => {
      updateUser(user.id, { hasClosedFirstBoardModal: true })
        .catch( error => LogError('end onboarding', error) )
    }}
  >
    <StepComponent
      user={user}
      currentStep={currentStep}
      changeStep={changeStep}
      stepCount={steps.length}
    />
  </Modal>
}

export default BoardOnboardingModal
