import React, { useState } from 'react'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import updateUser from 'actions/updateUser'
import { Button } from 'styles/buttons'
import { Input } from 'styles/forms'
import AccountLayout from 'views/_layouts/AccountLayout'

const NameInput = ({
  userId,
}) => {

  const [ value, setValue ] = useState('')
  const [ working, setWorking ] = useState(false)

  const setName = event => {
    event.preventDefault()
    setWorking(true)
    LogEvent('choose-name')
    updateUser(userId, {
      displayName: value,
    })
      .then( () => {
        setValue('')
      })
      .catch( error => {
        setWorking(false)
        LogError('update your user', error)
      })
  }

  return <AccountLayout>
    <h2 data-test-id="participant-user">What's your name?</h2>

    <form onSubmit={setName}>
      <Input
        type="text"
        name="displayName"
        maxLength={1000}
        wide="wide"
        placeholder="Your name"
        value={value}
        onChange={event => setValue(event.target.value)}
        required
        autoFocus
      />
      <Button
        wide
        big
        working={working}
        type="submit"
      >
        Take me to the board!
      </Button>
    </form>

  </AccountLayout>
}

export default NameInput
