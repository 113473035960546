import React, { useState } from 'react'
import Firebase from 'firebase/app'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import createInvite from 'actions/createInvite'
import { Button } from 'styles/buttons'
import { Input, Label } from 'styles/forms'

// WHY INVITES
// We must create and email invites (instead of having admins directly adding people to their org)
// because there's no guarantee the invitee already has an account.
// It also prevents junky orgs from adding people I guess.
// Otherwise we could probably just do this directly.

const InviteMember = ({
  orgId,
  debugging,
}) => {

  const [shouldSendInviteEmail, setShouldSendInviteEmail] = useState(true)
  const [isInvitingMember, setIsInvitingMember] = useState(false)
  const [inviteeEmail, setInviteeEmail] = useState('')

  const orgRef = Firebase.firestore().collection('organizations').doc(orgId)

  const onSubmit = event => {
    event.preventDefault()
    LogEvent('invite-member')  
    createInvite({ orgRef, inviteeEmail })
      .then( inviteRef => {
        // TODO - is this a race condition? what if the function triggers but there's no invite on the backend yet?
        const sendOrgInvitation = Firebase.functions().httpsCallable('sendOrgInvitation')
        sendOrgInvitation({ orgId, inviteId: inviteRef.id })
      })
      .then( () => {
        if (shouldSendInviteEmail) {
          console.log('sending email')
          setInviteeEmail('')
        }
        setIsInvitingMember(false)
      })
      .catch( error => {
        LogError('invite this member', error)
      })
  }

  if (!isInvitingMember) {
    return <Button onClick={() => setIsInvitingMember(true)}>
      Invite another facilitator
    </Button>
  }
  
  return <form onSubmit={onSubmit}>
    <Label>New facilitator email:</Label>
    <Input
      type="email"
      name="inviteeEmail"
      maxLength={1000}
      placeholder="person@example.com"
      required
      autoFocus
      value={inviteeEmail}
      onChange={event => setInviteeEmail(event.target.value)}
      />
    
    { debugging &&
      <>
        <input
          type="checkbox"
          checked={shouldSendInviteEmail}
          name="shouldSendInviteEmail"
          value={inviteeEmail}
          onChange={ event => setShouldSendInviteEmail(event.target.value)}
        />
        <span style={{color: 'red', margin: '0 1rem 0 0'}}>Send email</span>
      </>
    }

    <Button type="submit">Send invitation</Button>
    <Button tertiary="tertiary" onClick={() => setIsInvitingMember(false)}>Cancel</Button>
  </form>
}

export default InviteMember
