import React from 'react'

import LogEvent from 'actions/LogEvent'
import LogError from 'actions/LogError'
import updateUser from 'actions/updateUser'
import updateBoard from 'actions/updateBoard'
import {
  ToolbarButton,
  ToolbarButtonLabel,
  NavTooltip,
  HoverTarget,
} from 'styles/buttons'

const BoardVoter = ({
  user,
  board,
  boardRef,
  isFacilitator,
  slideType,
}) => {
  
  if (!isFacilitator) {
    return null
  }

  if (slideType !== 'cards') {
    return null
  }

  return <HoverTarget>
    <NavTooltip left="left">{ board.isInVoteMode ? 'Stop voting' : 'Start voting' }</NavTooltip>
    <ToolbarButton
      tabIndex="300"
      onClick={() => {
        updateUser(user.id, { hasClosedBoardTourSettingsStep: true })
          .catch( error => LogError('update this user', error, true) )

        if (board.isInVoteMode) {
          updateBoard(boardRef, { isInVoteMode: false })
            .catch( error => LogError('end vote mode', error) )
        } else {
          LogEvent('request-votes')
          updateBoard(boardRef, { isInVoteMode: true })
            .catch( error => LogError('start vote mode', error) )
        }
      }}
    >
      <i className="material-icons">
        { board.isInVoteMode ? 'thumb_up' : 'thumb_up_off_alt' }
      </i>
      <ToolbarButtonLabel>Vote</ToolbarButtonLabel>
    </ToolbarButton>
  </HoverTarget>
}

export default BoardVoter
