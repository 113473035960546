const SORT_PRESETS = [
  {
    id: 'VOTES',
    text: 'Most votes',
  },
  {
    id: 'TIME',
    text: 'First added',
  },
  {
    id: 'RANDOM',
    text: 'Randomize',
  },
  // {
  //   id: 'CUSTOM',
  //   text: 'Custom',
  // },
]

export default SORT_PRESETS
