import React from 'react'
import styled from 'styled-components/macro'

import TakeoverLayout from 'views/_layouts/TakeoverLayout'
import SortableCombinableList from '../SortableCombinableList'
import Card from './Card'
import defaultCards from './defaultCards'

const DragContainer = () => {

  const combineCards = (first, second) => {
    console.log('COMBINE THESE TWO ITEMS', first, second)
  }

  const persistCardPositions = (items) => {
    console.log('SAVING CARD STATE TO FIREBASE')
    console.log(items.map(item => item.props.card.color).join(' '))
  }

  const additionalProp = 'Hi'

  // items consist of an id and a props attr?
  const itemData = defaultCards.map(card => ({
    id: card.id,
    props: {
      additionalProp,
      card,
    },
  }))

  return <TakeoverLayout>
    <GroupWrapper>
      <SortableCombinableList
        defaultItems={itemData}
        ItemComponent={Card}
        combineItems={combineCards}
        persistItemPositions={persistCardPositions}
        canDrag={true}
        combinable={true}
        displayType={`inline-block`}
        itemMargin={'0 1rem .5rem 0'}
        />
    </GroupWrapper>
  </TakeoverLayout>
}

const GroupWrapper = styled.div`
  position: relative;
  padding: 2rem;
  background: #eee;
  width: 50rem;
  max-width: 100%;
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  max-height: 30rem;
`

export default DragContainer