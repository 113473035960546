import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import LogEvent from 'actions/LogEvent'
import {
  Button,
  // ButtonIconWrapper,
} from 'styles/buttons'

const UpgradeBanner = ({ isNotSubscribed }) => {
  const history = useHistory()

  if (!isNotSubscribed) {
    return null
  }

  return <UpgradeBannerWrapper onClick={ () => {
    LogEvent('click-subscription')
    history.push("/subscribe")
  }}>

    <UpgradeBannerTitle>
      Want to try this with your team?
    </UpgradeBannerTitle>

    <UpgradeBannerSub>
      Right now you are limited to 2 boards, 3 slides, and 3 participants. Upgrade for unlimited everything.
    </UpgradeBannerSub>

    <Button yellow>
      {/* <ButtonIconWrapper> */}
        {/* <i className="material-icons-round">star</i> */}
        Upgrade options
      {/* </ButtonIconWrapper> */}
    </Button>

  </UpgradeBannerWrapper>
}

const UpgradeBannerWrapper = styled.div`
  max-width: 40rem;
  margin: 1rem 0;
  padding: 1rem 1.5rem;
  background: var(--yellow-95);
  border-radius: 4px;
  cursor: pointer;

  @media(max-width: 599px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
`
const UpgradeBannerTitle = styled.h3`
  font-size: var(--m);
  font-weight: 600;
  color: var(--orange-20);
`
const UpgradeBannerSub = styled.p`
  font-size: var(--s);
  color: var(--orange-40);
`

export default UpgradeBanner
