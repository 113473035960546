import React from 'react'
import Firebase from 'firebase/app'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import updateBoard from 'actions/updateBoard'
import {
  DropdownMenuAction,
} from 'styles/dropdown'

const RenameBoard = ({
  close,
  isFacilitator,
  board,
  boardOrgId,
}) => {
  return <DropdownMenuAction onClick={() => {
    close && close()

    if (!isFacilitator) {
      return
    }
    const name = window.prompt(`What do you want to call this board?`, board.name)
    if (name === null) {
      return
    }
    const orgRef = Firebase.firestore().collection('organizations').doc(boardOrgId)
    const boardRef = orgRef.collection('boards').doc(board.id)
    LogEvent('rename-board')
    updateBoard(boardRef, { name })
      .catch( error => LogError('rename this board', error) )
  }}>
    <i className="material-icons-round">edit</i>
    Rename board
  </DropdownMenuAction>
}

export default RenameBoard
