// all the urls that should live on the marketing site
// these get redirected by the app router

const websiteRedirects = [

  // marketing
  {
    path: "/features",
    url: "/features",
  },
  {
    path: "/about",
    url: "/about",
  },
  {
    path: "/pricing",
    url: "/pricing",
  },


  // support
  {
    path: "/support",
    url: "/support",
  },
  {
    path: "/contact",
    url: "/contact",
  },
  {
    path: "/feedback",
    url: "/feedback",
  },
  {
    path: "/docs",
    url: "/support",
  },
  {
    path: "/learn",
    url: "/support",
  },

  // legal
  {
    path: "/terms",
    url: "/legal/terms",
  },
  {
    path: "/tos",
    url: "/legal/terms",
  },
  {
    path: "/terms-of-service",
    url: "/legal/terms",
  },
  {
    path: "/privacy",
    url: "/legal/privacy",
  },
  {
    path: "/privacy-policy",
    url: "/legal/privacy",
  },
  {
    path: "/dmca",
    url: "/legal/dmca",
  },
  {
    path: "/dmca-policy",
    url: "/legal/dmca",
  },
]

export default websiteRedirects
