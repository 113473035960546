// Helper functions for working with Firebase Firestore

import Firebase from 'firebase/app'

const prepareDocForCreate = doc => {

  if (!doc) {
    doc = {}
  }

  // timestamps
  doc.createdBy = Firebase.auth().currentUser ? Firebase.auth().currentUser.uid : null
  doc.createdOn = Firebase.firestore.Timestamp.now()

  return doc
}

const prepareDocForUpdate = doc => {

  if (!doc) {
    doc = {}
  }

  // timestamps
  doc.updatedBy = Firebase.auth().currentUser ? Firebase.auth().currentUser.uid : null
  doc.updatedOn = Firebase.firestore.Timestamp.now()

  // don't save the id as part of the document
  // ACTUALLY turns out we need to set ID now on boards for collectionGroup queries to work
  // delete doc.id

  return doc
}

export {
  prepareDocForCreate,
  prepareDocForUpdate,
}