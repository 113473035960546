import React from 'react'
import styled from 'styled-components/macro'

import BoardTourNavStep from 'views/board/onboarding_tour/BoardTourNavStep'
import {
  ToolbarButton,
  HoverTarget,
  NavTooltip,
  NavTooltipEyebrow,
} from 'styles/buttons'

const SlideNav = ({
  user,
  board,
  goToPrevSlide,
  goToNextSlide,
  prevSlideTitle,
  nextSlideTitle,
  isFacilitator,
  onFirstSlide,
  onLastSlide,
  numberOfSlides,
  currentSlideIndex,
  participantsMayRoam,
}) => {

  if (!isFacilitator && !participantsMayRoam) {
    return null
  }

  return <>
    { user &&
      isFacilitator &&
      board.isDemoBoard &&
      user.hasClosedFirstBoardModal &&
      user.hasSubmittedDemoBoardIdea &&
      !user.hasClosedBoardTourNavStep &&
      <BoardTourNavStep user={user} />
    }

    <SlideNavWrapper>

      <HoverTarget>
        {!onFirstSlide &&
          <NavTooltip>
            {prevSlideTitle}          
            <NavTooltipEyebrow>{currentSlideIndex} of {numberOfSlides}</NavTooltipEyebrow>
          </NavTooltip>
        }
        <ToolbarButton
          disabled={onFirstSlide}
          onClick={goToPrevSlide && goToPrevSlide}
          tabIndex="320"
        >
          <i className="material-icons-round">arrow_back</i>
        </ToolbarButton>
      </HoverTarget>

      <HoverTarget>
        {!onLastSlide &&
          <NavTooltip>
            {nextSlideTitle}
            <NavTooltipEyebrow>{currentSlideIndex + 2} of {numberOfSlides}</NavTooltipEyebrow>
          </NavTooltip>
        }
        <ToolbarButton
          disabled={onLastSlide}
          onClick={goToNextSlide && goToNextSlide}
          tabIndex="320"
        >
          <i className="material-icons-round">arrow_forward</i>
        </ToolbarButton>
      </HoverTarget>

    </SlideNavWrapper>
  </>
}

const SlideNavWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export default SlideNav
