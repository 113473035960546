import React, { useState } from 'react'
import Firebase from 'firebase/app'
import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import createBoard from 'actions/createBoard'
import createSlide from 'actions/createSlide'
import createCard from 'actions/createCard'
import updateOrg from 'actions/updateOrg'
import DEMO_CARDS from 'data/DemoCards'

import Modal from 'views/_components/Modal'
import { Button } from 'styles/buttons'
import {
  Label,
  Input,
} from 'styles/forms'

const TemplatePreview = ({
  orgRef,
  user,
  template,
  closeModal,
}) => {

  const history = useHistory()

  const defaultBoardName =  !template.name ? 'Brainstorm session' :
                            template.id === 'empty' ? 'Brainstorm session' :
                            template.id === 'demo' ? 'Test board' :
                            template.name

  const [boardName, setBoardName] = useState(defaultBoardName)
  const [isWorking, setIsWorking] = useState(false)

  const onSubmit = event => {
    
    event.preventDefault()

    LogEvent('add-board', template.id)
    
    setIsWorking(true)

    const isDemoBoard = template.id === 'demo'
    
    // TODO - break into /actions and make transaction
    createBoard({orgRef, isDemoBoard, values: {
      name: boardName,
    }})
      .then(async boardRef => {
        if (!template) {
          return boardRef
        }

        // make slides
        await Promise.all(template.slides.map( (slide, index) => {
          const values = {
            order: index,
            ...slide,
          }
          return createSlide({ boardRef, values })
        }))

        if (!isDemoBoard) {
          return boardRef
        }

        // make demo cards
        const slidesSnap = await Firebase.firestore().collection(`${boardRef.path}/slides`).get()
        
        if (slidesSnap.docs.length < 1) {
          return boardRef
        }

        const slidesInOrder = slidesSnap.docs
          .map(slideSnap => ({
            ref: slideSnap.ref,
            ...slideSnap.data(),
          }))
          .sort( (a,b) => a.createdOn.toMillis() - b.createdOn.toMillis() )
        const firstCardsSlide = slidesInOrder.find(slide => slide.type === 'cards')
        
        if (!firstCardsSlide) {
          const error = new Error("Error adding demo cards - no cards slide found")
          LogError('update this slide', error, true)
          return boardRef
        }

        await Promise.all( DEMO_CARDS.map( (card, index) => {
          const slideRef = firstCardsSlide.ref
          const values = {
            content: card.content,
            order: index,
          }
          return createCard({ slideRef, values })
        }))

        // flip flag
        await updateOrg(orgRef, { hasAddedDemoBoard: true })
        
        // need to return boardRef for the redirect
        return boardRef
      })

      // route to board url
      .then(boardRef => {
        history.push(`/b/${orgRef.id}/${boardRef.id}`)
      })
      .catch( error => LogError('create this board', error) )
  }

  if (!template) {
    return null
  }

  return <Modal narrow onClose={closeModal}>

    <h2 data-test-id="board-name-picker">Create a board</h2>

    <Form onSubmit={onSubmit}>

      <Label>Board name</Label>
      <InputBig
        required={true}
        type="name"
        maxLength={1000}
        autoFocus={true}
        defaultValue={boardName}
        onChange={ e => setBoardName(e.target.value) }
        />

      <br />

      <Button
        block
        wide
        big
        type="submit"
        working={isWorking}
      >
        Create board
      </Button>
    </Form>

  </Modal>
}

const Form = styled.form`
  margin: 0;
`
const InputBig = styled(Input)`
  font-size: var(--l);
  width: 100%;
  margin: 0 0 2rem;
`

export default TemplatePreview
