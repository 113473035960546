import React from 'react'
import { FirestoreCollection, FirestoreDocument } from 'react-firestore'

import { AppStateContext } from 'views/AppState'
import NewBoard from 'views/board/new_board/NewBoard'
import EnsureLoggedInAndInAnOrg from 'views/EnsureLoggedInAndInAnOrg'
import ErrorPage from 'views/_components/ErrorPage'
import { LoadingPage } from 'styles/loading'
import FirebaseAuth from 'views/_util/FirebaseAuth'
import TakeoverLayout from 'views/_layouts/TakeoverLayout'
import AppLayout from 'views/_layouts/AppLayout'

const NewBoardContainer = ({
  popup,
}) => {

  return <EnsureLoggedInAndInAnOrg>
    <AppStateContext.Consumer>
      { ({orgId}) => {
        
        return <FirestoreDocument path={`organizations/${orgId}/`}>
          {({ isLoading, error, data, snapshot }) => {
            if (isLoading) {
              return <LoadingPage>Loading</LoadingPage>
            }
            if (error) {
              return <ErrorPage error={error} />
            }
            
            const org = data
            const orgRef = snapshot.ref

            return <FirestoreCollection path={`organizations/${orgId}/boards`}>
              {({ isLoading, error, data, snapshot }) => {
                if (isLoading) {
                  return <LoadingPage>Loading</LoadingPage>
                }
                if (error) {
                  return <ErrorPage error={error} />
                }
                
                const boards = data
    
                return <FirebaseAuth>
                  { ({isLoading, error, auth}) => {
                    if (isLoading) {
                      return <LoadingPage>Loading</LoadingPage>
                    }
                    if (error) {
                      return <ErrorPage error={error} />
                    }
            
                    return <FirestoreDocument path={`users/${auth.uid}/`}>
                      {({ isLoading, error, data }) => {
                        if (isLoading) {
                          return <LoadingPage>Loading</LoadingPage>
                        }
                        if (error) {
                          return <ErrorPage error={error} />
                        }
                        
                        const user = data
      
                        return <NewBoard
                          user={user}
                          org={org}
                          orgRef={orgRef}
                          boards={boards}
                          popup={popup}
                          Parent={popup ? TakeoverLayout : AppLayout}
                        />
                        
                      }}
                    </FirestoreDocument>

                  }}
                </FirebaseAuth>
    
              }}
            </FirestoreCollection>
  
          }}
        </FirestoreDocument>
      }}
    </AppStateContext.Consumer>
  </EnsureLoggedInAndInAnOrg>
}

export default NewBoardContainer
