import React from 'react'
import styled from 'styled-components/macro'

const Card = ({
  card,
  additionalProp,
}) => {
  return <CardWrapper color={card.color}>
    {card.color}
    {' '}
    {additionalProp}
  </CardWrapper>
}

const CardWrapper = styled.div`
  padding: 1rem;
  background: ${p => p.color || 'black'};
  display: block;
  color: white;
`

export default Card