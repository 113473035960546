import React from 'react'

import LogError from 'actions/LogError'
import updateBoardMember from 'actions/updateBoardMember'

class BoardMembershipWatcher extends React.Component {
  
  componentDidMount () {
    updateBoardMember({
      boardRef: this.props.boardRef,
      userId: this.props.userId,
    })
      .catch( error => LogError('update this board member', error, true) )
  }
  
  render() {
    return null
  }
}

export default BoardMembershipWatcher
