import React from 'react'

import {
  DropdownMenuAction,
} from 'styles/dropdown'

const ShareAndExport = ({
  shareBoard,
  close,
}) => {

  return <>
    <DropdownMenuAction
      to=""
      as="a"
      target="_blank"
      onClick={ () => {
        close && close()
        shareBoard()
      }}
    >
      <i className="material-icons-round">supervisor_account</i>
      Export...
    </DropdownMenuAction>
  </>
}

export default ShareAndExport