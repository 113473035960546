// DOES NOT REQUIRE A USER TO BE IN AN ORG,
// ONLY THAT THEY ARE LOGGED IN
// This is because participants may also manage their
// email preferences

// TODO - refactor so that the rest of the app
// doesn't require you to be in an org either?

import React from 'react'
import { Redirect } from 'react-router-dom'

import FirebaseAuth from 'views/_util/FirebaseAuth'
import ErrorPage from 'views/_components/ErrorPage'
import { LoadingPage } from 'styles/loading'
import AccountLayout from 'views/_layouts/AccountLayout'
import ManageNotifications from 'views/settings/ManageNotifications'
import { AppLink } from 'styles/buttons'
import Chatbox from 'views/_components/Chatbox'

const EmailNotifications = () => {
  return <FirebaseAuth>
    { ({isLoading, error, auth}) => {
      if (isLoading) {
        return <LoadingPage>Loading</LoadingPage>
      }
      if (error) {
        return <ErrorPage error={error} />
      }
      if (!auth) {
        return <Redirect
          to={{
            pathname: "/login",
            search: `?redirectTo=${encodeURIComponent(window.location.href)}`,
            state: { message: "You have to log in to change your email preferences"}
          }}
        />
      }

      return <AccountLayout title="Email notifications">
        
        <h2>Email notifications</h2>
        
        <p>{auth.email}</p>

        <hr />
        
        <ManageNotifications auth={auth} />

        <hr />

        <p>
          Your changes are saved instantly.
          {' '}
        </p>

        <AppLink to="/settings">More settings...</AppLink>

        <Chatbox />

      </AccountLayout>

    }}
  </FirebaseAuth>
}

export default EmailNotifications
