const defaultCards = [
  {
    id:    'orange',
    color: 'orange',
  },
  {
    id:    'yellow',
    color: 'yellow',
  },
  {
    id:    'green',
    color: 'green',
  },
  {
    id:    'aqua',
    color: 'aqua',
  },
  {
    id:    'blue',
    color: 'blue',
  },
  {
    id:    'indigo',
    color: 'indigo',
  },
  {
    id:    'purple',
    color: 'purple',
  },
  {
    id:    'black',
    color: 'black',
  },
]

export default defaultCards