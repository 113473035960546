import React from 'react'
import { FirestoreDocument } from 'react-firestore'
import styled from 'styled-components/macro'
import BUBBLEHEAD_COLORS from 'data/BubbleheadColors'

const BubbleHeadListItem = ({
  userId,
  small,
  large,
  absent,
  isFacilitator,
}) => {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
  const colorIndex = chars.indexOf(userId?.charAt(0) || 'a')
  const colors = BUBBLEHEAD_COLORS[colorIndex]

  return <BubbleHeadWrapper absent={absent}>
    <FirestoreDocument path={`users/${userId}`}>
      {({isLoading, error, data}) => {
        const user = data
        return <BubbleHeadImage
          style={{backgroundImage: user && `url(${user?.photoURL})`}}
          title={user ? user?.displayName : 'Unknown user'}
          small={small}
          large={large}
          textColor={colors.textColor}
          hex={colors.hex}
        >
          {
            isLoading ? '...' :
            error ? '?' :
            !!user.photoURL ? '' :
            !user.displayName ? '' :
            user.displayName.split(" ").slice(0, 2).map(n => n[0]).join('')
          }
          {isFacilitator && 
            <BubbleHeadBadge>
              <i className="material-icons-round">star</i>
            </BubbleHeadBadge>
          }
        </BubbleHeadImage>
      }}
    </FirestoreDocument>
  </BubbleHeadWrapper>
}

const BubbleHeadWrapper = styled.div`
  margin: 0 3px 0 0;
  display: inline-block;
  position: relative;
  opacity: ${p => p.absent ? 0.5 : 1};
  user-select: none;
`
const BubbleHeadBadge = styled.div`
  display: block;
  width: 13px;
  height: 13px;
  background: white;
  border-radius: 50%;
  position: absolute;
  bottom: -4px;
  right: -4px;

  i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    line-height: 1;
    color: var(--orange-60);
  }
`
const BubbleHeadImage = styled.div`
  width:       ${ p => p.small ? '20px' : p.large ? '32px' : '24px'};
  height:      ${ p => p.small ? '20px' : p.large ? '32px' : '24px'};
  line-height: ${ p => p.small ? '20px' : p.large ? '32px' : '24px'};
  font-size:   ${ p => p.small ?  '8px' : p.large ? '12px' : '10px'};
  font-weight: 500;
  text-align: center;
  color: ${ p => p.textColor === 'light' ? 'white' : 'var(--text-primary)'};
  background-color: ${ p => p.hex || 'var(--background-item)'};
  background-size: cover;
  border-radius: 50%;
  text-transform: uppercase;
`

export default BubbleHeadListItem
