import React from 'react'
import Firebase from 'firebase/app'
import { FirestoreDocument } from 'react-firestore'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import updateOrg from 'actions/updateOrg'
import { AppLink } from 'styles/buttons'

const ChangeOrgName = ({ orgId, isOwner, }) => {

  const changeOrgName = (prevName) => {
    const name = window.prompt('What do you want to name this organization?', prevName)
    if (name === null) {
      return
    }
    const orgRef = Firebase.firestore().collection('organizations').doc(orgId)
    LogEvent('change-org-name')  
    updateOrg(orgRef, { name } )
      .catch( error => LogError('update your organization', error) )
  }

  return <FirestoreDocument path={`/organizations/${orgId}`}>
    {({isLoading, error, data}) => {
      if (isLoading) {
        return 'Loading'
      }
      if (error) {
        LogError('get your organization information', error)
        return 'error getting this org'
      }
      const organization = data
      
      return <div>
        {organization.name || 'Unknown org'}
        {isOwner &&
          <>
            {' · '}
            <AppLink to="" as="span" tertiary="tertiary" onClick={() => changeOrgName(organization.name)}>
              Change
            </AppLink>
          </>
        }
      </div>
    }}
  </FirestoreDocument>
}

export default ChangeOrgName
