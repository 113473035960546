import React from 'react'
import sizeMe from 'react-sizeme'

const Card = ({ card, Component }) => {
  return <Component style={{
      top: `${card.top}px`,
      left: `${card.left}px`,
      width: `${card.width}px`,
    }}>
      {card.content}
    </Component>
}

export default sizeMe({ monitorWidth: false, monitorHeight: true, noPlaceholder: true })(Card)