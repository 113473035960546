import React from 'react'
import styled from 'styled-components/macro'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import updateUser from 'actions/updateUser'
import sortCards from 'actions/sortCards'
import SORT_PRESET from 'data/SortPresets'
import Dropdown from 'views/_components/Dropdown'
import {
  ToolbarButton,
  ToolbarButtonLabel,
  NavTooltip,
  HoverTarget,
} from 'styles/buttons'

const BoardSorter = ({
  user,
  board,
  boardRef,
  isFacilitator,
  slideType,
  currentSlideId,
}) => {
  
  if (!isFacilitator) {
    return null
  }

  if (slideType !== 'cards') {
    return null
  }

  const button = <HoverTarget>
    {board.isInVoteMode ?
      <NavTooltip left="left">(End voting to sort)</NavTooltip>
    :
      <NavTooltip left="left">Sort by votes</NavTooltip>
    }
    <ToolbarButton
      disabled={board.isInVoteMode}
      tabIndex="300"
    >
      {/* flipped version of sort - https://materialdesignicons.com/ */}
      <svg style={{ width: '24px', height:'24px', transform: 'scaleY(-1)' }} viewBox="0 0 24 24">
        <path fill="currentColor" d="M19 7H22L18 3L14 7H17V21H19M2 17H12V19H2M6 5V7H2V5M2 11H9V13H2V11Z" />
      </svg>
      <ToolbarButtonLabel>Sort</ToolbarButtonLabel>
    </ToolbarButton>
  </HoverTarget>

  return <Dropdown toggle={button} top right>
    { ({close}) => {
      return <div>
        {SORT_PRESET.map(preset => {
          return <ActionOption
            key={preset.text}

            onClick={() => {
              close && close()
              const sortType = preset.id

              updateUser(user.id, { hasClosedBoardTourSettingsStep: true })
                .catch( error => LogError('update this user', error, true) )
      
              LogEvent('sort-cards')
              
              const slideRef = boardRef.collection('slides').doc(currentSlideId)
              sortCards(slideRef, sortType)
                .catch( error => LogError('sort these cards', error) )
      
            }}
          >
            {preset.text}
          </ActionOption>
        })}
      </div>
    }}
  </Dropdown>
}

const ActionOption = styled.div`
  display: block;
  margin: 0 -.5rem;
  padding: .5em;
  color: var(--brand-primary);

  font-size: var(--m);
  font-weight: 500;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background: var(--hover);
  }
  &:focus {
    box-shadow: 0 0 0 2px var(--blue-70);
  }
`

export default BoardSorter
