import React from 'react'
import { FirestoreDocument } from 'react-firestore'
import styled from 'styled-components/macro'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import NOTIFICATION_TYPES from 'data/NotificationTypes'
import updateUser from 'actions/updateUser'

// WARNING - they'll need to create an org to edit? Not great

const ManageNotifications = ({ auth }) => {
  return <FirestoreDocument path={`/users/${auth.uid}`}>
    {({isLoading, error, data}) => {
      if (isLoading) {
        return 'Loading'
      }
      if (error) {
        LogError('get your user information', error)
        return 'Error getting your profile'
      }
      const user = data

      return <>
        <p>Email me when...</p>

        {NOTIFICATION_TYPES.map( notificationType => {

          let checked
          if (typeof user.notifications === 'undefined' || typeof user.notifications[notificationType.id] === 'undefined') {
            checked = true
          } else {
            checked = user.notifications[notificationType.id]
          }

          return <NotificationTypeWrapper key={notificationType.id}>
            <Checkbox
              type="checkbox"
              checked={checked}
              onChange={ event => {
                const checked = !!event.target.checked

                const notifications = {
                  ...user.notifications,
                  [notificationType.id]: checked,
                }
                LogEvent('edit-notifications')              
                updateUser(auth.uid, { notifications } )
                  .catch( error => LogError('update your notification settings', error) )
              }}
            />
            <NotificationTypeDescription>{notificationType.description}</NotificationTypeDescription>
          </NotificationTypeWrapper>

        })}
      </>

    }}
  </FirestoreDocument>
}

const Checkbox = styled.input`
  margin: 4px 0;
`
const NotificationTypeWrapper = styled.label`
  padding: .5rem 0 0;
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: .75rem;
`
const NotificationTypeDescription = styled.div`
`

export default ManageNotifications
