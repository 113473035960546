import React from 'react'
import styled from 'styled-components/macro'

const InteractiveSlideBadge = () => {
  return <InteractiveSlideBadgeWrapper>
    <i className="material-icons-round">auto_fix_high</i>
    <span>Interactive</span>
  </InteractiveSlideBadgeWrapper>
}

const InteractiveSlideBadgeWrapper = styled.div`
  display: inline-block;
  padding: 1px 8px 2px;
  margin: 0;

  color: var(--text-secondary);
  font-size: var(--xs);
  background: var(--background-item);
  border-radius: 100px;
  font-weight: 500;

  span {
    margin: 0 0 0 .5rem;
  }
  i {
    font-size: 13px;
    position: relative;
    top: 2px;
  }
`

export default InteractiveSlideBadge
