import React, { useState, useEffect } from 'react'
import LogError from 'actions/LogError'

import OnboardingTourPopup from 'views/_components/OnboardingTourPopup'
import updateUser from 'actions/updateUser'
import { Button } from 'styles/buttons'

const BoardTourInputStep = ({
  user,
}) => {
  
  const [visible, setVisible] = useState(false)
  useEffect( () => {
    setTimeout( () => setVisible(true), 1*1000)
  })

  return <OnboardingTourPopup center="center" visible={visible}>
    <div
      style={{padding: '1.5rem 2rem'}}
      onClick={ () => {
        updateUser(user.id, { hasClosedBoardTourInputStep: true })
          .catch( error => LogError('end onboarding', error) )
      }}
    >
      <p>
        Try typing an answer here
      </p>

      <Button dark="dark" wide>OK</Button>
    </div>
  </OnboardingTourPopup>
}

export default BoardTourInputStep
