import React from 'react'
import { useHistory } from 'react-router-dom'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import logOut from 'actions/logOut'
import {
  DropdownMenuAction,
} from 'styles/dropdown'

const LogOut = ({
  close,
}) => {
  const history = useHistory()
  
  return <DropdownMenuAction
    onClick={ () => {
      close && close()

      const reallyLogOut = window.confirm(`Are you sure you want to log out?\n\nYou'll have to sign in or create another user to rejoin the board.`)
      if (!reallyLogOut) {
        return
      }

      LogEvent('log-out')
    
      logOut()
        .then( () => history.push('/login'))
        .catch( error => LogError('log you out', error) )
    }}
  >
    <i className="material-icons-round">logout</i>	
    <span>
      Log out
    </span>	
  </DropdownMenuAction>	
}

export default LogOut
