import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { FirestoreDocument } from 'react-firestore'
import queryString from 'query-string'

import { LoadingPage } from 'styles/loading'
import ErrorPage from 'views/_components/ErrorPage'
import FirebaseAuth from 'views/_util/FirebaseAuth'
import AcceptInvite from 'views/account/AcceptInvite'
import { AppStateContext } from 'views/AppState'

const AcceptInviteContainer = () => {

  const location = useLocation()
  const searchParams = queryString.parse(location.search)
  const { orgId, inviteId } = searchParams

  if (!orgId || !inviteId) {
    return <ErrorPage error={new Error('Invitation did not include invitation or organization id')} />
  }

  return <FirebaseAuth>
    { ({isLoading, error, auth}) => {
      if (isLoading) {
        return <LoadingPage>Loading</LoadingPage>
      }
      if (error) {
        return <ErrorPage error={error} />
      }
      if (!auth) {
        return <Redirect
          to={{
            pathname: "/signup",
            search: `?redirectTo=${encodeURIComponent(window.location.href)}`,
            state: { message: "Welcome! Let's create your account. This will only take a minute."}
          }}
        />
      }
      if (auth.isAnonymous) {
        return <Redirect
          to={{
            pathname: "/require_account",
            search: `?redirectTo=${encodeURIComponent(window.location.href)}`,
            state: { message: "You'll have to create an account (or log in) with the email address in your invitation to continue."}
          }}
        />
      }
      
      return <FirestoreDocument path={`organizations/${orgId}/invites/${inviteId}`}>
        {({ isLoading, error, data }) => {
          if (isLoading) {
            return <LoadingPage>Loading</LoadingPage>
          }
          if (error) {
            return <ErrorPage error={error} />
          }
          
          const invite = data
          
          // shouldn't happen because the invitation needs to exist for the permissions to work
          if (!invite) {
            return <ErrorPage error={new Error('Could not find this invitation')} />
          }

          return <FirestoreDocument path={`users/${invite.createdBy}/`}>
            {({ isLoading, error, data }) => {
              if (isLoading) {
                return <LoadingPage>Loading</LoadingPage>
              }
              if (error) {
                return <ErrorPage error={error} />
              }

              const inviter = data

              return <AppStateContext.Consumer>
                {({switchOrg}) => {
  
                  return <AcceptInvite
                    orgId={orgId}
                    inviteId={inviteId}
                    inviter={inviter}
                    switchOrg={switchOrg}
                  />

                }}
              </AppStateContext.Consumer>
      
            }}
          </FirestoreDocument>

        }}
      </FirestoreDocument>

    }}
  </FirebaseAuth>
}

export default AcceptInviteContainer
