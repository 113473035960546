import React, { useState, useEffect } from 'react'
import {
  DndContext,
  closestCorners,
  // KeyboardSensor,
  // PointerSensor,
  // useSensor,
  // useSensors,
  DragOverlay,
} from '@dnd-kit/core'
import {
  // arrayMove,
} from '@dnd-kit/sortable'

import DraggableItem from './DraggableItem'
import CombineItemDropArea from './CombineItemDropArea'
import MoveItemDropArea from './MoveItemDropArea'

/*

TODO
get combining working - how to do two separate droppable areas??
get clicks working (delays?)
feature parity with old version check
test mobile
evaluate importance of memo()

OPTIONS
remove sortable but still use DND
Remove grid display and use the layout engine I made so card contents never hit the card, only position during drag does

CONCERNS
Stability. WAIT until they do 1.0? Or at least remove beta? We can get our own previews working first. What does this give us?

COMBINING
Possible to have two separate droppables with two separate collision detections on the same card? One conservative for sorting and one greedy for combining?
Collision detection is part of... draggables? Nope it's part of the drag context, damn.
Sorting strategy we don’t actually need for combining cards so that’s good
Start with removing sortable library?

It’s nice to have sorting happen when cards are dragged far away and sorting still works. But is that really necessary if we allow dragging into new columns?

Maybe this will be no better than the original library with a preview added, but if we have to retest anyway, why not use the new one
How will we memoize cards? Set memo on cards for only re-rendering when the props besides position are changed... but that won’t prevent re-layout when reordering right? Or will it since react uses keys?

*/

const SortableCombinableList = ({
  defaultItems,
  ItemComponent,
  combineItems,
  persistItemPositions,
  canDrag,
  combinable,
  dragItemType,
  displayType,
  itemMargin,
}) => {

  const [items, setItems] = useState(defaultItems)
  const [activeId, setActiveId] = useState(null)

  // we need to update the draggable cards when we get new card props (ie on some db change)
  useEffect( () => {
    setItems(defaultItems)
  }, [defaultItems])

  // const sensors = useSensors(
  //   useSensor(PointerSensor),
  //   useSensor(KeyboardSensor)
  // )

  const handleDragStart = event => {
    setActiveId(event.active.id)
  }
  
  const handleDragOver = event => {
    const { active, over } = event

    if (!active || !over) {
      //probably shouldn't happen but it just did
      return null
    }

    // if (active.id !== over.id) {
    //   setItems( items => {
    //     const oldIndex = items.findIndex(i => i.id === active.id)
    //     const newIndex = items.findIndex(i => i.id === over.id)
    //     const newItems = arrayMove(items, oldIndex, newIndex)
    //     return newItems
    //   })
    // }
  }
  
  const handleDragEnd = event => {
    setActiveId(null)
    persistItemPositions(items)
  }

  return <DndContext
    // sensors={sensors}
    collisionDetection={closestCorners}
    // modifiers={modifiers}
    onDragStart={handleDragStart}
    onDragOver={handleDragOver}
    onDragEnd={handleDragEnd}
  > 
    {items.map( (item, index) => (
      <DraggableItem
        key={item.id}
        id={item.id}
        canDrag={canDrag}
        combinable={combinable}
        displayType={displayType}
        itemMargin={itemMargin}
        someoneIsDragging={!!activeId}
      >
        { activeId && (
          <>
            <CombineItemDropArea id={item.id} combinable={combinable} />
            <MoveItemDropArea id={item.id} index={index} />
          </>
        ) }
        <ItemComponent {...item.props} />
      </DraggableItem>
    ))}
    <DragOverlay>
      { activeId &&
        <ItemComponent
          id={activeId}
          {...items.find( i => i.id === activeId).props}
        />
      }
    </DragOverlay>
  </DndContext>
}

export default SortableCombinableList