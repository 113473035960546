const SLIDE_TYPES = [
  {
    id: 'text',
    icon: 'article',
    title: 'Text slide',
    description: `Show a message, add breaks, or say thanks`,
    interactive: false,
  },
  {
    id: 'cards',
    icon: 'dashboard',
    title: 'Prompt slide',
    description: 'Ask a question and collect answers',
    interactive: true,
  },
]

export default SLIDE_TYPES
