import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import Firebase from 'firebase/app'
import Moment from 'moment'
import Clipboard from 'react-clipboard.js'

import LogError from 'actions/LogError'
import AdminLayout from 'views/_layouts/AdminLayout'
import {
  AdminHeader,
  TwoCol,
  Table,
  TBody,
  Row,
  Cell,
  A,
  Empty,
  Copy,
} from 'styles/admin'

const AdminOrg = () => {

  const history = useHistory()

  const location = useLocation()
  const searchParams = queryString.parse(location.search)
  const orgId = searchParams?.orgId && decodeURIComponent(searchParams.orgId)

  const [isLoading, setIsLoading] = useState(null)
  const [orgInfo, setOrgInfo] = useState(null)

  const getOrgInfo = () => {
    setIsLoading(true)
    const adminGetOrgInfo = Firebase.functions().httpsCallable('adminGetOrgInfo')
    adminGetOrgInfo({ orgId })
      .then(result => {
        setOrgInfo(result.data)
        setIsLoading(false)
        console.log(result.data)
      })
      .catch( error => {
        setOrgInfo(null)
        setIsLoading(false)
        LogError('get org info', error)
      })
  }

  useEffect( () => {
    if (!orgInfo && orgId) {
      getOrgInfo()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgInfo, orgId])


  const signInAsUser = async event => {
    event.preventDefault()
    const adminSignInAsUser = Firebase.functions().httpsCallable('adminSignInAsUser')
    adminSignInAsUser({ userId: orgInfo.createdBy })
      .then(result => Firebase.auth().signInWithCustomToken(result.data.token) )
      .then( () => history.push('/boards') )
      .catch( error => LogError('sign in with this user id', error) )
  }

  return <AdminLayout title="Org">
    
    {isLoading ? 'loading' :
    !orgInfo ? '<-- type info' :
      <div>

        <AdminHeader>
          <div>
            🏢
          </div>
          <div>
            <p>Organization</p>
            <h1>{orgInfo.name}</h1>
            <Clipboard component={Copy} data-clipboard-text={orgInfo.id}>{orgInfo.id}</Clipboard>
          </div>
        </AdminHeader>
        
        <TwoCol>
          <div>
            <Table>
              <TBody>
                <Row>
                  <Cell th>Onboarding internal/external</Cell>
                  <Cell>{orgInfo.onboardingInternalExternal || '(not defined)'}</Cell>
                </Row>
                <Row>
                  <Cell th>Has added demo board</Cell>
                  <Cell>{orgInfo.hasAddedDemoBoard ? 'yes' : 'no'}</Cell>
                </Row>
              </TBody>
            </Table>
          </div>
          <div>
            <button onClick={signInAsUser}>🔑 Sign in as org owner</button>
            <br />
            <A target="_blank" href={`https://console.firebase.google.com/u/0/project/shuffleboard-${process.env.REACT_APP_ENV}/firestore/data~2Forganizations~2F${orgInfo.uid}`}>🔥 Firestore org</A>
            <br />
            <A target="_blank" disabled={!orgInfo.subscription} href={`https://console.firebase.google.com/u/0/project/shuffleboard-${process.env.REACT_APP_ENV}/firestore/data~2Fsubscriptions~2F${orgInfo.uid}`}>🔥 Firestore subscription</A>
            <br />
            <A target="_blank" disabled={!orgInfo.subscription || orgInfo.subscription._notes} href={`https://dashboard.stripe.com/subscriptions/${orgInfo.subscription?.stripeSubscriptionId}`}>💰 Stripe subscription</A>
            <br />
            <A target="_blank" disabled={!orgInfo.subscription || orgInfo.subscription._notes} href={`https://dashboard.stripe.com/customers/${orgInfo.stripeCustomerId}`}>💰 Stripe customer</A>
            <br />
            <A target="_blank" disabled={!orgInfo.acContactId} href={`https://getshuffleboard.activehosted.com/app/accounts/${orgInfo.acAccountId}`}>🛄 ActiveCampaign account</A>
            <br />
            <A target="_blank" href={`https://www.google.com/search?q=${orgInfo.name}`}>🔎 {orgInfo.name}</A>
            <br />
          </div>
        </TwoCol>
        
        <Table>
          <TBody>
            <Row>
              <Cell th>Created&nbsp;on</Cell>
              <Cell>{Moment(orgInfo.createdOn).fromNow()}</Cell>
              <Cell>{orgInfo.createdOn}</Cell>
            </Row>
            <Row>
              <Cell th>Updated&nbsp;on</Cell>
              <Cell>{Moment(orgInfo.updatedOn).fromNow()}</Cell>
              <Cell>{orgInfo.updatedOn}</Cell>
            </Row>
          </TBody>
        </Table>

        <h2>Subscription</h2>
        {
          !orgInfo.subscription ?
            <Empty>not subscribed</Empty>
          :
          <Table>
            <TBody>
              <Row>
                <Cell th>Status</Cell>
                <Cell>{orgInfo.subscription._notes ? 'FRIEND' : 'PAID'}</Cell>
              </Row>
              <Row>
                <Cell th>Notes</Cell>
                <Cell>{!orgInfo.subscription._notes ? 'none' : orgInfo.subscription._notes}</Cell>
              </Row>
              <Row>
                <Cell th>Created&nbsp;on</Cell>
                <Cell>{Moment(orgInfo.subscription.createdOn).fromNow()} <br /> {orgInfo.subscription.createdOn}</Cell>
              </Row>
            </TBody>
          </Table>
        }

        <h2>Boards ({orgInfo.boards.length || 0})</h2>

        {(!orgInfo.boards || orgInfo.boards.length === 0) ?
          <Empty>no boards created</Empty>
          :
          <Table>
            <TBody>
              <Row>
                <Cell th>ID</Cell>
                <Cell th>Name</Cell>
                <Cell th>Created</Cell>
                <Cell th></Cell>
              </Row>
              {orgInfo.boards.sort( (a, b) => Moment(a.createdOn).valueOf() - Moment(b.createdOn).valueOf() ).map(board => <Row key={board.id}>
                <Cell>
                  <Clipboard component={Copy} data-clipboard-text={board.id}>{board.id}</Clipboard>
                </Cell>
                <Cell>
                  <A href={`/admin/board?orgId=${orgInfo.id}&boardId=${board.id}`}>{board.name}</A>
                </Cell>
                <Cell>{Moment(board.createdOn).fromNow()}</Cell>
                <Cell>{board.createdOn}</Cell>
              </Row>)}
            </TBody>
          </Table>
        }
        
        <h2>Members ({orgInfo.members.length || 0})</h2>
        {/* TODO - get names */}

        {(!orgInfo.members || orgInfo.members.length === 0) ?
          <Empty>no members</Empty>
          :
          <Table>
            <TBody>
              <Row>
                <Cell th>ID</Cell>
                <Cell th>Name</Cell>
                <Cell th>Creator?</Cell>
                <Cell th>Level</Cell>
                <Cell th>Joined</Cell>
                <Cell th></Cell>
              </Row>
              {orgInfo.members.map(member => <Row key={member.id}>
                <Cell>
                  <Clipboard component={Copy} data-clipboard-text={member.id}>{member.id}</Clipboard>
                </Cell>
                <Cell>
                  <A href={`/admin/user?userId=${member.id}`}>{member.displayName}</A>
                </Cell>
                <Cell>{member.id === orgInfo.createdBy && '⭐️'}</Cell>
                <Cell>{member.level}</Cell>
                <Cell>{Moment(member.createdOn).fromNow()}</Cell>
                <Cell>{member.createdOn}</Cell>
              </Row>)}
            </TBody>
          </Table>
        }
        
        <h2>Pending invites ({orgInfo.invites.length || 0})</h2>

        {(!orgInfo.invites || orgInfo.invites.length === 0) ?
          <Empty>no invites</Empty>
          :
          <Table>
            <TBody>
              <Row>
                <Cell th>ID</Cell>
                <Cell th>Invitee email</Cell>
                <Cell th>Date</Cell>
                <Cell th>Created by</Cell>
              </Row>
              {orgInfo.invites.map(invite => <Row key={invite.id}>
                <Cell>
                  <Clipboard component={Copy} data-clipboard-text={invite.id}>{invite.id}</Clipboard>
                </Cell>
                <Cell>
                  <A href={`/admin/user?userEmail=${invite.inviteeEmail}`}>{invite.inviteeEmail}</A><br />
                </Cell>
                <Cell>{Moment(invite.createdOn).fromNow()} <br /> {invite.createdOn}</Cell>
                <Cell>
                  <A href={`/admin/user?userId=${invite.createdBy}`}>{invite.createdBy}</A><br />
                </Cell>
                <Cell>
                  <A href={`/accept_invite?orgId=${orgInfo.id}&inviteId=${invite.id}`}>Accept invite</A><br />
                </Cell>
              </Row>)}
            </TBody>
          </Table>
        }

      </div>
    }
  </AdminLayout>
}

export default AdminOrg
