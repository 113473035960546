import React, { useState } from 'react'
import styled from 'styled-components/macro'

import LogError from 'actions/LogError'
import getCSV from 'actions/getCSV'
import getDocx from 'actions/getDocx'
import { Button } from 'styles/buttons'
import ReportLink from 'views/reports/ReportLink'
import Modal from 'views/_components/Modal'
import LogEvent from 'actions/LogEvent'
import ShareTableButton from 'views/board/sharing/ShareTableButton'

const ShareBoardModal = ({
  board,
  boardOrgId,
  onClose,
}) => {
  const [ isMakingCsv, setIsMakingCsv ] = useState(false)
  const [ isMakingDocx, setIsMakingDocx ] = useState(false)

  return <Modal onClose={onClose}>
    <h2>Export</h2>

    <ExportRow>
      <ExportRowMain>
        <div style={{color: 'var(--red-60)'}}>
          <i className="material-icons-round">picture_as_pdf</i>
        </div>
        <div>
          <ExportRowTitle>PDF report</ExportRowTitle>
          <ExportRowSub>Common format that everyone can open</ExportRowSub>
        </div>
      </ExportRowMain>
      <div>
        <ReportLink
          orgId={boardOrgId}
          boardId={board.id}
        >
          { ({ loading, error }) => {
            return <Button
              wide
              secondary="secondary"
              working={loading}
              onClick={ () => LogEvent('share-pdf') }
            >
              Download .pdf
            </Button>
          }}
        </ReportLink>
      </div>
    </ExportRow>

    <ExportRow>
      <ExportRowMain>
        <SimpleIcon role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.004 1.5q.41 0 .703.293t.293.703v19.008q0 .41-.293.703t-.703.293H6.996q-.41 0-.703-.293T6 21.504V18H.996q-.41 0-.703-.293T0 17.004V6.996q0-.41.293-.703T.996 6H6V2.496q0-.41.293-.703t.703-.293zM6.035 11.203l1.442 4.735h1.64l1.57-7.876H9.036l-.937 4.653-1.325-4.5H5.38l-1.406 4.523-.938-4.675H1.312l1.57 7.874h1.641zM22.5 21v-3h-15v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3h-15v3Z"/></SimpleIcon>
        <div>
          <ExportRowTitle>Microsoft Word document</ExportRowTitle>
          <ExportRowSub>For Microsoft Office users</ExportRowSub>
        </div>
      </ExportRowMain>
      <div>        
        <Button
          to=""
          wide
          secondary="secondary"
          working={isMakingDocx}
          onClick={ () => {
            LogEvent('share-docx')
            setIsMakingDocx(true)
            getDocx({ orgId: boardOrgId, boardId: board.id })
              .then( () => {
                setIsMakingDocx(false)
              })
              .catch( error => {
                setIsMakingDocx(false)
                LogError('create a docx file', error)
              })
          }}
        >
          Download .docx
        </Button>
      </div>
    </ExportRow>

    <ExportRow>
      <ExportRowMain>
        <div style={{color: 'var(--gray-40)'}}>
          <svg style={{width:'24px', height:'24px'}} viewBox="0 0 24 24">
            <path fill="currentColor" d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8L14 2M18 20H6V4H13V9H18V20M10 19L12 15H9V10H15V15L13 19H10" />
          </svg>
        </div>
        <div>
          <ExportRowTitle>Export CSV</ExportRowTitle>
          <ExportRowSub>Use with Excel, Google Sheets, etc</ExportRowSub>
        </div>
      </ExportRowMain>
      <div>
        <Button
          wide
          to=""
          secondary="secondary"
          working={isMakingCsv}
          onClick={ () => {
            LogEvent('share-csv')
            setIsMakingCsv(true)
            getCSV({ orgId: boardOrgId, boardId: board.id })
              .then( () => {
                setIsMakingCsv(false)
              })
              .catch( error => {
                setIsMakingCsv(false)
                LogError('create a CSV', error)
              })
          }}
        >
          Export .csv
        </Button>
      </div>
    </ExportRow>

    <ExportRow>
      <ExportRowMain>
        <div style={{color: 'var(--green-60)'}}>
          <svg style={{width:'24px', height:'24px'}} viewBox="0 0 24 24">
            <path fill="currentColor" d="M5,4H19A2,2 0 0,1 21,6V18A2,2 0 0,1 19,20H5A2,2 0 0,1 3,18V6A2,2 0 0,1 5,4M5,8V12H11V8H5M13,8V12H19V8H13M5,14V18H11V14H5M13,14V18H19V14H13Z" />
          </svg>
        </div>
        <div>
          <ExportRowTitle>Copy table cells</ExportRowTitle>
          <ExportRowSub>Paste into Miro, Sheets, Excel, etc</ExportRowSub>
        </div>
      </ExportRowMain>
      <div>
        <ShareTableButton orgId={boardOrgId} boardId={board.id} />
      </div>
    </ExportRow>

  </Modal>
}


const ExportRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-column-gap: 4rem;
  align-items: center;

  border-bottom: 1px solid var(--hairline);
  padding: 1rem 0;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  @media(max-width: 599px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
`
const ExportRowMain = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 1rem;
`
const ExportRowTitle = styled.div`
  color: var(--text-primary);
  font-size: var(--m);
  font-weight: 500;
  line-height: 1;
  margin: 0 0 .25rem;
`
const ExportRowSub = styled.div`
  color: var(--text-secondary);
  font-size: var(--s);
`
const SimpleIcon = styled.svg`
  color: inherit;
  width: 20px;
  height: 20px;

  path {
    fill: var(--brand-primary);
  }
`

export default ShareBoardModal
