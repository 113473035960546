import React from 'react'
import styled from 'styled-components/macro'
import { FirestoreDocument } from 'react-firestore'
import { useHistory} from 'react-router-dom'

import FirebaseAuth from 'views/_util/FirebaseAuth'
import OrgSwitcher from 'views/_components/OrgSwitcher'
import Dropdown from 'views/_components/Dropdown'
import { AppStateContext } from 'views/AppState'
import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import logOut from 'actions/logOut'
import {
  Button,
} from 'styles/buttons'
import {
  DropdownMenuAction,
} from 'styles/dropdown'
import BubbleHeadListItem from './BubbleHeadListItem'

const SidebarAccount = () => {
  const history = useHistory()

  return <FirebaseAuth>
    { ({isLoading, error, auth}) => {
      if (isLoading) {
        return ''
      }
      if (error) {
        LogError('get your user information', error)
        return 'log in error'
      }
      if (!auth) {
        return <Button onClick={() => history.push('/login')}>Log in</Button>
      }
      return <FirestoreDocument path={`/users/${auth.uid}`}>
        {({isLoading, error, data}) => {
          if (isLoading) {
            return 'Loading'
          }
          if (error) {
            LogError('get your user information', error)
            return 'Error getting your profile'
          }

          const user = data
          
          return <AppStateContext.Consumer>
            { ({ orgId, switchOrg }) => {

              return <FirestoreDocument path={`/organizations/${orgId}`}>
                {({isLoading, error, data}) => {
                  if (isLoading) {
                    return 'loading'
                  }
                  if (error) {
                    LogError('get this organization', error)
                    return 'error getting this org'
                  }

                  const organization = data
                
                  return <Dropdown
                    lowered
                    left
                    top
                    toggle={
                      <AccountWrapper data-test-id="account-menu">
                        <BubbleHeadListItem userId={auth.uid} />
                        <div>
                          <Title>{user.displayName}</Title>
                          <Subtitle>{organization.name}</Subtitle>
                        </div>
                      </AccountWrapper>
                    }
                  >
                    { ({close}) => {
                      return <div>
                        <DropdownMenuAction
                          data-test-id="account-menu-logout"
                          onClick={() => {
                            close && close()
                            LogEvent('log-out')
                            logOut()
                              .then( () => history.push('/login'))
                              .catch( error => LogError('sign out', error) )
                          }}
                        >
                          <i className="material-icons-round">logout</i>
                          Log out
                        </DropdownMenuAction>

                        <OrgSwitcher
                          user={user}
                          orgId={orgId}
                          switchOrg={switchOrg}
                        />
      
                      </div>
                    }}
                  </Dropdown>

                }}
              </FirestoreDocument>

            }}
          </AppStateContext.Consumer>
          
        }}
      </FirestoreDocument>

    }}
  </FirebaseAuth>
}

const AccountWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 10px;
  cursor: pointer;
  align-items: center;
  padding: .5rem;
  margin: .5rem;
  border-radius: 6px;
  user-select: none;

  transition: all .2s ease;
  &:hover {
    background: var(--tan-94);
  }
`
const Title = styled.div`
  color: var(--text-primary);
  font-weight: 600;
  font-size: var(--s);
  line-height: 1.2;
`
const Subtitle = styled.div`
  color: var(--text-secondary);
  font-size: var(--xs);
  line-height: 1.2;
`

export default SidebarAccount
