import React from 'react'
import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'

import AccountLayout from 'views/_layouts/AccountLayout'
import { Button } from 'styles/buttons'
import Chatbox from 'views/_components/Chatbox'

const ThankYou = () => {

  const history = useHistory()

  return <AccountLayout title="Thank you!">
    
    <ThankYouHero>
      <h1>
        <span role="img" aria-label="party">🎉</span>
        <br />
        <br />
        You're subscribed!
      </h1>
    </ThankYouHero>
    
    <hr />

    <p>I know you're probably ready to get back to work, but I just want to take a quick minute to say thank you.</p>
    <p>Shuffleboard is not a big, venture-backed startup. In fact, it's just one person (me!) working as hard as I can to make something useful for you.</p>
    <p>I really can't tell you just how much your support means!</p>
    <p><strong>Thank you</strong>.</p>
    <p>- Sam</p>

    <br />
    <br />
    <br />

    <Button wide big onClick={ () => history.push('/boards') }>Back to your boards</Button>

    <Chatbox />

  </AccountLayout>
}

const ThankYouHero = styled.div`
  padding: 1rem;
  text-align: center;
`

export default ThankYou
