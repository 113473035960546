import { prepareDocForCreate } from './helpers/firestoreHelpers'

const createMember = (orgRef, memberId, level) => {
  return orgRef
    .collection('members')
    .doc(memberId)
    .set(prepareDocForCreate({
      level,
    }))
}


export default createMember
