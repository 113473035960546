import React from 'react'
import styled from 'styled-components/macro'

const Card = ({
  card,
  isHoveredOver,
  additionalProp,
}) => {
  return <CardWrapper color={card.color} isHoveredOver={isHoveredOver}>
    {card.color}
    {' '}
    {additionalProp}
  </CardWrapper>
}

const CardWrapper = styled.div`
  position: relative;
  padding: 2rem;
  border-radius: 5px;
  background: ${p => p.color || 'black'};
  display: inline-block;
  color: white;
  font-weight: 600;
  box-shadow: ${p => p.isHoveredOver && '0 0 0 2px black'};
  width: 200px;
`

export default Card