import React, { useState } from 'react'
import Firebase from 'firebase/app'
import { FirestoreCollection, FirestoreDocument } from 'react-firestore'
import styled from 'styled-components/macro'
import Clipboard from 'react-clipboard.js'

import deleteOrgMember from 'actions/deleteOrgMember'
import Dropdown from 'views/_components/Dropdown'
import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import BubbleHeadListItem from 'views/_components/BubbleHeadListItem'
import {
  DropdownMenuAction,
  DropdownUpperRightWrapper,
  DropdownToggleWrapper,
} from 'styles/dropdown'
import deleteInvite from 'actions/deleteInvite'

// https://flaviocopes.com/how-to-uppercase-first-letter-javascript/
const capitalize = string => {
  if (typeof string !== 'string') return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const ManageOrgMembers = ({
  orgId,
  auth,
  debugging,
}) => {

  const [ isSending, setIsSending ] = useState(false)
  
  return <>
    <FirestoreCollection path={`organizations/${orgId}/members/`}>
      {({ isLoading, error, snapshot }) => {
        if (isLoading) {
          return 'Loading...'
        }
        if (error) {
          LogError('show your organization\'s members', error)
          return 'Error getting organization members - sorry! Try refreshing the page?'
        }
        
        const members = snapshot.docs.map(orgMemberSnap => ({
          id: orgMemberSnap.id,
          ref: orgMemberSnap.ref,
          ...orgMemberSnap.data(),
        }))

        return <MemberListWrapper>
          {members
          .sort( (a,b) => a.createdOn.toMillis() - b.createdOn.toMillis() )
          .map(member => {
            return <MemberWrapper key={member.id}>
              <FirestoreDocument path={`/users/${member.id}`}>
                {({isLoading, error, data}) => {
                  if (isLoading) {
                    return 'Loading member...'
                  }
                  if (error) {
                    LogError('show one of your member\'s information', error)
                    return 'Error getting this user - sorry! Try refreshing the page?'
                  }
              
                  const user = data
                  
                  return <>
                    <BubbleHeadListItem userId={member.id} />
                    <MemberTitle>{user.displayName || 'Anonymous member'}</MemberTitle>
                    <MemberSub>{member.level ? capitalize(member.level) : 'No level'}</MemberSub>

                    { (member.id !== auth.uid) && (member.level !== 'owner') &&
                      <DropdownUpperRightWrapper>
                        <Dropdown
                          toggle={<DropdownToggleWrapper><i className="material-icons-round">more_vert</i></DropdownToggleWrapper>}
                        >
                          { ({close}) => {
                            return <div>
                              <DropdownMenuAction destructive="destructive" onClick={event => {
                                event.preventDefault()
                                const reallyRemove = window.confirm(`Are you sure you want to remove this member? They will no longer have access to your team boards.`)
                                close && close()
                                if (reallyRemove) {
                                  LogEvent('remove-org-member')
                                  deleteOrgMember(member.ref)
                                    .catch( error => LogError('remove this member', error) )
                                }
                              }}>
                                <i className="material-icons-round">delete_outline</i>
                                Remove member
                              </DropdownMenuAction>
                            </div>
                          }}
                        </Dropdown>
                      </DropdownUpperRightWrapper>
                    }
                  </>

                }}
              </FirestoreDocument>
            </MemberWrapper>

          })}
        </MemberListWrapper>

      }}
    </FirestoreCollection>
    <FirestoreCollection path={`organizations/${orgId}/invites/`}>
      {({ isLoading, error, snapshot }) => {
        if (isLoading) {
          return 'Loading...'
        }
        if (error) {
          LogError('show one of your invitations', error)
          return 'Error getting organization invites - sorry! Try refreshing the page?'
        }
        
        const invites = snapshot.docs.map(inviteSnap => ({
          id: inviteSnap.id,
          ref: inviteSnap.ref,
          ...inviteSnap.data(),
        }))

        if (invites.length === 0) {
          return null
        }

        return <MemberListWrapper>
          <br />
          <h4>Invitations</h4>
          {invites
          .sort( (a,b) => a.createdOn.toMillis() - b.createdOn.toMillis() )
          .map(invite => {
            return <MemberWrapper key={invite.id}>
              <i className="material-icons-round">mail_outline</i>
              <MemberTitle>{invite.inviteeEmail || 'No email'}</MemberTitle>
              <MemberSub green>Invitation sent</MemberSub>

              <DropdownUpperRightWrapper>
                <Dropdown
                  toggle={
                    <DropdownToggleWrapper data-test-id="invite-options">
                      <i className="material-icons-round">more_vert</i>
                    </DropdownToggleWrapper>
                  }
                >
                  { ({close}) => {
                    return <div>

                      <DropdownMenuAction disabled={isSending} onClick={event => {
                        event.preventDefault()
                        if (isSending) {
                          return
                        }
                        setIsSending(true)
                        const sendOrgInvitation = Firebase.functions().httpsCallable('sendOrgInvitation')
                        sendOrgInvitation({ orgId, inviteId: invite.id })
                          .then(() => {
                            setIsSending(false)
                            close()
                            alert('Invite resent!')
                          })
                          .catch( error => {
                            setIsSending(false)
                            LogError('send this invite', error)
                          })
                      }}>
                        <i className="material-icons-round">send</i>
                        Resend invite
                      </DropdownMenuAction>

                      {/* for UI tests, we should not encourage sharing this link (at least until we require email confirmation) */}
                      {debugging &&
                        <Clipboard
                          data-clipboard-text={`${window.origin}/accept_invite?orgId=${orgId}&inviteId=${invite.id}`}
                          component={DropdownMenuAction}
                          onSuccess={close}
                          button-data-test-id="copy-invite-link"
                        >
                          <i className="material-icons-round">copy</i>
                          Copy invite link
                        </Clipboard>
                      }

                      <DropdownMenuAction destructive="destructive" onClick={event => {
                        event.preventDefault()
                        const reallyRemove = window.confirm(`Are you sure you want to cancel this invitation?\n\nThis person will not be able to join your team unless you reinvite them.`)
                        close && close()
                        if (reallyRemove) {
                          LogEvent('delete-invite')                        
                          deleteInvite(invite.ref)
                            .catch( error => LogError('remove this invite', error) )
                        }
                      }}>
                        <i className="material-icons-round">delete_outline</i>
                        Cancel invite
                      </DropdownMenuAction>

                    </div>
                  }}
                </Dropdown>
              </DropdownUpperRightWrapper>
            </MemberWrapper>

          })}
        </MemberListWrapper>

      }}
    </FirestoreCollection>
  </>
}

const MemberListWrapper = styled.div`
`
const MemberWrapper = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr 1fr 2rem;
  grid-column-gap: .5rem;
  align-items: center;

  position: relative;
  border-bottom: 1px solid var(--hairline);
  padding: .75rem 0;

  &:last-child {
    margin: 0 0 1rem;
  }

  @media(max-width: 599px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
`
const MemberTitle = styled.div`
  font-size: var(--m);
  font-weight: 500;
  color: var(--text-primary);
`
const MemberSub = styled.div`
  font-size: var(--s);
  color: ${p => p.green ? 'var(--green-50)' : 'var(--text-secondary)'};
`

export default ManageOrgMembers
