import React from 'react'
import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'

const DraggableItem = ({
  id,
  canDrag,
  displayType,
  itemMargin,
  children,
}) => {
    
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useDraggable({
    id,
    disabled: !canDrag,
  })
  
  const style = {
    position: 'relative',
    margin: itemMargin || '0',
    display: displayType || 'inline-block',
    touchAction: 'none',
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  }

  return <div
    ref={setNodeRef}
    {...listeners}
    {...attributes}
    style={style}
  >
    {children}
  </div>
}

export default DraggableItem