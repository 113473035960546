import Firebase from 'firebase/app'

const logInAnonymously = () => {
  return Firebase.auth()
    .signInAnonymously()
    .then( result => {
      console.log(`logged in anonymously as ${result.user.uid}`)
    })
}

export default logInAnonymously
