// send Google Analytics event on page change
import ReactGA from 'react-ga'

const PageChangeListener = ({location}) => {
  const page = location.pathname + location.search
  console.log('new pageview: ', page)
  
  // Track Google Analytics page view for every route
  // https://github.com/react-ga/react-ga/issues/122#issuecomment-319546248
  ReactGA.set({ page })
  ReactGA.pageview(page)

  // Track ActiveCampaign page view
  // https://community.activecampaign.com/discussion/6617
  if (window.vgo) {
    window.vgo('update')
    window.vgo('process')
  } else {
    console.log('Cannot log ActiveCampaign page view, no "vgo" function')
  }

  return null
}

export default PageChangeListener