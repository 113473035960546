import React, { useState } from 'react'
import styled from 'styled-components/macro'

import BoardSettings from 'views/board/settings/BoardSettings'
import BubbleHeadList from 'views/_components/BubbleHeadList'
import InviteMenu from 'views/_components/InviteMenu'
import Modal from 'views/_components/Modal'
import {
  Button,
  HoverTarget,
  NavTooltip,
} from 'styles/buttons'

const SlideTopBar = ({
  user,
  board,
  boardRef,
  isFacilitator,
  participants,
  boardOrgId,
  slides,
  goToPrevSlide,
  isNotSubscribed,
  addSlide,
  leaveBoard,
  shareBoard,
}) => {
  const [showJoinModal, setShowJoinModal] = useState(false)

  return <>

    <SlideTopBarWrapper>

      <SlideTopBarLeft>
        <HoverTarget>
          <NavTooltip top="top" left="left">Exit board</NavTooltip>
          <SlideTitle onClick={leaveBoard}>
            <BackIcon>
              <i className="material-icons">chevron_left</i>
            </BackIcon>
            <MiniLogo>S</MiniLogo>
            <BoardName>{board.name || 'Unnamed board'}</BoardName>
          </SlideTitle>
        </HoverTarget>
      </SlideTopBarLeft>

      <SlideTopBarRight>

        <BoardSettings
          user={user}
          boardOrgId={boardOrgId}
          boardRef={boardRef}
          board={board}
          slides={slides}
          isFacilitator={isFacilitator}
          goToPrevSlide={goToPrevSlide}
          isNotSubscribed={isNotSubscribed}
          addSlide={addSlide}
          leaveBoard={leaveBoard}
          shareBoard={shareBoard}
        />

        <BubbleHeadSlideWrapper onClick={ () => setShowJoinModal(!showJoinModal)}>
          <BubbleHeadList
            users={participants}
            isFacilitator={isFacilitator}
            />
          <Button
            small
            secondary={participants.length > 1}
            style={{marginLeft: '.5rem'}}
          >
            Invite
          </Button>
        </BubbleHeadSlideWrapper>

      </SlideTopBarRight>

    </SlideTopBarWrapper>

    {showJoinModal && 
      <Modal narrow onClose={() => setShowJoinModal(false)}>
        <InviteMenu
          participants={participants}
          isNotSubscribed={isNotSubscribed}
          isFacilitator={isFacilitator}
        />
      </Modal>
    }

  </>
}

const SlideTopBarWrapper = styled.div`
  display: grid;
  grid-template-columns: auto max-content max-content;
  justify-content: stretch;
  align-items: center;
`
const SlideTopBarLeft = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
`
const SlideTopBarRight = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-column-gap: .5rem;
  align-items: center;
`
const MiniLogo = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;

  line-height: 16px;
  text-align: center;
  font-size: 12px;

  color: white;
  background: var(--text-secondary);
  opacity: .5;
  border-radius: 3px;
`
const BackIcon = styled.div`
  display: flex;
  width: 16px;
  height: 16px;

  align-items: center;
  justify-content: center;  
  user-select: none;

  color: var(--text-tertiary);
  opacity: .8;
  transition: opacity .2s ease;

  &:hover {
    opacity: 1;
  }
`
const SlideTitle = styled.div`
  display: flex;
  align-items: center;
  padding: .25rem .5rem;
  margin: .25rem -.5rem;
  border-radius: 3px;
  cursor: pointer;
  color: var(--text-secondary);
  font-size: var(--s);
  font-weight: 500;
  transition: opacity .2s ease;
  opacity: .7;

  ${BackIcon} {
    display: none;
  }
  ${MiniLogo} {
    display: inline-block;
  }

  &:hover {
    opacity: 1;
    ${BackIcon} {
      display: flex;
    }
    ${MiniLogo} {
      display: none;
    }
  }
`
const BoardName = styled.div`
  margin: 0 0 0 .5rem;
`
const BubbleHeadSlideWrapper = styled.div`
  display: flex;
  cursor: pointer;
`

export default SlideTopBar
