import JSZip from 'jszip'
import { saveAs } from 'file-saver'

import LogError from 'actions/LogError'
import GetSerializedReportData from 'actions/GetSerializedReportData'

const getCSV = (props) => {
  console.log('getting CSV...')
  return doDownload(props)
    .catch( error => LogError('generate CSV', error) )
}

const doDownload = async ({ orgId, boardId }) => {
  
  // get report data
  const reportData = await GetSerializedReportData({ orgId, boardId })

  // generate object of CSV files
  const csvFiles = reportData.board.slides
    .filter(slide => slide.type === 'cards')
    .map(slide => {
      const csvHeader = 'Idea,Author,Votes'
      const csvContent = slide.cards.map(card => {
        return `"${card.content}","${card.author && card.author.displayName}","${(card._voteCount) || 0}"`
      }).join('\n')

      const fileName = slide.prompt ? slide.prompt.substring(0, 50) : 'Unnamed slide'

      // strip slashes because they make subfolders
      // https://stuk.github.io/jszip/documentation/api_jszip/file_name.html
      // https://stackoverflow.com/questions/3780696/javascript-string-replace-with-regex-to-strip-off-illegal-characters
      const fileNameEscaped = fileName.replace(/[/\\]/g, '-')

      return {
        name: fileNameEscaped,
        content: csvHeader+'\n'+csvContent,
      }
    })

  console.log('zipping csvFiles', csvFiles)

  // make zip file
  const zip = new JSZip()
  csvFiles.forEach( (file, index) => zip.file(`${index + 1} - ${file.name}.csv`, file.content))
  const zipFile = await zip.generateAsync({ type: 'blob' })

  // save file
  saveAs(zipFile, `${reportData.board.name} CSV export - Shuffleboard.zip`)
}

export default getCSV