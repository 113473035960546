// This component will attempt to auto-join an organization for a user

import React, { useEffect } from 'react'

const AutoJoinOrg = ({switchOrg, user}) => {
  useEffect( () => {
    if (user.mostRecentOrgId) {
      console.log('joining the most recent org')
      switchOrg(user.mostRecentOrgId)
      return
    }
    if (user.knownMemberOrgIds[0]) {
      console.log('joining the first org in the known orgs list')
      switchOrg(user.knownMemberOrgIds[0])
      return
    }
    console.log('no org to auto-join')
  })

  return <div>Loading your org...</div>
}

export default AutoJoinOrg
