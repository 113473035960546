import Firebase from 'firebase/app'

const sortCards = async (slideRef, sortType) => {

  console.log('Sorting cards for', slideRef, sortType, '...')

  let cards = await slideRef
    .collection('cards')
    .get()
    .then(cardSnaps => {
      return cardSnaps.docs.map(cardSnap => ({
        ref: cardSnap.ref,
        ...cardSnap.data(),
      }))
    })
  console.log('cards raw', cards)

  if (cards.length < 1) {
    return
  }
  
  cards = cards
    .filter( card => !card.parentCard )
  console.log('cards top level', cards)

  if (sortType === 'TIME') {
    cards = cards
      .sort( (a, b) => {
        const timeA = a.createdOn.toMillis() || 0
        const timeB = b.createdOn.toMillis() || 0
        return timeB - timeA
      })
  } else if (sortType === 'RANDOM') {
    // https://stackoverflow.com/a/12646864
    for (let i = cards.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [cards[i], cards[j]] = [cards[j], cards[i]];
    }
  } else {
    cards = cards
      .sort( (a, b) => {
        const voteA = a._voteCount || 0
        const voteB = b._voteCount || 0
        return voteB - voteA
      })
  }
  console.log(`cards sorted by method ${sortType}`, cards)

  cards = cards
    .map( ( card, index ) => ({
      ...card,
      order: index,
    }))
  console.log('cards with order', cards)
  
  // Check for the firestore batched writes limit
  if (cards.length >= 500) {
    console.error('Hit card limit')
    alert('Sorry, you have too many cards to sort!')
    return
  }

  // Batch write
  // https://firebase.google.com/docs/firestore/manage-data/transactions#batched-writes
  const batch = Firebase.firestore().batch()

  cards.forEach(card => {
    const newCard = {...card}
    delete newCard.ref
    batch.set(card.ref, newCard)
  })

  return batch.commit()
    .then( () => {
      console.log('...done sorting!')
    })
}

export default sortCards
