import React from 'react'
import styled from 'styled-components/macro'
import { Button } from 'styles/buttons'

const OnboardingChecklist = ({
  steps,
  firstName,
  numComplete,
  percentComplete,
}) => {

  return <OnboardingChecklistWrapper>
    
    <OnboardingChecklistHeader>
      <div>
        <OnboardingChecklistHeaderTitle>
          Hi {firstName && ' '+firstName}
          {' '}
          <span role="img" aria-label="smile">🙂</span>
        </OnboardingChecklistHeaderTitle>
        <OnboardingChecklistHeaderSub>
          Let's get you up and running!
          · {numComplete} of {steps.length} steps complete
        </OnboardingChecklistHeaderSub>
      </div>
    </OnboardingChecklistHeader>
    
    <ProgressBarWrapper>
      <ProgressBar style={{ width: percentComplete+'%' }} />
    </ProgressBarWrapper>

    {steps.map(step => (
      <OnboardingChecklistItemWrapper
        key={step.name}
        isComplete={step.isComplete}
        onClick={!step.isComplete ? step.action : undefined}
      >

        <OnboardingChecklistCheck isComplete={step.isComplete}>
          <i className="material-icons-round">check</i>
        </OnboardingChecklistCheck>

        <div>
          <OnboardingChecklistItemName isComplete={step.isComplete}>{step.name}</OnboardingChecklistItemName>
          {step.desc &&
            <OnboardingChecklistItemDesc isComplete={step.isComplete}>{step.desc}</OnboardingChecklistItemDesc>
          }
        </div>

        <Button
          disabled={step.isComplete}
          green={!step.isComplete && 'green'}
          secondary={step.isComplete && 'secondary'}
        >
          {step.cta}
        </Button>
      </OnboardingChecklistItemWrapper>
    ))}    

  </OnboardingChecklistWrapper>
}

const OnboardingChecklistWrapper = styled.div`
  background: white;
  padding: .5rem 1.5rem;
  margin: 0 0 3rem;
  box-shadow: 0 2px 6px var(--shadow);
  border-radius: 4px;
  border: 2px solid var(--green-70);
`
const OnboardingChecklistHeader = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  grid-column-gap: 1rem;
  align-items: center;
  margin: 1rem 0;
`
const OnboardingChecklistHeaderTitle = styled.div`
  color: var(--text-primary);
  font-size: var(--l);
  font-weight: 600;
`
const OnboardingChecklistHeaderSub = styled.div`
  color: var(--text-secondary);
  font-size: var(--s);
`
const OnboardingChecklistItemWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content auto max-content;
  grid-column-gap: .75rem;
  align-items: center;
  
  padding: 1rem 0;
  cursor: ${p => p.isComplete ? 'auto' : 'pointer'};
  border-bottom: 1px solid var(--hairline);
  user-select: none;

  &:last-child {
    border-bottom: none;
  }

  @media(max-width: 599px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
`
const OnboardingChecklistItemName = styled.div`
  color: ${p => p.isComplete ? 'var(--text-tertiary)' : 'var(--text-primary)'};
  font-size: var(--m);
  font-weight: ${p => p.isComplete ? '400' : '600'};
  text-decoration: ${p => p.isComplete ? 'line-through' : 'none'};
`
const OnboardingChecklistItemDesc = styled.div`
  color: ${p => p.isComplete ? 'var(--text-tertiary)' : 'var(--text-secondary)'};
  font-size: var(--s);
  font-weight: 400;
  text-decoration: ${p => p.isComplete ? 'line-through' : 'none'};
`
const OnboardingChecklistCheck = styled.span`
  i {
    font-size: var(--xl);
    margin: 0 2px;
    color: ${p => p.isComplete ? 'var(--green-50)' : 'var(--green-90)'};
  }
`
const ProgressBarWrapper = styled.div`
  width: 100%;
  height: .75rem;
  border-radius: 3px;
  background: var(--green-96);
  position: relative;
  overflow: hidden;
`
const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: 4px;
  background: var(--brand-secondary);

  width: 4px; /* default */
`

export default OnboardingChecklist
