import React from 'react'
import styled from 'styled-components/macro'

const OnboardingTourPopup = ({
  children,
  left,
  center,
  right,
  visible,
}) => {
  return <OnboardingTourPopupWrapper
    left={left}
    center={center}
    right={right}
    visible={visible}
  >
    {children}
  </OnboardingTourPopupWrapper>
}

const OnboardingTourPopupWrapper = styled.div`
  position: fixed;
  left:  ${p => p.left  ? '2rem' : p.center ? '50%' : 'auto'};
  right: ${p => p.right ? '2rem' : 'auto'};
  transform: ${p => p.center && 'translateX(-50%)'};
  bottom: 6rem;
  max-width: 20rem;

  background: var(--brand-primary);
  color: white;
  font-weight: 500;
  z-index: 10;
  cursor: pointer;
  border-radius: 8px;
  
  opacity: ${p => p.visible ? 1 : 0};
  transition: opacity 1s ease;

  p {
    color: white;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  &:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left:  ${p => p.left  ? `1rem` : p.center ? '48%' : 'auto'};
    right: ${p => p.right ? `1rem` : 'auto'};
    bottom: -1rem;
    border-width: .5rem;
    border-style: solid;
    border-color: var(--brand-primary) transparent transparent transparent;
  }
`

export default OnboardingTourPopup
