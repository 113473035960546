import React from 'react'

import FirebaseAuth from 'views/_util/FirebaseAuth'
import SubscriptionStatus from 'views/_util/SubscriptionStatus'
import { AppStateContext } from 'views/AppState'
import ErrorPage from 'views/_components/ErrorPage'
import Subscribe from 'views/subscribe/Subscribe'
import { LoadingPage } from 'styles/loading'
import EnsureLoggedInAndInAnOrg from 'views/EnsureLoggedInAndInAnOrg'

const SubscribeContainer = () => {

  return <EnsureLoggedInAndInAnOrg>
    <FirebaseAuth>
      { ({isLoading, error, auth}) => {
        if (isLoading) {
          return <LoadingPage>Loading</LoadingPage>
        }
        if (error) {
          return <ErrorPage error={error} />
        }

        return <SubscriptionStatus>
          { ({isLoading, error, subscribed}) => {
            if (isLoading) {
              return <LoadingPage>Loading</LoadingPage>
            }
            if (error) {
              return <ErrorPage error={error} />
            }
            
            return <AppStateContext.Consumer>
              { ({orgId}) => {
                return <Subscribe
                  auth={auth}
                  subscribed={subscribed}
                  orgId={orgId}
                  />
              }}
            </AppStateContext.Consumer>

          }}
        </SubscriptionStatus>
      }}
    </FirebaseAuth>
  </EnsureLoggedInAndInAnOrg>
}

export default SubscribeContainer
