import React, { useState } from 'react'
import Firebase from 'firebase/app'
import { useDocument } from 'react-firebase-hooks/firestore'
import { Helmet } from 'react-helmet'
import styled from 'styled-components/macro'
import {
  Route,
  useRouteMatch,
  useHistory,
} from 'react-router-dom'

import Chatbox from 'views/_components/Chatbox'
import Modal from 'views/_components/Modal'
import PresentationLayout from 'views/_layouts/PresentationLayout'
import SlideManager from 'views/board/SlideManager'
import AddSlideTypePicker from 'views/board/new_slide/AddSlideTypePicker'
import PresenceWatcherContainer from 'views/board/watchers/PresenceWatcherContainer'
import BoardMembershipWatcherContainer from 'views/board/watchers/BoardMembershipWatcherContainer'
import SlideTopBar from 'views/board/SlideTopBar'
import BoardTimer from 'views/board/bottom_bar/BoardTimer'
import BoardVoter from 'views/board/bottom_bar/BoardVoter'
import BoardSorter from 'views/board/bottom_bar/BoardSorter'
import VoteBar from 'views/board/bottom_bar/VoteBar'
import SlideNav from 'views/board/SlideNav'
import BoardOnboardingModal from 'views/board/onboarding_modal/BoardOnboardingModal'
import BoardTourSettingsStep from 'views/board/onboarding_tour/BoardTourSettingsStep'
import AutoCopyLink from 'views/board/AutoCopyLink'
import ShareBoardModal from 'views/board/sharing/ShareBoardModal'
import InputBox from 'views/_components/InputBox'

const Board = ({
  user,
  board,
  boardRef,
  boardOrgId,
  slides,
  isFacilitator,
  participants,
}) => {

  let match = useRouteMatch()
  let history = useHistory()

  const [subscriptionSnapshot, subscriptionLoading, subscriptionError] = useDocument(
    Firebase.firestore().doc(`/subscriptions/${boardOrgId}`),
    {}
  )

  const isNotSubscribed = !subscriptionLoading && !subscriptionError && !(subscriptionSnapshot && subscriptionSnapshot.data() && subscriptionSnapshot.data().subscribed)

  const [ isAddingSlide, setIsAddingSlide ] = useState(false)
  const [ isSharingBoard, setIsSharingBoard ] = useState(false)

  const participantsMayRoam = board.displayOptions && board.displayOptions.participantRoaming

  return <PresentationLayout title="Board">
    <SlideManager
      user={user}
      boardRef={boardRef}
      board={board}
      slides={slides}
      addSlide={() => setIsAddingSlide(true)}
      isFacilitator={isFacilitator}
    >
      {({
        SlideComponent,
        boardHasSlides,
        goToNextSlide,
        nextSlideTitle,
        goToPrevSlide,
        prevSlideTitle,
        slideType,
        slideRef,
        onFirstSlide,
        onLastSlide,
        numberOfSlides,
        currentSlideIndex,
        currentSlide,
        currentSlideId,
      }) => {
        return <>
          <Helmet>
            <title>{board.name || 'Unnamed board'} | Shuffleboard</title>
          </Helmet>

          <SlideWrapper data-test-id="board">
            
            <SlideTopBar
              user={user}
              boardOrgId={boardOrgId}
              boardRef={boardRef}
              board={board}
              slides={slides}
              isFacilitator={isFacilitator}
              participants={participants}
              goToPrevSlide={goToPrevSlide}
              isNotSubscribed={isNotSubscribed}
              addSlide={() => setIsAddingSlide(true)}
              leaveBoard={() => history.push(isFacilitator ? '/boards' : '/')}
              shareBoard={() => setIsSharingBoard(true)}
            />

            <SlideComponent
              user={user}
              boardOrgId={boardOrgId}
              boardRef={boardRef}
              board={board}
              slides={slides}
              isFacilitator={isFacilitator}
              nextSlideTitle={nextSlideTitle}
              prevSlideTitle={prevSlideTitle}
              boardHasSlides={boardHasSlides}
              isNotSubscribed={isNotSubscribed}
              currentSlideId={currentSlideId}
            />

            <SlideBottomBar>
              
              <BottomBarActionsWrapper>
                { user &&
                  isFacilitator &&
                  board.isDemoBoard &&
                  user.hasClosedFirstBoardModal &&
                  user.hasSubmittedDemoBoardIdea &&
                  !user.hasClosedBoardTourSettingsStep &&
                  <BoardTourSettingsStep user={user} />
                }
                <BoardTimer
                  isFacilitator={isFacilitator}
                  currentSlide={currentSlide}
                  slideRef={slideRef}
                  />
                <BoardVoter
                  user={user}
                  boardRef={boardRef}
                  board={board}
                  isFacilitator={isFacilitator}
                  slideType={slideType}
                  />
                <BoardSorter
                  user={user}
                  boardRef={boardRef}
                  board={board}
                  isFacilitator={isFacilitator}
                  slideType={slideType}
                  currentSlideId={currentSlideId}
                  />
              </BottomBarActionsWrapper>

              <SlideNav
                user={user}
                board={board}
                isFacilitator={isFacilitator}
                goToPrevSlide={goToPrevSlide}
                prevSlideTitle={prevSlideTitle}
                goToNextSlide={goToNextSlide}
                nextSlideTitle={nextSlideTitle}
                onFirstSlide={onFirstSlide}
                onLastSlide={onLastSlide}
                numberOfSlides={numberOfSlides}
                currentSlideIndex={currentSlideIndex}
                participantsMayRoam={participantsMayRoam}
                />
            </SlideBottomBar>

          </SlideWrapper>

          {/* TODO - animate it up and down */}
          {
            !board.isInVoteMode &&
            <InputBox
              user={user}
              slideRef={slideRef}
              board={board}
              isFacilitator={isFacilitator}
            />
          }

          <VoteBar
            boardRef={boardRef}
            board={board}
            isFacilitator={isFacilitator}
            slideType={slideType}
          />

        </>
      }}
    </SlideManager>

    {isAddingSlide && 
      <Modal narrow onClose={() => setIsAddingSlide(false)}>
        <AddSlideTypePicker
          slides={slides}
          boardRef={boardRef}
          closeModal={() => setIsAddingSlide(false)}
          isNotSubscribed={isNotSubscribed}
          />
      </Modal>
    }

    {/* quick sharing option for in-app state */}
    {isSharingBoard && 
      <ShareBoardModal
        board={board}
        boardOrgId={boardOrgId}
        isFacilitator={isFacilitator}
        onClose={() => setIsSharingBoard(false)}
      />
    }

    {/*
      for linking from email etc
      ie: https://app.getshuffleboard.com/b/abc123/xyz789/share
    */}
    
    <Route
      path={`${match.path}/share`}
      render={ () => (
        <ShareBoardModal
          board={board}
          boardOrgId={boardOrgId}
          isFacilitator={isFacilitator}
          onClose={ () => history.push(`/b/${boardOrgId}/${board.id}`) }
        />
      )}
    />

    { user &&
      isFacilitator &&
      !user.hasClosedFirstBoardModal &&
      <BoardOnboardingModal user={user} />
    }

    <AutoCopyLink
      isFacilitator={isFacilitator}
      participants={participants}
      board={board}
      user={user}
      />

    {/* presence */}
    <PresenceWatcherContainer boardId={board.id} />
    <BoardMembershipWatcherContainer boardRef={boardRef} />
    
    <Chatbox hide />

  </PresentationLayout>
}

const SlideWrapper = styled.div`
  height: 100%;
  display: grid;
  // https://css-tricks.com/preventing-a-grid-blowout/
  grid-template-rows: max-content minmax(0, auto) max-content;
  justify-content: stretch;
`
const SlideBottomBar = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem 0 1rem;
`
const BottomBarActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  grid-column-gap: 0rem;
  align-items: center;
`

export default Board
