import React from 'react'
import { FirestoreDocument } from 'react-firestore'

import LogError from 'actions/LogError'
import ChildCard from 'views/board/card_detail/ChildCard'

const ChildCardContainer = ({
  slideRef,
  cardId,
  isFacilitator,
}) => {
  
  return <FirestoreDocument path={`${slideRef.path}/cards/${cardId}`}>
    {({isLoading, error, data, snapshot }) => {
      if (isLoading) {
        return '...'
      }
      if (error) {
        LogError('get this card', error)
        return `Sorry--couldn't load this card`
      }
      
      const card = data
      const cardRef = snapshot.ref

      return <FirestoreDocument path={`users/${card.createdBy}`}>
        {({ isLoading, error, data }) => {
          if (isLoading) {
            return null
          }
          if (error) {
            LogError('get this card author', error)
            return 'error'
          }

          const cardAuthor = data

          return <ChildCard
            cardAuthor={cardAuthor}
            cardRef={cardRef}
            card={card}
            isFacilitator={isFacilitator}
          />

        }}
      </FirestoreDocument>
  
    }}
  </FirestoreDocument>
}

export default ChildCardContainer
