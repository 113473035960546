import React from 'react'
import {
  Document,
  Page,
  View,
  Text,
  Link,
  Image,
  StyleSheet,
  Font,
} from '@react-pdf/renderer'

const Report = ({
  // organization,
  board,
  subscribed,
  missingSlideCount,
}) => {
  return <Document
    title={board.name}
    author={board.author && board.author.displayName}
    producer={"Shuffleboard | getshuffleboard.com"}
  >

    {/* Cover page */}
    <Page size="LETTER" style={styles.page}>
      <View style={styles.section}>
        {/* TODO - get org info and put this back once we have backend PDFs */}
        {/* <Text>{organization && organization.name}</Text> */}
        <Text style={styles.title}>{board.name}</Text>

        {board.author &&
          <View style={styles.authorWrapper}>
            {board.author.photoURL &&
              <Image style={styles.authorImage} src={board.author.photoURL} />
            }
            <View style={{paddingLeft: 12,}}>
              <Text style={styles.authorPrefix}>Hosted by</Text>
              <Text style={styles.authorName}>{board.author.displayName}</Text>
            </View>
          </View>
        }

        {
          board.members &&
          board.members.length > 0 &&
          <View>
            <Text style={styles.authorPrefix}>With</Text>
            {board.members.map(member => (
              <Text key={member.id} style={styles.member}>{member.displayName}</Text>
            ))}
          </View>
        }
      </View>
    </Page>

    {board.slides.filter(s => s.type === 'cards').map(slide => (
      <Page key={slide.id} size="LETTER" style={styles.page}>

        <View style={styles.slideHeader}>
          <Text>{slide.prompt || 'Unnamed slide'}</Text>
        </View>

        <View style={styles.slideBody}>
          {(slide.cards && slide.cards.length > 0) ?
            <View style={styles.cardsWrapper}>
              {slide.cards && slide.cards.map(card => (
                <View key={card.id} style={styles.card}>
                  <Text style={styles.cardContent}>{card.content}</Text>
                  <Text style={styles.cardMeta}>
                    {card.author.displayName}
                    {card._voteCount > 0 && ` · ${card._voteCount} vote${card._voteCount !== 1 ? 's' : ''}`}
                  </Text>
                  <Text style={styles.cardDescription}>{card.description}</Text>
                  {card.childCards.length > 0 && card.childCards.map(childCard => (
                    <Text key={childCard.id} style={styles.childCard}>
                      {childCard.content}
                      {' · '}
                      {childCard.author.displayName}
                    </Text>
                  ))}
                </View>
              ))}
            </View>
            :
            <Text style={styles.cardsEmptyState}>No answers for this slide</Text>
          }
        </View>

        <View fixed style={styles.footer}>
          <Link src="https://getshuffleboard.com/">Created with Shuffleboard</Link>
        </View>
      </Page>
    ))}

    {
      !subscribed &&
      <Page size="LETTER" style={styles.page}>
        <View style={styles.upsellWrapper}>
          <Text style={styles.upsellTitle}>Subscribe to unlock {missingSlideCount} missing slide{missingSlideCount !== 1 && 's'}</Text>
          <Text style={styles.upsellBody}>Subscribers get access to full, detailed PDF reports, unlimited boards, unlimited attendees, and more</Text>
          <Link style={styles.upsellLink} src="https://getshuffleboard.com/">Subscribe at getshuffleboard.com</Link>
        </View>
      </Page>      
    }

  </Document>
}


Font.register({ family: 'Sans-Black',   src: '/fonts/Work_Sans/WorkSans-Black.ttf' })

Font.register({ family: 'SourceSerifPro-Bold',     src: '/fonts/Source_Serif_Pro/SourceSerifPro-Bold.ttf' })
Font.register({ family: 'SourceSerifPro-SemiBold', src: '/fonts/Source_Serif_Pro/SourceSerifPro-SemiBold.ttf' })
Font.register({ family: 'SourceSerifPro-Regular',  src: '/fonts/Source_Serif_Pro/SourceSerifPro-Regular.ttf' })


// COLORS

// TODO - pull in css vars
// getComputedStyle(document.documentElement).getPropertyValue('--text-primary')
const textPrimary = '#454554'
const textSecondary = '#73738c'
const xxl = 40
const xl = 24
const l = 20
const m = 15
const s = 12
const xs = 10

// STYLES

// TODO - use the styled components API?
// requires a separate install of custom styled components package
// convenient, or not worth the weight?
// https://react-pdf.org/styling#styled-components

const leftRightMargin = 60

const styles = StyleSheet.create({

  // Viewer
  viewer: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none',
  },

  // Document and pages
  page: {
    // fontFamily: 'Inter-Regular',
    paddingTop: 40,
    paddingBottom: 70,
    paddingLeft: leftRightMargin,
    paddingRight: leftRightMargin,
    color: textSecondary,
    fontSize: m,
  },
  footer: {
    fontSize: xs,
    position: 'absolute',
    bottom: 30,
    left: leftRightMargin,
    right: 0,
    width: '100%',
    textDecoration: 'none',
  },

  // Cover
  title: {
    marginTop: 240,
    fontSize: xxl,
    lineHeight: '1.1',
    fontFamily: 'Sans-Black',
    color: textPrimary,
    paddingBottom: s,
  },
  authorWrapper: {
    marginTop: xl,
    marginBottom: xl,
    flexDirection: 'row',
  },
  authorImage: {
    width: xl,
    height: xl,
    borderTopLeftRadius: xl/2,
    borderTopRightRadius: xl/2,
    borderBottomRightRadius: xl/2,
    borderBottomLeftRadius: xl/2,
  },
  authorPrefix: {
    fontSize: s,
    color: textSecondary,
  },
  authorName: {
    fontSize: m,
    // fontFamily: 'Inter-Bold',
    color: textPrimary,
  },
  member: {
    fontSize: m,
    color: textPrimary,
    paddingTop: xs/2,
  },
  
  // Slides
  slideHeader: {
    paddingTop: xl,
    paddingBottom: l,
    color: textPrimary,
    fontFamily: 'SourceSerifPro-Bold',
    fontSize: xl,
    borderBottom: '5px solid #999',
    lineHeight: '1.1',
  },
  slideBody: {
    // fontFamily: 'Inter-Medium',
  },
  
  // Cards
  cardsEmptyState: {
    // fontFamily: 'Inter-Regular',
    color: textSecondary,
    fontSize: m,
  },
  cardsWrapper: {
  },
  card: {
    display: 'inline-block',
    marginRight: m,
    marginTop: xs,
  },
  cardContent: {
    // fontFamily: 'Inter-Medium',
    color: textPrimary,
    fontSize: m,
  },
  cardMeta: {
    // fontFamily: 'Inter-Regular',
    color: textSecondary,
    fontSize: s,
    marginTop: xs/2,
  },
  cardDescription: {
    marginTop: xs/2,
    color: textSecondary,
    fontSize: s,
  },
  childCard: {
    marginLeft: m,
    marginTop: xs/2,
    color: textPrimary,
    fontSize: s,
  },

  // Upsell page
  upsellWrapper: {
    padding: m,
    textAlign: 'center',
  },
  upsellTitle: {
    // fontFamily: 'Inter-Bold',
    color: textPrimary,
    fontSize: m,
    paddingTop: xxl,
    paddingBottom: m,
  },
  upsellBody: {
    // fontFamily: 'Inter-Regular',
    color: textSecondary,
    fontSize: s,
    paddingBottom: m,
  },
  upsellLink: {
    // fontFamily: 'Inter-Regular',
    color: 'blue',
    fontSize: s,
  },

})

export default Report
