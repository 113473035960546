import Firebase from 'firebase/app'

import { prepareDocForCreate } from './helpers/firestoreHelpers'
import createMember from './createMember'
import updateUser from './updateUser'

const createOrg = (ownerId, values) => {
  return Firebase.firestore()
    .collection('organizations')
    .add(prepareDocForCreate(values))
    .then(async orgRef => {
      await createMember(orgRef, ownerId, 'owner')
      
      return Firebase.firestore()
        .collection('users')
        .doc(ownerId)
        .get()
        .then(userSnap => {
          const user = userSnap.data()
          return updateUser(userSnap.id, {
            knownMemberOrgIds: [
              ...user.knownMemberOrgIds || [],
              orgRef.id,
            ],
          })
        })
        .then(() => orgRef)
    })
}

export default createOrg
