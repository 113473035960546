import React from 'react'
import Firebase from 'firebase/app'

import LogError from 'actions/LogError'
import SortableCombinableList from 'views/_util/SortableCombinable/SortableCombinableList'
import SlideListItem from './SlideListItem'
import OrderCardsOrSlides from 'actions/helpers/OrderCardsOrSlides'

const ManageSlides = ({
  boardRef,
  isFacilitator,
  slides,
  goToPrevSlide,
  close,
}) => {

  if (!isFacilitator) {
    return null
  }

  const persistSlidePositions = (items) => {

    // make a db batch
    const batch = Firebase.firestore().batch()
    
    // update each slide's position in the db
    items.forEach( (item, index) => {
      const slideRef = boardRef.collection('slides').doc(item.props.slide.id)
      batch.update(slideRef, {order: index})
    })
    
    // Commit the batch
    batch.commit()
      .catch( error => LogError('sort this slide', error) )
  }

  // prepare to be sortable
  const slideItems = slides
    .sort(OrderCardsOrSlides)
    .map(slide => ({
      id: slide.id,
      props: {
        boardRef,
        isFacilitator,
        goToPrevSlide,
        slide,
        close,
      }
    }))

  // need a wrapper to prevent from sorting these past the parent
  return <div>
    <SortableCombinableList
      defaultItems={slideItems}
      ItemComponent={SlideListItem}
      persistItemPositions={persistSlidePositions}
      combinable={false}
      dragItemType={`slide`}
      displayType={`block`}
      canDrag={!!isFacilitator}
      itemMargin={'0'}
      vertOnly={true}
      parentOnly={true}
    />
  </div>
}

export default ManageSlides
