import React from 'react'

import LogError from 'actions/LogError'
import updateUser from 'actions/updateUser'
import {
  WelcomeModalSubheading,
} from 'styles/headings'
import {
  TwoByTwoModalWrapper,
  TwoByTwoModalBody,
  TwoByTwoModalImage,
  OnboardModalNav,
  OnboardModalProgress,
} from 'styles/modal'
import { Button } from 'styles/buttons'

const Reports = ({currentStep, changeStep, stepCount, user}) => {
  return <TwoByTwoModalWrapper>

    <TwoByTwoModalBody>

      <h1>
        Instant meeting notes
      </h1>

      <WelcomeModalSubheading>
        Perfect for sharing with your team
        {' '}
        <span role="img" aria-label="glasses">🤓</span>
      </WelcomeModalSubheading>

      <p>
        Download PDF, DOCX, or CSV
      </p>
      <p>
        Or quickly copy/paste your whole session into spreadsheets, documents, or any other tool
      </p>

      <OnboardModalNav>
        <Button tertiary="tertiary" onClick={() => {
          changeStep(currentStep - 1)
        }}>Back</Button>

        <OnboardModalProgress>
          {currentStep + 1} of {stepCount}
        </OnboardModalProgress>

        <Button
          data-test-id="board-onboarding-next"
          onClick={() => {
            updateUser(user.id, { hasClosedFirstBoardModal: true })
              .catch( error => LogError('end onboarding', error) )
          }}
        >
          OK!
        </Button>
      </OnboardModalNav>
      
    </TwoByTwoModalBody>

    <TwoByTwoModalImage src="/images/onboarding/onboarding - reports.jpg" />

  </TwoByTwoModalWrapper>
}

export default Reports
