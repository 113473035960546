const Chatbox = ({ show, hide }) => {
  
  // be extra sure ActiveCampaign chat box is off
  // https://stackoverflow.com/a/34200213/1061063
  if (hide) {
    const hideChatCSS = `#conversations-wrap { display: none !important; }`
    return <style>{hideChatCSS}</style>
  }

  // doesn't actually render it, that's done here:
  // https://getshuffleboard.activehosted.com/app/settings/tracking
  // TODO - remove <Chatbox /> from pages that use it
  return null
}

export default Chatbox
