import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import updateCard from 'actions/updateCard'
import createReaction from 'actions/createReaction'
import deleteReaction from 'actions/deleteReaction'
import BubbleHeadListItem from 'views/_components/BubbleHeadListItem'

// VOTING LOGIC
//
// When clicking a vote button...
//
// If parent card is voted for
//   and child card is voted for
//     - unvote BOTH
//   and child card is NOT voted for
//     - unvote the parent
//
// If parent card is NOT voted for
//   and child card is voted for
//     - unvote the child
//   and child card is NOT voted for
//     - vote for the parent

const Card = React.forwardRef( ({
  board,
  cardRef,
  card,
  cardAuthor,
  isFacilitator,
  userIsOwner,
  userReactions,
  isBeingDragged,
  isHoveredOver,
  openCardDetail,
}, ref) => {
  
  const [isLoadingVoteAction, setIsLoadingVoteAction] = useState(false)

  // whenever we have a new card voteCount, assume the vote loading state is not loading anymore
  useEffect( () => {
    setIsLoadingVoteAction(false)
  }, [card._voteCount, setIsLoadingVoteAction])

  const newCardsAreHidden = board.displayOptions && board.displayOptions.hideNewCards
  const contentVisible = card.contentVisible || !newCardsAreHidden
  const cardAuthorsAreShown = board.displayOptions && board.displayOptions.showCardAuthors
  const votingAlwaysAllowed = board.displayOptions && board.displayOptions.allowVotingAnytime

  // remove invisible cards for participants
  if (!isFacilitator && !contentVisible && !userIsOwner) {
    return null
  }

  // show contents if visible, or if it's a participant looking at their own card
  const showCardContents = contentVisible || (!isFacilitator && userIsOwner)
  
  const deleteUserReactions = () => {
    console.log('deleting reactions', userReactions, 'from card', card)
    userReactions.map(ur => {
      LogEvent('remove-card-reaction')
      return deleteReaction(ur.ref)
        .catch( error => LogError('remove this card reaction', error) )
    })
  }

  const voteCount = card._voteCount || 0

  const onCardClick = () => {    
    if (board.isInVoteMode) {
      if (isLoadingVoteAction) {
        return
      }
      if (userReactions && userReactions.length > 0) {
        setIsLoadingVoteAction(true)
        deleteUserReactions()
      } else {
        setIsLoadingVoteAction(true)
        LogEvent('add-card-reaction')
        createReaction(cardRef)
          .catch( error => LogError('react to this card', error) )
      }
    } else {
      if (isFacilitator && !contentVisible) {
        LogEvent('show-hide-card')
        updateCard(cardRef, {contentVisible: true})
          .catch( error => LogError('show this card', error) )
      } else {
        openCardDetail(card.id)
      }
    }
  }

  // TODO - find more youtube video link types
  // https://stackoverflow.com/questions/3717115/regular-expression-for-youtube-links
  const isYoutubeLink = card.content && card.content.includes('youtube.com/watch?v=')
  const youtubeId = isYoutubeLink ? card.content.split('youtube.com/watch?v=')[1].split('&')[0] : undefined

  return <CardWrapper
    ref={ref}
    tabIndex="200"
    isInVoteMode={board.isInVoteMode}
    isBeingDragged={isBeingDragged}
    isHoveredOver={isHoveredOver}
    isFacilitator={isFacilitator}
    contentVisible={contentVisible}
    onClick={onCardClick}
    isMultiple={card.childrenIds.length !== 0}
    showCardContents={showCardContents}
    data-test-id="idea"
  >
    <CardContent>
      {
        showCardContents ?
        <span>
          {card.content}

          {isYoutubeLink &&
            <YouTubeEmbed
              title={youtubeId}
              width="220"
              height="124"
              src={`https://www.youtube.com/embed/${youtubeId}`}
            />
          }

          <CardAuthor hidden={!cardAuthorsAreShown}>
            <BubbleHeadListItem
              small="small"
              userId={card.createdBy}
            />
            <CardAuthorName>
              {
                !cardAuthor.displayName ? 'Anonymous' :
                cardAuthor.displayName.split(' ')[0]
              }
            </CardAuthorName>
          </CardAuthor>
        </span>
        :
        "Tap to reveal" // should only happen for board runners
      }
    </CardContent>

    {
      !board.isInVoteMode &&
      voteCount > 0 &&
      <CardVoteCount>{voteCount} vote{voteCount !== 1 && 's'}</CardVoteCount>
    }

    {
      !board.isInVoteMode &&
      !isBeingDragged &&
      contentVisible &&
      votingAlwaysAllowed &&
      <QuickVoter
        votedFor={userReactions && userReactions.length > 0}
        isLoadingVoteAction={isLoadingVoteAction}
        onClick={event => {
          event.stopPropagation()
          if (isLoadingVoteAction) {
            return
          }
          setIsLoadingVoteAction(true)
          if (userReactions && userReactions.length > 0) {
            deleteUserReactions()
          } else {
            LogEvent('add-card-reaction')
            createReaction(cardRef)
              .catch( error => LogError('react to this card', error) )
          }
        }}
      >
        {isLoadingVoteAction ?
          <SmallSpinner />
          :
          <i className="material-icons-round">thumb_up</i>
        }
      </QuickVoter>
    }

    {
      !board.isInVoteMode ?
        null
      :
      isLoadingVoteAction ?
        <SmallSpinner />
      :
      (userReactions && userReactions.length > 0) &&
        <UserVote>
          <i className="material-icons-round">thumb_up</i>
        </UserVote>
    }
    
  </CardWrapper>
})

const cardColor = 'white'
const cardHiddenColor = 'white'

const QuickVoter = styled.div`
  /* but always shown when card is hovered (see below) */
  display: ${p => p.votedFor ? 'block' : p.isLoadingVoteAction ? 'block' : 'none'};

  position: absolute;
  right: 0;
  top: 0;
  padding: .25rem;
  box-shadow: -3px 3px 3px 0 white;
  cursor: pointer;
  background: white;
  
  line-height: 1;
  font-size: 12px;
  
  i {
    color: ${p => p.votedFor ? 'var(--brand-primary)' : 'var(--gray-80)'};
    opacity: .8;
    line-height: 1;
    font-size: 12px;
  }
  
  &:hover i {
    opacity: 1;
  }
`
const SmallSpinner = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: var(--gray-80) transparent var(--gray-80) transparent;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: smallSpinner 2s linear infinite;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  @keyframes smallSpinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
const CardWrapper = styled.div`
  // trying this to fix stacy's ms edge issue
  // https://stackoverflow.com/questions/45444105/flex-direction-column-not-working-in-ie-and-edge/45444144
  display: block;

  padding: .5rem 2rem .5rem .66rem;
  position: relative;
  cursor: ${p => p.isInVoteMode ? 'pointer' : ( p.contentVisible && p.isFacilitator) ? 'grab' : 'pointer'};
  border-radius: 4px;
  background: ${p =>
    !p.showCardContents ? cardHiddenColor :
    p.isBeingDragged ? 'var(--background-item-dark)' :
    cardColor
  };
  opacity: ${p => p.isHoveredOver && '.5'};
  color: ${p =>
    !p.showCardContents ? 'var(--text-tertiary)' :
    p.isBeingDragged ? 'transparent' :
    'var(--text-primary)'
  };
  box-shadow: ${p =>
    p.isBeingDragged ? `none` :
    p.isMultiple ? '0 3px 3px -2px hsl(0,0%,0%,.2),0 6px 0 -2px white,0 4px 6px var(--shadow)' :
    '0 2px 6px var(--shadow)'
  };
  transition: transform .2s ease;

  &:hover {
    ${QuickVoter} {
      display: block;
    }
  }

  outline: none;
  &:focus {
    transform: translateY(-2px);
    box-shadow: ${p =>
      p.isBeingDragged ? `none` :
      p.isMultiple ? '0 3px 3px -2px hsl(0,0%,0%,.2), 0 6px 0 -2px white, 0 6px 10px var(--shadow-overlay)' :
      '0 4px 10px var(--shadow-overlay)'
    };
  }

  width: 250px;

  @media (max-width: 599) {
    width: 100%;
  }
`
const UserVote = styled.div`
  background: var(--brand-primary);
  color: white;
  border-radius: 100px;
  position: absolute;
  right: -.25rem;
  top: -.25rem;
  width: 1.5rem;
  height: 1.5rem;
  padding: .25rem;
  text-align: center;
  line-height: 1;

  i {
    font-size: var(--m);
  }
`
const CardContent = styled.div`
  // preserve line breaks for text areas
  // https://stackoverflow.com/questions/40417527/how-do-i-preserve-line-breaks-when-getting-text-from-a-textarea/40426477#40426477
  white-space: pre-wrap;

  // keep long links from busting out the side and breaking layout
  overflow: hidden;
  text-overflow: ellipsis;
  
  display: block;
  line-height: 1.2;
  font-size: var(--m);
  color: inherit;
  user-select: none;
  overflow: hidden;
`
const CardAuthor = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: .25rem;
  align-items: center;
  margin: ${p => p.hidden ? 0 : '.5rem 0 0'};
  height: ${p => p.hidden ? 0 : 'calc(0.25rem + 20px)'};
  transition: all .2s ease;
`
const CardAuthorName = styled.div`
  font-size: var(--xs);
  color: var(--text-secondary);
`
const CardVoteCount = styled.div`
  font-size: 12px;
  background: var(--background-item);
  color: var(--brand-primary);
  font-weight: 500;
  border-radius: 100px;
  display: inline-block;
  margin: .5rem 0 0;
  padding: 3px .5rem;
  line-height: 1;
`
const YouTubeEmbed = styled.iframe`
  border: none;
  margin: 1rem 0 -1rem;
`

export default Card
