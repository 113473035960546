import React from 'react'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import updateBoard from 'actions/updateBoard'
import ToggleSwitch from 'views/_components/ToggleSwitch'
import {
  DropdownMenuAction,
} from 'styles/dropdown'

const ShowCards = ({
  board,
  boardRef,
}) => {

  const newCardsAreHidden = board.displayOptions && board.displayOptions.hideNewCards

  return <DropdownMenuAction onClick={() => {
    LogEvent('toggle-hide-cards-until-clicked')
    updateBoard(boardRef, {displayOptions: {hideNewCards: !newCardsAreHidden}})
      .catch( error => LogError('change this card\'s visibility', error) )
  }}>
    <i className="material-icons-round">visibility</i>
    Hide cards until clicked
    <ToggleSwitch on={newCardsAreHidden} />
  </DropdownMenuAction>
}

export default ShowCards