import React, { useState } from 'react'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import resetPassword from 'actions/resetPassword'
import AccountLayout from 'views/_layouts/AccountLayout'
import { Button, AppLink } from 'styles/buttons'
import { Label, Input } from 'styles/forms'
import Chatbox from 'views/_components/Chatbox'

const ResetPassword = () => {

  const [email, setEmail] = useState('')
  const [hasSentPasswordResetRequest, setHasSentPasswordResetRequest] = useState(false)

  const onSubmit = event => {
    event.preventDefault()
    LogEvent('request-password-reset')
    resetPassword({ email })
      .then( () => {
        setHasSentPasswordResetRequest(true)
      })
      .catch( error => {
        if (error.code === 'auth/user-not-found') {
          alert(`Hm, we couldn't find a user with that email. Please double-check the spelling and try again.`)
          return
        }
        LogError('reset your password', error)
      })
    }

  return <AccountLayout title="Reset password">

    <h2>Reset your password</h2>

    {hasSentPasswordResetRequest ?
      <div>Done! Please check your email for a password reset link.</div>
    :
      <form onSubmit={onSubmit}>
        <Label>Email address</Label>
        <Input
          type="email"
          name="email"
          maxLength={1000}
          placeholder="your@email.com"
          required
          autoFocus
          wide="wide"
          value={email}
          onChange={event => setEmail(event.target.value)}
          />
        <Button type="submit" wide block big="big">Send password reset link</Button>
      </form>
    }

    <hr />

    Ready to log in?
    {' '}
    <AppLink to="/login">Log in</AppLink>

    <Chatbox />

  </AccountLayout>
}

export default ResetPassword
