import React from 'react'
import { FirestoreDocument, FirestoreCollection } from 'react-firestore'

import FirebaseAuth from 'views/_util/FirebaseAuth'
import FirebasePresence from 'views/_util/FirebasePresence'
import NameInput from 'views/_components/NameInput'
import ErrorPage from 'views/_components/ErrorPage'
import Join from 'views/account/Join'
import Board from 'views/board/Board'
import OrderCardsOrSlides from 'actions/helpers/OrderCardsOrSlides'

import { LoadingPage } from 'styles/loading'

const BoardContainer = ({match}) => {
  return <FirebaseAuth>
    { ({isLoading, error, auth}) => {
      if (isLoading) {
        return <LoadingPage>Loading</LoadingPage>
      }
      if (error) {
        return <ErrorPage error={error} />
      }
      if (!auth) {
        return <Join />
      }
      
      return <FirestoreDocument path={`organizations/${match.params.orgId}/boards/${match.params.boardId}`}>
        {({ isLoading, error, data, snapshot }) => {
          if (isLoading) {
            return <LoadingPage>Loading</LoadingPage>
          }
          if (error) {
            return <ErrorPage error={error} />
          }

          const board = data
          const boardRef = snapshot.ref
          const boardSnap = snapshot

          if (!snapshot.exists) {
            return <ErrorPage error={new Error(`This board does not exist or has been deleted`)} />
          }

          return <FirestoreCollection path={`organizations/${match.params.orgId}/members`}>
            {({ isLoading, error, data }) => {
              if (isLoading) {
                return <LoadingPage>Loading</LoadingPage>
              }

              if (error && !error.message.includes('Missing or insufficient permissions') ) {
                return <ErrorPage error={error} />
              }

              // facilitators are people who are members of the board org
              // this may be overkill since just having access to query the org is enough right now
              // ie isFacilitator = !error
              // but this is more future-proof
              let isFacilitator = false
              const orgMembersIds = data.map(memberSnap => memberSnap.id)
              if (orgMembersIds.includes(auth.uid)) {
                isFacilitator = true
              }
              
              return <FirestoreDocument path={`users/${auth.uid}/`}>
                {({ isLoading, error, data }) => {
                  if (isLoading) {
                    return <LoadingPage>Loading</LoadingPage>
                  }
                  if (error) {
                    return <ErrorPage error={error} />
                  }
        
                  const user = data
        
                  // TODO - don't flash this!
                  // likely new anonymous auth, needs to type a name
                  
                  // refactor this page to use the hook style so we can say "no cache for users"
                  // const [userSnapshot, userLoading, userError] = useDocument(
                  //   Firebase.firestore().doc(`/users/${auth.uid}`),
                  //   { snapshotListenOptions: { includeMetadataChanges: true }, }
                  // )
                
                  if (!user.displayName && isFacilitator) {
                    // TOTAL HACK to prevent a flash of a "what's your name?" form for all board loads
                    // the right way to do it: refactor all this with hooks
                    return <LoadingPage>Loading</LoadingPage>
                  }
                  if (!user.displayName && !isFacilitator) {        
                    return <NameInput userId={user.id} />
                  }

                  return <FirebasePresence boardId={board.id}>
                    {({ isLoading, error, presentParticipants }) => {
                      if (isLoading) {
                        return <LoadingPage>Loading</LoadingPage>
                      }
                      if (error) {
                        return <ErrorPage error={error} />
                      }
                      
                      return <FirestoreCollection path={`${boardRef.path}/members/`}>
                        {({ isLoading, error, data }) => {
                          if (isLoading) {
                            return <LoadingPage>Loading</LoadingPage>
                          }
                          if (error) {
                            return <ErrorPage error={error} />
                          }
                          
                          const members = data
                          
                          // combine all the members and the current participants into a single "participants" array
                          const participants = members.map(member => ({
                            absent: !presentParticipants.find(pp => pp.id === member.id),
                            isFacilitator: board.createdBy === member.id,
                            ...member,
                          }))

                          return <FirestoreCollection path={`${boardRef.path}/slides/`}>
                            {({ isLoading, error, snapshot }) => {
                              if (isLoading) {
                                return <LoadingPage>Loading</LoadingPage>
                              }
                              if (error) {
                                return <ErrorPage error={error} />
                              }
                              
                              // https://stackoverflow.com/a/10124053
                              const slides = snapshot.docs
                                .map(slideSnap => ({
                                  id: slideSnap.id,
                                  ref: slideSnap.ref,
                                  ...slideSnap.data(),
                                }))
                                .sort(OrderCardsOrSlides)

                              return <Board
                                user={user}
                                auth={auth}
                                board={board}
                                boardRef={boardRef}
                                boardSnap={boardSnap}
                                boardOrgId={match.params.orgId}
                                slides={slides}
                                isFacilitator={isFacilitator}
                                participants={participants}
                                />
                            }}
                          </FirestoreCollection> // members
      
                        }}
                      </FirestoreCollection> // slides

                    }}
                  </FirebasePresence> // participants

                }}
              </FirestoreDocument> // user

            }}
          </FirestoreCollection> // orgMembers

        }}
      </FirestoreDocument> // board

    }}
  </FirebaseAuth> // auth
}

export default BoardContainer
