import React from 'react'
import Firebase from 'firebase/app'

// https://firebase.googleblog.com/2013/06/how-to-build-presence-system.html
// https://blog.campvanilla.com/firebase-firestore-guide-how-to-user-presence-online-offline-basics-66dc27f67802

class PresenceWatcher extends React.Component {
  
  userRef = Firebase.database().ref(`/boards/${this.props.boardId}/users/${this.props.userId}/`)
  connectionRef = Firebase.database().ref('.info/connected')

  componentDidMount () {

    // when the presence system realizes you're there (or not there...)
    this.connectionRef.on('value', snapshot => {
      if (snapshot.val()) {

        // make a note to disconnect later
        this.userRef.onDisconnect().remove()

        // set online right now
        this.userRef.update({online: true})

      }
    })
  }
  
  componentWillUnmount () {
    // TODO - will this break if I close in one tab but not another?
    // details: https://app.asana.com/0/1125533493218954/1200260361668812
    this.userRef.remove()
  }

  render() {
    return null
  }
}

export default PresenceWatcher
