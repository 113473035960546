import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import logInWithGoogle from 'actions/logInWithGoogle'
import logInWithEmailPassword from 'actions/logInWithEmailPassword'
import AccountLayout from 'views/_layouts/AccountLayout'
import OrBar from 'views/_components/OrBar'
import { Button, ButtonSubText, AppLink } from 'styles/buttons'
import { Label, Input } from 'styles/forms'
import Chatbox from 'views/_components/Chatbox'

const LogIn = () => {

  const history = useHistory()

  const location = useLocation()
  const searchParams = queryString.parse(location.search)
  const redirectTo = searchParams?.redirectTo && decodeURIComponent(searchParams.redirectTo)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const doEmailLogin = event => {
    event.preventDefault()
    logInWithEmailPassword({ email, password })
      .then( () => {
        LogEvent('log-in-email')
        if (redirectTo) {
          window.location = redirectTo
        } else {
          history.push('/boards')
        }
      })
      .catch( error => {
        if (error.code === 'auth/user-not-found') {
          alert(`Hm, we couldn't find a user with that email and password. Please double-check the spelling and try again.`)
          return
        }
        if (error.code === 'auth/wrong-password') {
          alert(`Hm, that password isn't right. Please double-check the spelling and try again.`)
          return
        }
        LogError('sign you in', error)
      })
    }

  return <AccountLayout message={location.state?.message} title="Log in">
    
    <h2>Log in</h2>

    <Button
      wide
      block
      big="big"
      destructive="destructive"
      onClick={() => {
        logInWithGoogle()
          .then( () => {
            LogEvent('log-in-google')
            if (redirectTo) {
              window.location = redirectTo
            } else {
              history.push('/boards')
            }
          })
          .catch( error => {
            if (error.code === 'auth/popup-closed-by-user') {
              console.log('user closed the auth popup')
              return null
            }
            LogError('sign in', error)
          })
      }}
    >
      Log in with Google
    </Button>
    <ButtonSubText>We only use your picture, name, and email</ButtonSubText>

    <OrBar />

    <form onSubmit={doEmailLogin} data-test-id="login">
      <Label>Email address</Label>
      <Input
        type="email"
        name="email"
        maxLength={1000}
        placeholder="your@email.com"
        required
        wide="wide"
        value={email}
        onChange={event => setEmail(event.target.value)}
        />
      <Label>Password</Label>
      <Input
        type="password"
        name="password"
        maxLength={1000}
        placeholder="············"
        required
        wide="wide"
        value={password}
        onChange={event => setPassword(event.target.value)}
      />
      <Button type="submit" wide block big="big">Log in with email</Button>
    </form>

    <ButtonSubText>
      <AppLink to="/reset_password">Forgot your password?</AppLink>

      <br />
      <br />

      By continuing, you agree to our
      {' '}
      <AppLink to="/terms">terms of service</AppLink>
    </ButtonSubText>

    <hr />

    No account yet?
    {' '}
    <AppLink
      data-test-id="signup-via-login"
      to={
        redirectTo ?
        `/signup?redirectTo=${window.encodeURIComponent(redirectTo)}` :
        `/signup`
      }
    >
      Sign up
    </AppLink>

    <Chatbox />

  </AccountLayout>
}

export default LogIn
