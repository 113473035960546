import React from 'react'
import { FirestoreDocument } from 'react-firestore'

import TextSlide from 'views/board/slides/TextSlide'
import { LoadingPage } from 'styles/loading'
import LogError from 'actions/LogError'

const TextSlideContainer = ({
  user,
  board,
  boardRef,
  boardOrgId,
  slides,
  isFacilitator,
  participants,
  goToPrevSlide,
  goToNextSlide,
  prevSlideTitle,
  nextSlideTitle,
  currentSlideId,
}) => {
  
  return <FirestoreDocument path={`${boardRef.path}/slides/${currentSlideId}`}>
    {({isLoading, error, data, snapshot }) => {
      if (isLoading) {
        return <LoadingPage>Loading</LoadingPage>
      }
      if (error) {
        LogError('get this slide', error)
        return `Sorry, there was an error getting this slide: ${error}`
      }
      
      const slide = data
      const slideRef = snapshot.ref

      return <TextSlide
        user={user}
        board={board}
        boardRef={boardRef}
        boardOrgId={boardOrgId}
        slideRef={slideRef}
        slide={slide}
        slides={slides}
        isFacilitator={isFacilitator}
        participants={participants}
        goToPrevSlide={goToPrevSlide}
        goToNextSlide={goToNextSlide}
        prevSlideTitle={prevSlideTitle}
        nextSlideTitle={nextSlideTitle}
        />

    }}
  </FirestoreDocument>
}

export default TextSlideContainer
