import ReactGA from 'react-ga'
import Firebase from 'firebase/app'

import LogError from 'actions/LogError'
import EventTypes from 'data/EventTypes'

const LogEvent = (eventId, data) => {
  
  const event = EventTypes[eventId]

  if (!event) {
    console.error(`Error tracking event - no event ${eventId} in EventTypes`)
    return
  }

  const { category, action } = event

  console.log(`Logging event: ${category} - ${action}`, event)

  ReactGA.event({
    category,
    action,
    ...(data && {label: data}),
  })
  
  const logEventOnBackend = Firebase.functions().httpsCallable('logEventOnBackend')
  logEventOnBackend({ event })
    .catch( error => LogError('log this event on the backend', error, true))
}

export default LogEvent
