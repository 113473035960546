import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import TextareaAutosize from 'react-textarea-autosize'
import Linkify from 'linkifyjs/react'
import { useDebouncedCallback } from 'use-debounce'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import UpgradeBanner from 'views/_components/UpgradeBanner'
import updateSlide from 'actions/updateSlide'

const TextSlide = ({
  slide,
  slideRef,
  isFacilitator,
  isNotSubscribed,
}) => {

  const [ promptState,    setPromptState    ] = useState(slide.prompt)
  const [ subPromptState, setSubPromptState ] = useState(slide.subPrompt)

  const updatePromptInDatabaseDebounced = useDebouncedCallback( () => {
    console.log('update prompt in db')
    LogEvent('change-slide-prompt')
    updateSlide(slideRef, { prompt: promptState })
      .catch( error => LogError('update this text slide prompt', error) )
  }, 1000)

  const updateSubPromptInDatabaseDebounced = useDebouncedCallback( () => {
    console.log('update subprompt in db')
    LogEvent('change-slide-subprompt')
    updateSlide(slideRef, { subPrompt: subPromptState })
      .catch( error => LogError('update this text slide subprompt', error) )
  }, 1000)

  // if we get new info from the database, set the state right away
  useEffect( () => {
    console.log('new slide prompt')
    setPromptState(slide.prompt)
  }, [slide.prompt])
  useEffect( () => {
    setSubPromptState(slide.subPrompt)
  }, [slide.subPrompt])

  return <TextSlideWrapper>

    {isFacilitator ?
      <TextSlidePromptEditable
        value={promptState}
        placeholder="Slide title goes here"
        maxLength={1000}
        onChange={event => {
          console.log('input in prompt')
          const prompt = event.target.value
          setPromptState(prompt)
          updatePromptInDatabaseDebounced(prompt)
        }}
    />
    :
      <TextSlidePrompt>{slide.prompt || ""}</TextSlidePrompt>
    }

    {isFacilitator ?
      <TextSlideSubPromptEditable
        value={subPromptState}
        placeholder="Click to type..."
        maxLength={3000}
        onChange={event => {
          console.log('input in subprompt')
          const subPrompt = event.target.value
          setSubPromptState(subPrompt)
          updateSubPromptInDatabaseDebounced(subPrompt)
        }}
    />
    :
      <TextSlideSubPrompt>
        <Linkify
          tagName="span"
          options={{
            className: false,
          }}
        >
          {slide.subPrompt || ""}
        </Linkify>
      </TextSlideSubPrompt>
    }

    <UpgradeBanner isNotSubscribed={isNotSubscribed} />

  </TextSlideWrapper>
}

const TextSlideWrapper = styled.div`
  margin: 0;
`

const TextSlidePrompt = styled.div`
  // preserve line breaks for text areas
  // https://stackoverflow.com/questions/40417527/how-do-i-preserve-line-breaks-when-getting-text-from-a-textarea/40426477#40426477
  white-space: pre-wrap;

  // keep long links from busting out the side and breaking layout
  overflow: hidden;
  text-overflow: ellipsis;
  
  color: var(--text-primary);
  font-size: 3rem;
  font-weight: 600;
  font-family: var(--font-family-serif) !important;
  line-height: 1.1;
  width: 100%;
  max-width: 80rem;
  padding: .5rem 0;

  margin: 20vh 0 0;

  @media (max-width: 599px) {
    font-size: 2rem;
  }
`
const TextSlidePromptEditable = styled(TextareaAutosize)`
  color: var(--text-primary);
  font-size: 3rem;
  font-weight: 600;
  font-family: var(--font-family-serif) !important;
  line-height: 1.1;
  width: 100%;
  max-width: 80rem;
  
  margin: 20vh -0.5rem 0;

  display: block;
  border: 1px solid transparent;
  padding: .5rem;
  border-radius: 4px;
  resize: none;
  cursor: pointer;
  
  &:hover {
    box-shadow: inset 0 0 0 1px var(--hairline);
  }

  outline: none;
  &:focus {
    box-shadow: inset 0 0 0 1px var(--hairline-dark);
  }
`
const TextSlideSubPrompt = styled.div`
  // preserve line breaks for text areas
  // https://stackoverflow.com/questions/40417527/how-do-i-preserve-line-breaks-when-getting-text-from-a-textarea/40426477#40426477
  white-space: pre-wrap;

  // keep long links from busting out the side and breaking layout
  overflow: hidden;
  text-overflow: ellipsis;
  
  color: var(--text-secondary);
  font-weight: 500;
  font-size: var(--l);
  width: 100%;
  max-width: 40rem;

  a {
    color: var(--blue-70);

    &:hover {
      text-decoration: underline;
    }
  }
`
const TextSlideSubPromptEditable = styled(TextareaAutosize)`
  color: var(--text-secondary);
  font-weight: 500;
  font-size: var(--l);
  width: 100%;
  max-width: 40rem;
  font-family: var(--font-family-sans) !important;
  line-height: 1.4;

  margin: 0rem -0.5rem 0;

  display: block;
  border: 1px solid transparent;
  padding: .5rem;
  border-radius: 4px;
  resize: none;
  cursor: pointer;
  
  &:hover {
    box-shadow: inset 0 0 0 1px var(--hairline);
  }

  outline: none;
  &:focus {
    box-shadow: inset 0 0 0 1px var(--hairline-dark);
  }
`

export default TextSlide
