// Error logging function for admin pages ONLY
// not for user-facing stuff

// similar to LogError, but:
// - doesn't report to error monitoring services
// - gives more technical error info

const LogError = (action, error) => {
  console.error(`Error during "${action || 'undefined action'}": ${error ? error.message : 'no error object passed to logger'}`, error)
  alert(`Could not ${action}\n\n${error ? error.message : 'no error object passed to logger'}`)
}

export default LogError
