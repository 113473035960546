import React from 'react'

import {
  WelcomeModalSubheading,
} from 'styles/headings'
import {
  TwoByTwoModalWrapper,
  TwoByTwoModalBody,
  TwoByTwoModalImage,
  OnboardModalNav,
  OnboardModalProgress,
} from 'styles/modal'
import { Button } from 'styles/buttons'

const Slides = ({currentStep, changeStep, stepCount}) => {
  return <TwoByTwoModalWrapper>

    <TwoByTwoModalBody>

      <h1>
        Boards are made of slides
      </h1>

      <WelcomeModalSubheading>
        It's an interactive presentation
        {' '}
        <span role="img" aria-label="raise hand">🙋‍♀️</span>
      </WelcomeModalSubheading>

      <p>
        Each slide has a question that you can edit.
      </p>
      <p>
        Everyone answers on their own device.
      </p>

      <OnboardModalNav>
        <div></div>

        <OnboardModalProgress>
          {currentStep + 1} of {stepCount}
        </OnboardModalProgress>

        <Button
          data-test-id="board-onboarding-next" 
          onClick={() => {
            changeStep(currentStep + 1)
          }}
        >
          Next
        </Button>
      </OnboardModalNav>
      
    </TwoByTwoModalBody>

    <TwoByTwoModalImage src="/images/onboarding/onboarding - slides.jpg" />

  </TwoByTwoModalWrapper>
}

export default Slides
