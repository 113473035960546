import React from 'react'
import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'

import AppLayout from 'views/_layouts/AppLayout'
import BoardListItemContainer from 'views/boards/BoardListItemContainer'
import OnboardingChecklistContainer from 'views/boards/OnboardingChecklistContainer'
import {
  Button,
  ButtonIconWrapper,
} from 'styles/buttons'
import {
  AppLayoutHeader,
} from 'styles/layout'
import Chatbox from 'views/_components/Chatbox'

const Boards = ({
  boards,
  orgRef,
  subscribed,
  boardsRemaining,
}) => {

  const history = useHistory()

  return <AppLayout title="Boards">

    {boards.length > 0 ?
      <>
        <AppLayoutHeader>
          <h1>
            Boards
          </h1>
          <Button
            onClick={() => {
              if (!subscribed && boardsRemaining < 1) {
                history.push(`/subscribe`)
              } else {
                history.push(`/new`)
              }
            }}
          >
            <ButtonIconWrapper>
              <i className="material-icons-round">add</i>
              <span>New board</span>
            </ButtonIconWrapper>
          </Button>
        </AppLayoutHeader>

        {!subscribed &&
          <OnboardingChecklistContainer
            boards={boards}
            orgRef={orgRef}
          />
        }

        <BoardList>
          {boards.map(board => (
            <BoardListItemContainer
              key={board.id}
              board={board}
              orgRef={orgRef}
            />
          ))}
        </BoardList>
      </>
      :
      <BoardEmptyState>
        <h2 data-test-id="boards-empty">
          <span role="img" aria-label="sparkles">✨</span>
          {' '}
          Let's do this!
          {' '}
          <span role="img" aria-label="sparkles">✨</span>
        </h2>
        <p>Create a board to start gathering ideas from your team</p>
        <br />
        <Button
          onClick={() => history.push(`/new`)}
          big="big"
          block
          data-test-id="board-empty-state-add"
        >
          Make your first board
        </Button>
        <br />
        <br />
        <br />
        <WelcomeIframe
          title="youtube-welcome"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/fUChREZ-4go"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen />
      </BoardEmptyState>
    }

    <Chatbox />

  </AppLayout>
}

const BoardEmptyState = styled.div`
  padding: 6rem;
  text-align: center;
`
const WelcomeIframe = styled.iframe`
  border: none;
`
const BoardList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  margin: 0 0 2rem;

  @media(max-width: 599px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
`

export default Boards
