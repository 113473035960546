import React from 'react'

import {
  WelcomeModalSubheading,
} from 'styles/headings'
import {
  TwoByTwoModalWrapper,
  TwoByTwoModalBody,
  TwoByTwoModalImage,
  OnboardModalNav,
  OnboardModalProgress,
} from 'styles/modal'
import { Button } from 'styles/buttons'

const Invite = ({currentStep, changeStep, stepCount}) => {
  return <TwoByTwoModalWrapper>

    <TwoByTwoModalBody>

      <h1>
        Invite anyone to join
      </h1>

      <WelcomeModalSubheading>
        Just send them the link
        {' '}
        <span role="img" aria-label="horse running">🏇</span>
      </WelcomeModalSubheading>

      <p>
        They won't have to log in or install anything.
      </p>
      <p>
        Everyone can add answers.
      </p>
      <p>
        When you switch slides, their slide changes too.
      </p>

      <OnboardModalNav>
        <Button tertiary="tertiary" onClick={() => {
          changeStep(currentStep - 1)
        }}>Back</Button>

        <OnboardModalProgress>
          {currentStep + 1} of {stepCount}
        </OnboardModalProgress>

        <Button
          data-test-id="board-onboarding-next" 
          onClick={() => {
            changeStep(currentStep + 1)
          }}
        >
          Next
        </Button>
      </OnboardModalNav>
      
    </TwoByTwoModalBody>

    <TwoByTwoModalImage src="/images/onboarding/onboarding - add.jpg" />

  </TwoByTwoModalWrapper>
}

export default Invite
