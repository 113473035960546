import React from 'react'

import {
  WelcomeModalSubheading,
} from 'styles/headings'
import {
  TwoByTwoModalWrapper,
  TwoByTwoModalBody,
  TwoByTwoModalImage,
  OnboardModalNav,
  OnboardModalProgress,
} from 'styles/modal'
import { Button } from 'styles/buttons'

const Feedback = ({currentStep, changeStep, stepCount}) => {
  return <TwoByTwoModalWrapper>

    <TwoByTwoModalBody>

      <h1>
        Request votes
      </h1>

      <WelcomeModalSubheading>
        Let your audience prioritize their own ideas
        {' '}
        <span role="img" aria-label="hands">🙌</span>
      </WelcomeModalSubheading>

      <p>
        Click a button to sort ideas by the most popular
      </p>

      <p>
        Use the built-in timer to keep things moving
      </p>

      <OnboardModalNav>
        <Button tertiary="tertiary" onClick={() => {
          changeStep(currentStep - 1)
        }}>Back</Button>

        <OnboardModalProgress>
          {currentStep + 1} of {stepCount}
        </OnboardModalProgress>

        <Button
          data-test-id="board-onboarding-next" 
          onClick={() => {
            changeStep(currentStep + 1)
          }}
        >
          Next
        </Button>
      </OnboardModalNav>
      
    </TwoByTwoModalBody>

    <TwoByTwoModalImage src="/images/onboarding/onboarding - vote.jpg" />

  </TwoByTwoModalWrapper>
}

export default Feedback
