import React from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { FirestoreDocument } from 'react-firestore'
import queryString from 'query-string'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import FirebaseAuth from 'views/_util/FirebaseAuth'
import AccountLayout from 'views/_layouts/AccountLayout'
import logOut from 'actions/logOut'
import { Button } from 'styles/buttons'
import { LoadingPage } from 'styles/loading'
import ErrorPage from 'views/_components/ErrorPage'

const RequireAccount = () => {

  const history = useHistory()

  const location = useLocation()
  const searchParams = queryString.parse(location.search)
  const redirectTo = searchParams?.redirectTo && decodeURIComponent(searchParams.redirectTo)

  return <FirebaseAuth>
    { ({isLoading, error, auth}) => {
      if (isLoading) {
        return <LoadingPage>Loading</LoadingPage>
      }
      if (error) {
        return <ErrorPage error={error} />
      }
      if (!auth) {
        // should not happen but adding just in case so we don't get errors
        // Note: no redirectTo because once they've authed, this page is irrelevant
        return <Redirect to={{ pathname: "/signup" }} />
      }

      return <FirestoreDocument path={`/users/${auth.uid}`}>
        {({isLoading, error, data}) => {
          if (isLoading) {
            return <LoadingPage>Loading</LoadingPage>
          }
          if (error) {
            return <ErrorPage error={error} />
          }

          const user = data
          
          return <AccountLayout title="Log in with account">

            <h2>Create an account</h2>

            <p>
              Right now you're contributing as
              {' '}
              <em>{(user.displayName && user.displayName.length > 0) ? '"'+user.displayName+'"' : 'an anonymous guest'}</em>
              {' '}
              with no email address.
            </p>
            <p>
              {
                location.state?.message ||
                `You'll have to log in with a full account to see this page.`
              }
            </p>

            <br />

            <Button
              wide
              block
              big="big"
              onClick={() => {
                LogEvent('log-out-to-create-account')
                const url = redirectTo ? `/signup?redirectTo=${window.encodeURIComponent(redirectTo)}` : `/signup`
                logOut()
                  .then( () => history.push(url))
                  .catch( error => LogError('sign you out', error) )
              }}
            >
              Create account or log in
            </Button>
          </AccountLayout>
        }}
      </FirestoreDocument>

    }}
  </FirebaseAuth>
}

export default RequireAccount
