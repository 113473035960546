import React, { useState } from 'react'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import moveCard from 'actions/moveCard'
import {
  DropdownMenuAction,
} from 'styles/dropdown'

const CardDetail = ({
  slide,
  slideRef,
  cardRef,
}) => {
  const [working, setWorking] = useState(false)

  return <DropdownMenuAction
    key={slide.id}
    disabled={slide.id === slideRef.id || working}
    onClick={event => {
      event.preventDefault()
      if (slide.id === slideRef.id) {
        return
      }
      setWorking(true)
      LogEvent('move-card')
      moveCard({cardRef, destinationSlideRef: slide.ref})
        .catch( error => LogError('move this card', error) )
    }}
  >
    <i className="material-icons-round">exit_to_app</i>
    {slide.prompt}
  </DropdownMenuAction>

}

export default CardDetail
