import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import LogEvent from 'actions/LogEvent'
import LogError from 'actions/LogError'
import SLIDE_TYPES from 'data/SlideTypes'
import createSlide from 'actions/createSlide'
import updateBoard from 'actions/updateBoard'
import InteractiveSlideBadge from 'views/_components/InteractiveSlideBadge'
import {
  Button,
} from 'styles/buttons'

const MAX_SLIDES = 3

const AddSlideTypePicker = ({
  isNotSubscribed,
  slides,
  boardRef,
  closeModal,
}) => {

  const history = useHistory()

  useEffect(() => {
    LogEvent('choose-slide-type')
  }, [])

  const blockNewSlides = isNotSubscribed && slides.length >= MAX_SLIDES

  return <div>

    <h2>New slide</h2>
    <p>What kind of slide do you want to create?</p>

    {SLIDE_TYPES.map(type => (
      <SlideTypeOption
        key={type.id}
        disabled={blockNewSlides}
        onClick={ () => {
          if (blockNewSlides) {
            return
          }

          closeModal()

          LogEvent('create-slide')
        
          createSlide({
            boardRef,
            values: {
              order: slides.length,
              type: type.id,
            }
          })
            .then(slideRef => {
              // set the board's current slide to the new slide
              return updateBoard(boardRef, {
                currentSlide: slideRef.id,
              })
            })
            .catch( error => LogError('create this slide', error) )
        }}
      >
        <SlideTypeIconWrapper>
          <i className="material-icons-round">{type.icon}</i>
        </SlideTypeIconWrapper>
        <div>
          <SlideTypeTitle>{type.title}</SlideTypeTitle>
          <SlideTypeSub>{type.description}</SlideTypeSub>
          { type.interactive && <InteractiveSlideBadge /> }
        </div>
      </SlideTypeOption>
    ))}


    {
      blockNewSlides &&
      <>
        <br />
        <hr />
        <div onClick={ () => {
          LogEvent('click-subscription')
          history.push("/subscribe")
        }}>
          <p>
            <strong>
              <span role="img" aria-label="locked">🔒</span>
              {' '}
              Upgrade for more slides
            </strong>
          </p>
          <p>
            Free plans are limited to 3 slides.
            Check out our upgrade options to get unlimited slides, unlimited participants, and more.
          </p>
          <Button green="green">
            See upgrade options
          </Button>
        </div>
      </>
    }

  </div>
}

const SlideTypeOption = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 1rem;
  padding: 1rem;
  margin: 0 -1rem;
  cursor: pointer;
  border-radius: 6px;
  align-items: center;

  background: white;
  opacity: 0.9;

  &:hover {
    background: var(--hover);
    opacity: 1;
  }
  &[disabled] {
    cursor: auto;
    opacity: .7;

    &:hover {
      background: white;
      opacity: 0.7;
    }
  }
`
const SlideTypeIconWrapper = styled.div`
  border-radius: 3px;
  background: var(--background--page);
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 30px;
    color: var(--brand-primary);
  }
`
const SlideTypeTitle = styled.div`
  font-size: var(--m);
  color: var(--text-primary);
  font-weight: 500;
  line-height: 1.1;
`
const SlideTypeSub = styled.div`
  color: var(--text-secondary);
  font-size: var(--s);
`

export default AddSlideTypePicker
