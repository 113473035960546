import styled from 'styled-components/macro'

const WelcomeModalSubheading = styled.h2`
  font-weight: 500;
  font-size: var(--l);
  margin: 1rem 0 2rem;
  line-height: 1.3;
`

export {
  WelcomeModalSubheading,
}