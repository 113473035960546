// update db users on auth

import Firebase from 'firebase/app'
import React from 'react'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/react'

import LogError from 'actions/LogError'
import updateUser from 'actions/updateUser'

class KeepUserUpdated extends React.Component {

  componentDidMount() {
    this.unsubscribe = Firebase.auth()
      .onAuthStateChanged(this.handleAuth, this.handleError)
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  handleAuth = auth => {

    if (!auth) {
      return
    }
    if (auth.isAnonymous) {
      return
    }

    // https://docs.logrocket.com/reference#identify
    const LogRocketInfo = {}
    if (auth.displayName) {
      LogRocketInfo.name = auth.displayName
    }
    if (auth.email) {
      LogRocketInfo.email = auth.email
    }
    if (auth.uid) {
      LogRocket.identify(auth.uid, LogRocketInfo)
    }

    // https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
    const SentryInfo = {}
    if (auth.uid) {
      SentryInfo.id = auth.uid
    }
    if (auth.displayName) {
      SentryInfo.username = auth.displayName
    }
    if (auth.email) {
      SentryInfo.email = auth.email
    }
    if (auth.uid) {
      Sentry.setUser(SentryInfo)
    }

    // Track ActiveCampaign user
    // https://help.activecampaign.com/hc/en-us/articles/221542267-An-overview-of-Site-Tracking#how-to-pass-a-contact-s-email-address-into-javascript-code
    if (process.env.REACT_APP_ENV === 'live' || process.env.REACT_APP_ENV === 'stage') {
      if (auth.email) {
        // eslint-disable-next-line
        (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
        window.vgo('setAccount', '67452952')
        window.vgo('setTrackByDefault', true)
        window.vgo('setEmail', auth.email)
        window.vgo('process')
      }
    }
    
    const update = {}
    if (auth.displayName) {
      update.displayName = auth.displayName
    }
    if (auth.photoURL) {
      update.photoURL = auth.photoURL
    }

    updateUser(auth.uid, update)
      .catch( error => LogError('update this user', error, true) )
  }

  handleError = error => {
    console.error('Auth error; could not update db user info', error)
  }

  render() {
    return null
  }

}

export default KeepUserUpdated