import createBoard from 'actions/createBoard'
import createSlide from 'actions/createSlide'

const duplicateBoard = async ({ orgRef, boardRef }) => {

  const originalBoard = await boardRef
    .get()
    .then(boardSnap => boardSnap.data())
  
  const slides = await boardRef
    .collection('slides')
    .get()
    .then(slidesSnap => slidesSnap.docs.map( slideSnap => slideSnap.data() ))

  const newBoardValues = {
    ...originalBoard,
    hasChangesSinceLastFacilitatorEmailCheck: false,
    name: `${originalBoard.name} copy`,
  }

  const newBoardRef = await createBoard({ orgRef, values: newBoardValues, isDemoBoard: false })

  return Promise.all( slides.map(slide => {
    return createSlide({
      boardRef: newBoardRef,
      values: slide,
    })
  }))
}

export default duplicateBoard
