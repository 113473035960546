import React from 'react'
  
const Card = ({
  id,
}) => {
  return <div style={styles}>
    {id}
  </div>
}

const styles = {
  padding: '.5rem',
  background: 'white',
  color: 'black',
  width: '200px',
  cursor: 'move',
  boxShadow: '0 2px 4px #ddd',
}

export default Card

