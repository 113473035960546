import React, { useState } from 'react'
import Firebase from 'firebase/app'
import { FirestoreDocument } from 'react-firestore'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import Modal from 'views/_components/Modal'
import { AppStateContext } from 'views/AppState'
import { Button } from 'styles/buttons'
import {
  Select,
  SelectWrapper,
} from 'styles/forms'

const DEFAULT_CHOICE_VALUE = 'choose'

const MoveBoardModal = ({
  board,
  orgRef,
  onClose,
}) => {
  const [ destinationOrgId, setDestinationOrgId ] = useState(DEFAULT_CHOICE_VALUE)
  const [ working, setWorking ] = useState(false)

  const doMove = event => {
    event.preventDefault()
    if (destinationOrgId === DEFAULT_CHOICE_VALUE) {
      return
    }
    const confirmMove = window.confirm(`This board's url and invite link will change.\n\nAre you sure you want to continue?`)
    if (!confirmMove) {
      return
    }
    setWorking(true)
    LogEvent('move-board')

    const moveBoardToNewOrg = Firebase.functions().httpsCallable('moveBoardToNewOrg')
    moveBoardToNewOrg({
      boardId: board.id,
      sourceOrgId: orgRef.id,
      destinationOrgId,
    })
      .then( () => {
        setWorking(false)
        alert('Your board was moved!')
        onClose && onClose()
      })
      .catch( error => {
        setWorking(false)
        LogError('move this board', error)
      })
  }

  return <AppStateContext.Consumer>
    { ({auth}) => {
      if (!auth) {
        return null
      }
      return <FirestoreDocument path={`/users/${auth.uid}`}>
        {({isLoading, error, data}) => {
          if (isLoading) {
            return null
          }
          if (error) {
            LogError('get your user information', error)
            return null
          }

          const user = data

          return <Modal narrow onClose={onClose}>
            <h2>Move board - **EXPERIMENTAL**</h2>

            <p>This will change your board's link URL, and some participants may lose access.</p>

            <form onSubmit={doMove}>

              <SelectWrapper>
                <Select
                  type="text"
                  name="destinationOrgId"
                  wide="wide"
                  value={destinationOrgId}
                  onChange={event => setDestinationOrgId(event.target.value)}
                  required
                  autoFocus
                >
                  <option value={DEFAULT_CHOICE_VALUE} disabled>-- Choose destination --</option>
                  {user.knownMemberOrgIds
                    .filter(knownId => orgRef.id !== knownId)
                    .map(knownMemberOrgId => {
                      return <FirestoreDocument key={knownMemberOrgId} path={`/organizations/${knownMemberOrgId}`}>
                        {({isLoading, error, data}) => {
                          if (isLoading || error) {
                            return null
                          }
                          return <option value={knownMemberOrgId} disabled={error || isLoading}>
                            {isLoading ? 'Loading org...' : data.name || 'Unknown org'}
                          </option>
                        }}
                      </FirestoreDocument>
                    })
                  }
                </Select>
              </SelectWrapper>

              <Button
                wide
                big
                working={working}
                type="submit"
              >
                Move board
              </Button>

            </form>

          </Modal>
        }}
      </FirestoreDocument>
    }}
  </AppStateContext.Consumer>

}

export default MoveBoardModal
