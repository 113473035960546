import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import styled from 'styled-components/macro'

import MoveItemDropArea from './MoveItemDropArea'

const SortableCombinableItem = ({
  id,
  index,
  combineItems,
  moveItemToPosition,
  persistItemPositions,
  setSomeoneIsDragging,
  someoneIsDragging,
  canDrag,
  dragItemType,
  combinable,
  displayType,
  itemMargin,
  children,
}) => {

  const ref = useRef(null)

  // DRAGGABLE

  const [{ isBeingDragged }, drag] = useDrag({
    item: {
      type: dragItemType,
      index,
      id,
    },

    canDrag: () => canDrag || false,
    
    collect: monitor => ({
      isBeingDragged: monitor.isDragging(),
    }),

    begin: () => {
      setSomeoneIsDragging(true)
    },
    
    end: () => {
      setSomeoneIsDragging(false)
      persistItemPositions()
    },
  })
  
  // DROPPABLE

  const [{ isHoveredOver}, drop] = useDrop({

    accept: dragItemType,

    canDrop: () => combinable || true,

    collect: monitor => ({
      isHoveredOver: !!monitor.isOver() && monitor.canDrop(),
    }),
  
    drop: droppedItem => {

      if (!combinable && !combineItems) {
        return
      }

      if (!ref.current) {
        return
      }

      if (droppedItem.index === index) {
        return
      }
      
      // if a card is dropped on another item, move the second to the first's index position
      // see CardList for details
      // moveItemToPosition(droppedItem.index, index)

      combineItems(droppedItem.id, id)
    },
    
  })

  drop(ref)
  drag(ref)

  return <SortableCombinableItemWrapper
    ref={ref}
    isBeingDragged={isBeingDragged}
    displayType={displayType}
    itemMargin={itemMargin}
  >
    
    {children({
      isHoveredOver,
      isBeingDragged,
    })}

    {someoneIsDragging &&
      <MoveItemDropArea
        index={index}
        moveItemToPosition={moveItemToPosition}
        dragItemType={dragItemType}
        />
    }

  </SortableCombinableItemWrapper>
}

const SortableCombinableItemWrapper = styled.div`
  position: relative;
  opacity: ${p => p.isBeingDragged && 0 };
  margin: ${p => p.itemMargin || '0'};
  display: ${p => p.displayType || 'inline-block'};
`

export default SortableCombinableItem