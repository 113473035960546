import styled from 'styled-components/macro'

const TwoByTwoModalWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 500px;
  
  @media (max-width: 599px) {
    grid-template-columns: 1fr;
  }
`
const TwoByTwoModalBody = styled.div`
  position: relative;
  padding: 3rem;
  /* display: grid; */
  /* grid-template-rows: auto max-content; */
  /* grid-row-gap: 2rem; */
`
const TwoByTwoModalImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (max-width: 599px) {
    display: none;
  }
`

const OnboardModalNav = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 2rem;
  
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  justify-content: space-between;
  align-items: center;
  margin: 3rem 0 0;
`
const OnboardModalProgress = styled.div`
  color: var(--text-secondary);
  text-align: center;
  font-size: var(--s);
`


export {
  TwoByTwoModalWrapper,
  TwoByTwoModalBody,
  TwoByTwoModalImage,
  OnboardModalNav,
  OnboardModalProgress,
}