import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

const SortableItem = ({
  id,
  canDrag,
  itemMargin,
  displayType,
  children,
}) => {

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    disabled: !canDrag,
  })
  
  const style = {
    position: 'relative',
    margin: itemMargin || '0',
    display: displayType || 'inline-block',
    touchAction: 'none',
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  }

  return <div
    ref={setNodeRef}
    style={style}
    {...listeners}
    {...attributes}
  >
    {children}
  </div>
}

export default SortableItem