import React from 'react'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import updateUser from 'actions/updateUser'
import {
  DropdownMenuAction,
} from 'styles/dropdown'

const ChangeName = ({
  user,
  close,
}) => {

  if (!user || !user.displayName) {
    return
  }

  return <DropdownMenuAction
    onClick={ () => {
      close && close()

      const displayName = window.prompt(`What should we call you?\n\nThis will affect any other boards you joined with this name`, user.displayName)
      if (displayName === null) {
        return
      }
      LogEvent('choose-name')
      updateUser(user.id, { displayName })
        .catch( error => LogError('change your name', error) )
    }}
  >
    <i className="material-icons-round">person</i>	
    <span>
      Change my name
    </span>	
  </DropdownMenuAction>	
}

export default ChangeName