import React from 'react'
import Firebase from 'firebase/app'
import styled from 'styled-components/macro'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import updateCard from 'actions/updateCard'
import CardContainer from 'views/board/cards/CardContainer'
import SortableCombinableList from 'views/_util/SortableCombinable/SortableCombinableList'
// import SortableCombinableListDndKit from 'views/_util/SortableCombinableDndKit/SortableCombinableList'
// import SortableList from 'views/_util/Sortable/SortableList'
import OrderCardsOrSlides from 'actions/helpers/OrderCardsOrSlides'

// https://github.com/LouisBrunner/dnd-multi-backend/tree/master/packages/react-dnd-multi-backend

const CardList = ({
  cardSnaps,
  board,
  isFacilitator,
  slideRef,
  openCardDetail,
}) => {

  const cards = cardSnaps
    .map(cardSnap => ({
      id: cardSnap.id,
      ref: cardSnap.ref,
      ...cardSnap.data(),
    }))

    .sort(OrderCardsOrSlides)

    // give every card an empty array of children
    .map( card => {
      card.childrenIds = []
      return card
    })

    // save the children's IDs to the parent
    // TODO - move this logic from two places up to prompt slide
    .map( (card, cardIndex, cards) => {
      if (card.parentCard) {
        const parent = cards.find(c => c.id === card.parentCard)
        if (parent) {
          parent.childrenIds.push(card.id)
        } else {
          // TODO - fix this? or is it OK?
          console.error('no parent card, this child card will never appear')
        }
      }
      return card
    })

    
    // prepare to be sortable/combinable
    const cardItems = cards
      
      // remove child cards from our list
      .filter( card => !card.parentCard )

      // prepare for SortableCombinable
      .map(card => ({
        id: card.id,
        props: {
          card,
          board,
          cards,
          cardRef: card.ref,
          isFacilitator,
          openCardDetail,
        }
      }))
  

    // HELPER FUNCTIONS

    const moveCardToParent = (childId, parentId) => {
      const childCardRef = slideRef.collection('cards').doc(childId)
      updateCard(childCardRef, { parentCard: parentId })
        .catch( error => LogError('move this card', error) )
    }
  
    const combineCards = (firstId, secondId) => {
      console.log('Combining two cards...', firstId, secondId)

      LogEvent('stack-card')    
      
      // To change this again, flip these
      // and uncomment the line of code in SortableCombinableItem
      // the index of the first card is being updated by SortableCombinable
      const parentCardId = secondId
      const childCardId = firstId

      moveCardToParent(childCardId, parentCardId)
      const childCard = cards.find(c => c.id === childCardId)
      childCard.childrenIds.map(id => moveCardToParent(id, parentCardId) )
    }

    // TODO - move to actions
    const persistCardPositions = cards => {

      console.log('persist cards', cards)

      // make a db batch
      const batch = Firebase.firestore().batch()
      
      // update each card's position in the db
      cards.forEach( (item, index) => {
        const cardRef = slideRef.collection('cards').doc(item.props.card.id)
        batch.update(cardRef, {order: index})
      })
      
      // Commit the batch
      batch.commit()
        .catch( error => LogError('sort this card', error) )
    }
  
  // It would be better if you knew the width of the container so you could make it full width.
  // Instead of scrolling this container, you could scroll the parent, which would look better
  // because it would scroll "full bleed".
  // To do this we probably need a custom layout, not flexbox.

  return <CardsWrapper>
    <SortableCombinableList
      defaultItems={cardItems}
      ItemComponent={CardContainer}
      combineItems={combineCards}
      persistItemPositions={persistCardPositions}
      canDrag={!!isFacilitator}
      combinable={true}
      displayType={`inline-block`}
      itemMargin={'0 1rem .5rem 0'}
    />
  </CardsWrapper>
}


/* https://css-tricks.com/auto-sizing-columns-css-grid-auto-fill-vs-auto-fit/ */
const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start;
  padding: .5rem;
  height: 100%;
  
  /* move? */
  overflow-x: auto;
  overflow-y: hidden; /* should never happen anyway */
`

export default CardList
