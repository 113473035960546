import React, { useState, useEffect } from 'react'
import Firebase from 'firebase/app'
import styled from 'styled-components/macro'

import updateSlide from 'actions/updateSlide'
import LogEvent from 'actions/LogEvent'
import LogError from 'actions/LogError'
import TIMER_PRESETS from 'data/TimerPresets'
import Dropdown from 'views/_components/Dropdown'
import {
  Button,
  ToolbarButton,
  ToolbarButtonLabel,
  NavTooltip,
  HoverTarget,
  ButtonIconWrapper,
} from 'styles/buttons'

const BoardTimer = ({
  currentSlide,
  slideRef,
  isFacilitator,
}) => {

  // ticking clock
  const [nowMillis, setNowMillis] = useState(new Date().getTime())
  useEffect( () => {
    const interval = setInterval( () => {
      setNowMillis(new Date().getTime())
    }, 100)
    return () => clearInterval(interval)
  })

  // check for expired
  useEffect( () => {
    const expireAfter = 60*60
    // TODO - shorter expiration after we have timer end sounds
    // const expireAfter = currentSlide?.timerOptions?.expireAfter || 10
    const expired = timeRemainingSeconds < (-1 * expireAfter)
    if (expired) {
      console.log('timer is old, stopping')
      stopTimer()
    }
  })

  // shared timer state
  const isRunning = !!currentSlide?.timerOptions?.isRunning
  const warnAfter = currentSlide?.timerOptions?.warnAfter || 10
  const startTimeMillis = currentSlide?.timerOptions?.startTime?.toDate().getTime() || new Date().getTime()
  const duration = currentSlide?.timerOptions?.duration || 3*60

  // duration calculations
  const durationMillis = parseInt(duration)*1000
  let durationMinutes = Math.floor(duration / 60)
  let durationSeconds = duration % 60
  
  // timer calculations
  const endMillis = startTimeMillis + durationMillis  
  const timeRemainingMillis = endMillis - nowMillis
  const timeRemainingSeconds = Math.round(timeRemainingMillis / 1000)

  // format percentage
  const percentageComplete =  !isRunning ? 100 :
                              timeRemainingMillis < 0 ? 0 :
                              timeRemainingMillis > durationMillis ? 100 :
                              (timeRemainingMillis / durationMillis) * 100
  
  // format time value
  // TODO - reset timer after 1h
  let timerValue
  if (!isRunning) {
    timerValue = `${durationMinutes}:${durationSeconds}`
  } else if (timeRemainingSeconds <= 0) {
    timerValue = '0:00' // TODO - use another div with "times up"?
  } else if (timeRemainingSeconds > 60 * 60) {
    timerValue = '60:00'
  } else {
    let displayMinutes = Math.floor(timeRemainingSeconds / 60)
    let displaySeconds = timeRemainingSeconds % 60
    
    // don't flash out-of-range values
    if (displayMinutes < 0) {
      displayMinutes = 0
    }
    if (displaySeconds < 0) {
      displaySeconds = 0
    }
    if (displayMinutes > durationMinutes) {
      displayMinutes = durationMinutes
    }
    
    // pad with zeros (seconds only)
    if (displaySeconds < 10) {
      displaySeconds = "0" + displaySeconds
    }
  
    timerValue = `${displayMinutes}:${displaySeconds}`
  }

  // width of timer text div
  const timerTextWidthPadding = isFacilitator ? .5 : 0
  const timerTextWidthNumbers = (timerValue.length - 1) * 0.75
  const timerTextWidthColon = 0.5
  const timerTextWidth =  timerTextWidthNumbers + timerTextWidthColon + timerTextWidthPadding

  // derived timer state
  const done = timeRemainingSeconds <= 0
  const almostDone = timeRemainingSeconds > 0 && timeRemainingSeconds <= warnAfter

  // functions
  const startTimer = ({duration, warnAfter}) => {
    if (!slideRef || !isFacilitator) {
      return null
    }
    const timerOptions = {
      ...currentSlide.timerOptions,
      startTime: Firebase.firestore.Timestamp.now(),
      isRunning: true,
      duration,
      warnAfter,
    }
    LogEvent('start-timer')
    updateSlide(slideRef, { timerOptions })
      .catch( error => LogError('start the timer', error))  
  }
  const stopTimer = () => {
    if (!slideRef || !isFacilitator) {
      return null
    }
    const timerOptions = {
      ...currentSlide.timerOptions,
      startTime: Firebase.firestore.FieldValue.delete(),
      isRunning: false,
    }
    updateSlide(slideRef, { timerOptions })
      .catch( error => LogError('stop the timer', error))
  }
  
  // rendering
  const button = <HoverTarget>
    {isFacilitator &&
      <NavTooltip left="left">
        { done ? 'Timer off' : isRunning ? 'Change timer' : 'Start timer' }
      </NavTooltip>
    }
    <ToolbarButton
      done={done}
      almostDone={almostDone}
      participant={!isFacilitator}
      tabIndex="300"
      onClick={() => done && stopTimer()}
    >
      <div>
        {
          !isFacilitator ?
          null
          :
          (isRunning && !done) ?
          <TimerRadialIcon
            percentageComplete={percentageComplete}
            color={done ? 'white' : almostDone ? 'var(--red-60)' : 'var(--brand-primary)'}
            />
          :
          <i className="material-icons-round">timer</i>
        }
      </div>
      {isRunning ? 
        <TimerText visible={isRunning} width={timerTextWidth+'em'}>{timerValue}</TimerText>
      :
        <ToolbarButtonLabel>Timer</ToolbarButtonLabel>
      }
    </ToolbarButton>
  </HoverTarget>

  return <Dropdown toggle={button} dontShow={done || !isFacilitator} top right>
    { ({close}) => {
      return <div>
        {TIMER_PRESETS.map(preset => {
          return <TimePreset
            key={preset.text}
            onClick={() => {
              close && close()
              const {duration, warnAfter} = preset
              startTimer({ duration, warnAfter })
            }}
          >
            {preset.text}
          </TimePreset>
        })}
        { isRunning &&
          <div style={{padding: '.5rem 0'}}>
            <Button
              wide
              destructive={true}
              onClick={() => {
                close && close()
                stopTimer()
              }}
            >
              <ButtonIconWrapper>
                <i className="material-icons-round">{isRunning ? 'stop' : 'play_arrow'}</i>
                <span>Stop timer</span>
              </ButtonIconWrapper>
            </Button>
          </div>
        }
      </div>
    }}
  </Dropdown>
}

const TimerText = styled.div`
  font-size: var(--m);
  font-family: var(--font-family-mono);
  width: ${p => p.visible ? p.width : '0rem'};
  text-align: right;
  transition: width .2s ease;
  overflow-x: hidden;
`

// Over 200 classes were generated for component BoardTimer__TimerRadialIcon. 
// Consider using the attrs method, together with a style object for frequently changed styles.
const TimerRadialIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100px;
  border: 2px solid ${p => p.color};
  margin: 3px; // match material icon size so hover is smooth 
  background: ${p => `conic-gradient(
    ${p.color},
    ${p.color} ${p.percentageComplete}%,
    transparent ${p.percentageComplete}%,
    transparent
  )`};
`
const TimePreset = styled.div`
  display: block;
  margin: 0 -.5rem;
  padding: .5em;
  color: var(--brand-primary);

  font-size: var(--m);
  font-weight: 500;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background: var(--hover);
  }
  &:focus {
    box-shadow: 0 0 0 2px var(--blue-70);
  }
`

export default BoardTimer
