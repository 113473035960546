import React, { useState } from 'react'
import styled from 'styled-components/macro'
import Clipboard from 'react-clipboard.js'

const CopyLink = ({ disabled }) => {

  const [text, setText] = useState('Copy link')

  return <CopyLinkWrapper disabled={disabled}>

    <JoinTextMask />

    <JoinText disabled={disabled}>{window.location.href}</JoinText>
    
    <Clipboard
      data-clipboard-text={disabled ? '' : window.location.href}
      component={CopyButton}
      onSuccess={() => {
        if (disabled) {
          return
        }
        setText('Copied!')
      }}
    >
      {text}
    </Clipboard>

  </CopyLinkWrapper>
}

const CopyLinkWrapper = styled.div`
  display: grid;
  grid-template-columns: auto max-content;

  padding: .5rem .5rem .5rem 1rem;
  background: white;
  max-width: 24rem;
  border-radius: 6px;
  position: relative;
  user-select: ${p => p.disabled && 'none'};
  margin: 0 0 1rem;
  pointer-events: ${p => p.disabled && 'none'};
  opacity: ${p => p.disabled ? .5 : 1};
  background: var(--background-item);
`
const JoinText = styled.div`
  color: var(--text-secondary);
  padding: 0;
  font-size: 1rem;
  line-height: 2;
  max-width: 100%;
  overflow: hidden;
  user-select: ${p => p.disabled && 'none'};
`
const JoinTextMask = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0 5px 5px 0;
  background: linear-gradient(90deg, var(--background-item-transparent) 0%, var(--background-item) 30%);
  width: 10rem;
  pointer-events: none;
  z-index: 50;
`
const CopyButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 4px;
  font-size: var(--s);
  font-weight: 500;
  padding: .5rem 1rem;
  background-color: var(--brand-primary);
  color: white;
  opacity: .9;
  z-index: 100;

  &:hover {
    opacity: 1;
  }
`

export default CopyLink
