import React from 'react'
import { FirestoreDocument } from 'react-firestore'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import updateUser from 'actions/updateUser'
import { AppLink } from 'styles/buttons'

const ChangeName = ({ auth }) => {

  return <FirestoreDocument path={`/users/${auth.uid}`}>
    {({isLoading, error, data}) => {
      if (isLoading) {
        return 'Loading'
      }
      if (error) {
        LogError('get your user information', error)
        return 'Error getting your profile'
      }
      const user = data
      
      return <div>
        {user.displayName || 'Unknown user'}

        {/*
          ONLY SHOW TO EMAIL-ONLY AUTHED USERS
          this will get overwritten by oauth logins
          if they've had an oauth login previously but their user was deleted,
          it will be editable here but overwritten by the system
          since auth will still contain their old oauth connection
        */}
        {
          auth.providerData &&
          auth.providerData.length === 1 &&
          auth.providerData[0].providerId === 'password' &&
          <>
            {' · '}
            <AppLink
              to=""
              as="span"
              tertiary="tertiary"
              onClick={() => {
                const displayName = window.prompt(`What's your name?`, user.displayName)
                if (displayName === null) {
                  return
                }
                LogEvent('choose-name')
                updateUser(auth.uid, { displayName } )
                  .catch( error => LogError('update your user', error) )
              }}
            >
              Change your name
            </AppLink>
          </>
        }
      </div>
    }}
  </FirestoreDocument>
}

export default ChangeName
