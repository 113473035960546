import React, { useState } from 'react'
import { withSize } from 'react-sizeme'

import getCardPositions from './getCardPositions.js'
import Card from './Card'

const CardLayout = ({ cardData, ParentComponent, size }) => {
    
  const [ cards, setCards ] = useState(cardData)

  const onCardSizeInfo = ({ index, size }) => {
    const myCards = [...cards]
    myCards[index].height = size.height
    setCards(myCards)
  }

  const cardsWithPositions = getCardPositions({
    containerWidth: size.width,
    containerHeight: size.height,
    cards,
  })

  return <div style={{width: '100%', height: '100%', position: 'relative', }}>
    {cardsWithPositions.map( (card, index) => <Card
      key={index}
      Component={ParentComponent}
      card={card}
      onSize={ size => onCardSizeInfo({ index, size })}
      updateManually={ height => onCardSizeInfo({ index, size: {height} })}
    /> )}
  </div>
}

export default withSize({ monitorHeight: true, })(CardLayout)
