import React from 'react'

import {
  WelcomeModalSubheading,
} from 'styles/headings'
import {
  TwoByTwoModalWrapper,
  TwoByTwoModalBody,
  TwoByTwoModalImage,
  OnboardModalNav,
  OnboardModalProgress,
} from 'styles/modal'
import { Button } from 'styles/buttons'

const Drag = ({currentStep, changeStep, stepCount}) => {
  return <TwoByTwoModalWrapper>

    <TwoByTwoModalBody>

      <h1>
        Sort and combine ideas
      </h1>

      <WelcomeModalSubheading>
        Just like real sticky notes
        {' '}
        <span role="img" aria-label="sparkles">✨</span>
      </WelcomeModalSubheading>

      <p>
        Drag and drop cards to keep everything organized
      </p>
      {/* <p>
        Drag cards around to reorder them.
      </p>

      <p>
        Combine ideas by dropping them on top of each other.
      </p> */}

      <OnboardModalNav>
        <Button tertiary="tertiary" onClick={() => {
          changeStep(currentStep - 1)
        }}>Back</Button>

        <OnboardModalProgress>
          {currentStep + 1} of {stepCount}
        </OnboardModalProgress>

        <Button
          data-test-id="board-onboarding-next" 
          onClick={() => {
            changeStep(currentStep + 1)
          }}
        >
          Next
        </Button>
      </OnboardModalNav>
      
    </TwoByTwoModalBody>

    <TwoByTwoModalImage src="/images/onboarding/onboarding - cards.jpg" />

  </TwoByTwoModalWrapper>
}

export default Drag
