// UI-only global flag, toggleable in testing, to show debug elements

import React, { useState } from 'react'

export const DebugContext = React.createContext()

const DebugState = ({children}) => {

  const [debugging, setDebugging] = useState(null)
  
  // so we can trigger it in the console
  window.debug = setDebugging

  return <DebugContext.Provider value={debugging}>
    {children}
  </DebugContext.Provider>
}

export default DebugState