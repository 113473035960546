import React, { useState } from 'react'
import Firebase from 'firebase/app'
import { useHistory } from 'react-router-dom'

import LogEvent from 'actions/LogEvent'
import LogError from 'actions/LogError'
import { Button, AppLink } from 'styles/buttons'

const SettingsSubscription = ({
  subscribed,
  freeAccount,
  orgId,
  isOwner,
  debugging,
}) => {

  const history = useHistory()

  const [buttonWorking, setButtonWorking] = useState(false)

  const manageSubscription = async () => {

    console.log('managing subscription...')
    
    setButtonWorking(true)
    
    LogEvent('manage-subscription')
    
    console.log('getting a session URL from the backend...')
    const origin = window.location.origin
    const getBillingPortalSession = Firebase.functions().httpsCallable('createStripeBillingPortalSession')

    const sessionUrl = await getBillingPortalSession({ origin, orgId })
      .then( result => result.data.sessionUrl )
      .catch( error => LogError('get Stripe billing portal session', error, true) )
      
    if (!sessionUrl) {
      alert(`Sorry, we couldn't link you to the customer portal you right now. It's our fault.\n\nPlease try again soon, or contact us at getshuffleboard.com/support and we'll take care it for you.`)
      setButtonWorking(false)
      return
    }
    
    console.log('...got session url', sessionUrl)
    
    window.location.href = sessionUrl
  }

  return <>
    {
      !subscribed ?
      <div>
        <p>You are on an unlimited free trial. Upgrade to get unlimited participants, reports, and more.</p>
        { isOwner ?
          <Button onClick={ () => history.push("/subscribe") }>See upgrade options</Button>
        :
          <p><strong>Contact your account owner to upgrade</strong></p>
        }
      </div>
      :
      (!freeAccount || debugging) ?
      <div>
        <p>You're subscribed! Thank you for supporting Shuffleboard :)</p>
        <br />

        <p>Need to change your payment method, update billing information, or cancel your subscription?</p>
        <Button working={buttonWorking} onClick={manageSubscription}>Manage subscription</Button>
        <br />
        <br />

        <p>
          Need help? We're here for you!
          {' '}
          <AppLink green="green" onClick={ () => history.push("/support") }>Contact our support team</AppLink>.
        </p>

      </div>
      :
      <div>
        <p>Hi friend! You've got a special account with free access.</p>
        <p>The free plan is limited to only a few facilitators. To upgrade to a full account, get in touch and we'll talk discounts :)</p>
        <Button as={'a'} href="https://getshuffleboard.com/contact" target="_blank">Get in touch</Button>
      </div>
    }
  </>
}

export default SettingsSubscription
