import styled from 'styled-components/macro'

const AppLayoutHeader = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  align-items: center;
  margin: 1rem 0 2rem;

  h1 {
    font-size: var(--xl);
    font-weight: 700;
  }
`

export {
  AppLayoutHeader,
}
