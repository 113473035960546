import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { PDFDownloadLink } from '@react-pdf/renderer'

import LogError from 'actions/LogError'
import GetSerializedReportData from 'actions/GetSerializedReportData'
import ErrorPage from 'views/_components/ErrorPage'
import TakeoverLayout from 'views/_layouts/TakeoverLayout'
import Report from 'views/reports/Report'
import { LoadingPage } from 'styles/loading'
import { Button, ButtonIconWrapper } from 'styles/buttons'
import Chatbox from 'views/_components/Chatbox'

const ReportContainer = ({match}) => {

  let [loading, setLoading] = useState(true)
  let [error, setError] = useState(undefined)
  let [reportData, setReportData] = useState({})

  // WITHOUT THIS CONDITION
  // the component re-renders forever
  // not sure why
  if (!reportData.board) {
    GetSerializedReportData({
      orgId: match.params.orgId,
      boardId: match.params.boardId,
    })
      .then( reportData => {
        setReportData(reportData)
        setLoading(false)
      })
      .catch( error => {
        LogError('get report data', error, true)
        setError(error)
        setLoading(false)
      })
  }

  if (loading) {
    return <LoadingPage>Loading</LoadingPage>
  }
  if (error) {
    return <ErrorPage error={error} />
  }
  
  if (!reportData.board || !reportData.board.exists) {
    return <ErrorPage error={new Error(`This board either doesn't exist or was deleted`)} />
  }

  if (!reportData.subscribed) {
    reportData.missingSlideCount = reportData.board.slides.length > 1 ? reportData.board.slides.length - 1 : 0
    reportData.board.slides = reportData.board.slides.slice(0, 2)
  }

  return <TakeoverLayout title="Download meeting notes">

    <DownloadWrapper>
      <DownloadHeader>{reportData.board.name}</DownloadHeader>
      <DownloadSubHeader>{reportData.board.slides.length} slide{reportData.board.slides.length !== 1 && 's'}</DownloadSubHeader>
      <PDFDownloadLink document={<Report {...reportData} />} fileName={`${reportData.board.name} | Shuffleboard report.pdf`}>
        {({ blob, url, loading, error }) => {
          return <Button big="big" disabled={loading || error}>
            <ButtonIconWrapper>
              <i className="material-icons-round">
                {loading ? 'hourglass_empty' : 'picture_as_pdf'}
              </i>
              <span>
                {loading ? 'Loading PDF...' : 'Download PDF'}
              </span>
            </ButtonIconWrapper>
          </Button>
        }}
      </PDFDownloadLink>
    </DownloadWrapper>
    
    <Chatbox />

  </TakeoverLayout>

}

const DownloadWrapper = styled.div`
  text-align: center;
  margin: 10vh auto;
  max-width: 20rem;
`
const DownloadHeader = styled.h1`
  margin: 0;
`
const DownloadSubHeader = styled.div`
  margin: 0 0 2rem;
`

export default ReportContainer
