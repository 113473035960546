import React from 'react'

import {
  DropdownMenuAction,
} from 'styles/dropdown'

const LeaveBoard = ({
  close,
  leaveBoard,
}) => {
  return <DropdownMenuAction onClick={() => {
    close && close()
    leaveBoard()
  }}>
    <i className="material-icons-round">home</i>
    Exit board
  </DropdownMenuAction>
}

export default LeaveBoard
