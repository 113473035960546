import React from 'react'
import Firebase from 'firebase/app'
import { FirestoreCollection } from 'react-firestore'

import FirebaseAuth from 'views/_util/FirebaseAuth'
import SubscriptionStatus from 'views/_util/SubscriptionStatus'
import ErrorPage from 'views/_components/ErrorPage'
import { AppStateContext } from 'views/AppState'
import Boards from 'views/boards/Boards'
import { LoadingPage } from 'styles/loading'
import EnsureLoggedInAndInAnOrg from 'views/EnsureLoggedInAndInAnOrg'

const BoardsContainer = () => {

  return <EnsureLoggedInAndInAnOrg>
    <AppStateContext.Consumer>
      { ({orgId}) => {
        return <FirebaseAuth>
          { ({isLoading, error, auth}) => {
            if (isLoading) {
              return <LoadingPage>Loading</LoadingPage>
            }
            if (error) {
              return <ErrorPage error={error} />
            }

            return <SubscriptionStatus>
              { ({isLoading, error, subscribed, boardsRemaining}) => {

                if (isLoading) {
                  return <LoadingPage>Loading</LoadingPage>
                }
                if (error) {
                  return <ErrorPage error={error} />
                }

                return <FirestoreCollection path={`organizations/${orgId}/boards`}>
                  {({ isLoading, error, snapshot }) => {
                    if (isLoading) {
                      return <LoadingPage>Loading</LoadingPage>
                    }
                    if (error) {
                      return <ErrorPage error={error} />
                    }

                    let boards = snapshot.docs.map(queryDocumentSnapshot => ({
                      id: queryDocumentSnapshot.id,
                      ref: queryDocumentSnapshot.ref,
                      ...queryDocumentSnapshot.data(),
                    })).sort( (a,b) => {
                      const aDate = a.createdOn || a.updatedOn
                      const bDate = b.createdOn || b.updatedOn
                      return bDate.toMillis() - aDate.toMillis()
                    })
              
                    const orgRef = Firebase.firestore()
                      .collection('organizations')
                      .doc(orgId)

                    return <Boards
                      boards={boards}
                      orgRef={orgRef}
                      subscribed={subscribed}
                      boardsRemaining={boardsRemaining}
                    />

                  }}
                </FirestoreCollection>
              }}
            </SubscriptionStatus>
          }}
        </FirebaseAuth>
      }}
    </AppStateContext.Consumer>
  </EnsureLoggedInAndInAnOrg>
}

export default BoardsContainer
