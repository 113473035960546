import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet'

import {
  CloseX,
} from 'styles/misc'

const TakeoverLayout = ({
  title,
  prevScreen,
  allowBack,
  children,
}) => {
  const history = useHistory()

  return <TakeoverLayoutWrapper>

    <Helmet>
      <title>{title || 'App'} | Shuffleboard</title>
    </Helmet>

    {prevScreen &&
      <CloseX fixed onClick={() => history.push(prevScreen)}>
        <i className="material-icons-round">close</i>
      </CloseX>
    }

    {allowBack &&
      <CloseX fixed onClick={() => history.goBack()}>
        <i className="material-icons-round">close</i>
      </CloseX>
    }

    {children}

  </TakeoverLayoutWrapper>
}

const TakeoverLayoutWrapper = styled.div`
  padding: 2rem;
  
  @media (max-width: 599px) {
    padding: 1rem;
  }
`

export default TakeoverLayout
