import React, { useState, useCallback, useEffect } from 'react'
import update from 'immutability-helper'
import { DndProvider } from 'react-dnd'
import MultiBackend, { Preview } from 'react-dnd-multi-backend'
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch'

import SortableCombinableItem from './SortableCombinableItem'

const SortableCombinableList = ({
  defaultItems,
  ItemComponent,
  combineItems,
  persistItemPositions,
  canDrag,
  combinable,
  dragItemType,
  displayType,
  itemMargin,
}) => {

  const [items, setItems] = useState(defaultItems)

  const [someoneIsDragging, setSomeoneIsDragging] = useState(false)

  // we need to update the draggable cards when we get new card props (ie on some db change)
  useEffect( () => {
    setItems(defaultItems)
  }, [defaultItems])

  const moveItemToPosition = useCallback(
    (dragIndex, hoverIndex) => {
      const movedItem = items[dragIndex]
      setItems(
        update(items, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, movedItem],
          ],
        }),
      )
    },
    [items],
  )

  return <DndProvider backend={MultiBackend} options={HTML5toTouch}>

    {/* TODO - use real styles */}
    <Preview>
      <Preview.Context.Consumer>
        {({itemType, item, style}) => (
          <div style={{
            ...style,
            backgroundColor: 'white',
            color: 'var(--text-primary)',
            padding: '1rem',
            minWidth: '50px',
            minHeight: '50px',
            fontSize: 'var(--l)',
            fontWeight: '500',
            zIndex: 10000,
          }}>
            {item.content}
          </div>
        )}
      </Preview.Context.Consumer>
    </Preview>

  
    {items.map( (item, index) => (
      <SortableCombinableItem
        key={item.id}
        id={item.id}
        index={index}
        combineItems={combineItems}
        moveItemToPosition={moveItemToPosition}
        persistItemPositions={() => persistItemPositions(items)}
        setSomeoneIsDragging={setSomeoneIsDragging}
        someoneIsDragging={someoneIsDragging}
        canDrag={canDrag}
        combinable={combinable}
        dragItemType={dragItemType || 'item'}
        displayType={displayType}
        itemMargin={itemMargin}
      >
        {({isHoveredOver, isBeingDragged}) => (
          <ItemComponent
            draggableListIndex={index}
            isHoveredOver={isHoveredOver}
            isBeingDragged={isBeingDragged}
            {...item.props}
            />
        )}
      </SortableCombinableItem>
    ))}

  </DndProvider>
}

export default SortableCombinableList