import React from 'react'
import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import SidebarAccount from 'views/_components/SidebarAccount'

const sidebarWidth = 260

const AppLayout = ({
  title,
  children,
}) => {
  return <AppLayoutWrapper>
    <Helmet>
      <title>{title || 'App'} | Shuffleboard</title>
    </Helmet>

    <AppSidebar>
      <AppSidebarTop>
        <Logo to="/boards">
          <LogoImage src="/favicon.png" />
          <LogoText>Shuffleboard</LogoText>
        </Logo>
        <SidebarLink to="/boards" activeClassName="selected">
          <i className="material-icons-round">dashboard</i>
          Boards
        </SidebarLink>
        <SidebarLink to="/templates" activeClassName="selected">
          <i className="material-icons-round">add_circle</i>
          Templates
        </SidebarLink>
      </AppSidebarTop>

      <AppSidebarBottom>
        <SidebarLink to="/settings" activeClassName="selected">
          <i className="material-icons-round">settings</i>
          Settings
        </SidebarLink>
        <SidebarAccount />
      </AppSidebarBottom>
    </AppSidebar>
    
    <AppMain>
      <AppPageWrapper>
        {children}
      </AppPageWrapper>
    </AppMain>
  </AppLayoutWrapper>
}

const AppLayoutWrapper = styled.div`
  min-height: 100%;
`

// sidebar
const AppSidebar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  height: 100vh;
  width: ${sidebarWidth}px;
  display: grid;
  grid-template-rows: auto max-content;
  background: var(--background-page);
  box-shadow: inset 0 2px 6px var(--shadow);
  z-index: 1;

  @media (max-width: 599px) {
    width: 100%;
    width: 100vw;
    height: auto;
    position: relative;
  }
`
const AppSidebarTop = styled.div`
`
const Logo = styled(NavLink)`
  position: relative;
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: .75rem;
  align-items: center;

  outline: none;
  cursor: pointer;
  padding: 1rem;
  
  transition: all .2s ease;
`
const LogoImage = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`
const LogoText = styled.div`
  color: var(--text-primary);
  font-weight: 600;
  text-decoration: none;
`
const AppSidebarBottom = styled.div`
`
const SidebarLink = styled(NavLink)`
  padding: .75rem .5rem;
  margin: .5rem;

  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: .75rem;
  
  border-radius: 6px;
  user-select: none;
  font-weight: 500;
  line-height: 24px; /* based on icon size */
  color: var(--text-primary);

  transition: all .2s ease;

  &.selected {
    background: var(--tan-92);
    &:hover {
      background: var(--tan-92);
    }
  }  

  &:hover {
    background: var(--tan-94);
  }

  i {
    padding: 2px 2px 2px 4px;
    font-size: 20px;
    color: var(--text-tertiary);
  }
`

// main
const AppMain = styled.div`
  padding: 2rem 2rem 6rem;
  background: white;
  margin-left: ${sidebarWidth}px;
  min-height: 100%;

  @media(max-width: 599px) {
    padding: 2rem 1rem 4rem;
    margin-left: 0;
    min-height: auto;
  }
`
const AppPageWrapper = styled.div`
  margin: 0 auto;
  max-width: 52rem;
`

export default AppLayout
