import React from 'react'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import createOrg from 'actions/createOrg'
import { AppStateContext } from 'views/AppState'
import { AppLink } from 'styles/buttons'

const AddOrg = ({ auth }) => {

  return <AppStateContext.Consumer>
      {({orgId, switchOrg}) => {
        return <AppLink
          to=""
          as="span"
          onClick={() => {
            const confirm = window.confirm("This will create an entirely new organization on a free trial tier and no other members. \n\nAre you sure you want to continue?")
            if (!confirm) {
              return
            }
            const name = window.prompt("What's the name of this organization?")
            if (!name) {
              return
            }
            LogEvent('create-org')
            createOrg(auth.uid, {name})
              .then(orgRef => switchOrg(orgRef.id) )
              .catch( error => LogError('create this organization', error) )
          }}
        >
          + New organization
        </AppLink>
    }}
  </AppStateContext.Consumer>
}

export default AddOrg
