import React from 'react'
import Firebase from 'firebase/app'

class FirebasePresence extends React.Component {

  state = {
    loading: true,
    error: null,
    presentParticipants: [],
  }

  usersRef = Firebase.database().ref(`/boards/${this.props.boardId}/users`)

  componentDidMount() {
    this.watchUsers = this.usersRef.on('value', snapshot => {
      const usersObject = snapshot.val()

      if (!usersObject) {
        // nobody here yet (only until presence updates)
        this.setState({
          loading: false,
          error: null,
          presentParticipants: [],
        })
      } else {
        const arrayOfUsers = Object.keys(usersObject).map(id => ({id,}) )
        this.setState({
          loading: false,
          error: null,
          presentParticipants: arrayOfUsers,
        })
      }
    }, error => {
      this.setState({
        loading: false,
        error,
        presentParticipants: null,
      })
    })
  }

  componentWillUnmount() {
    if (this.watchUsers) {
      this.usersRef.off('value', this.watchUsers)
    }
  }

  render() {
    return this.props.children(this.state)
  }
}

export default FirebasePresence
