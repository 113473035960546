import React from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet'

const PresentationLayout = ({
  title,
  children,
}) => {
  return <PresentationLayoutWrapper>
    <Helmet>
      <title>{title || 'App'} | Shuffleboard</title>
    </Helmet>
    {children}
  </PresentationLayoutWrapper>
}

const PresentationLayoutWrapper = styled.div`
  padding: 1rem 2rem;
  
  @media (max-width: 599px) {
    padding: 1rem;
  }
`

export default PresentationLayout
