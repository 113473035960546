import React from 'react'

import FirebaseAuth from 'views/_util/FirebaseAuth'
import BoardMembershipWatcher from 'views/board/watchers/BoardMembershipWatcher'
import LogError from 'actions/LogError'

const BoardMembershipWatcherContainer = ({boardRef}) => {
  return <FirebaseAuth>
    { ({isLoading, error, auth}) => {
      if (isLoading) {
        return ''
      }
      if (error || !auth) {
        LogError('get auth for board membership watcher', error, true)
        return ''
      }
      return <BoardMembershipWatcher userId={auth.uid} boardRef={boardRef} />
    }}
  </FirebaseAuth>
}

export default BoardMembershipWatcherContainer
