import React from 'react'

import { DropdownMenuAction } from 'styles/dropdown'

const AddSlide = ({
  isFacilitator,
  close,
  addSlide,
}) => {

  if (!isFacilitator) {
    return null
  }
  
  return <DropdownMenuAction
    to=""
    onClick={() => {
      addSlide()
      close && close()
    }}
  >
    <i className="material-icons-round">add_box</i>
    Add slide
  </DropdownMenuAction>

}

export default AddSlide