import React from 'react'
import { FirestoreDocument, FirestoreCollection } from 'react-firestore'
import Firebase from 'firebase/app'

import Modal from 'views/_components/Modal'
import LogError from 'actions/LogError'
import FirebaseAuth from 'views/_util/FirebaseAuth'
import CardDetail from 'views/board/card_detail/CardDetail'

const CardDetailContainer = ({
  closeCardDetails,
  isFacilitator,
  board,
  slides,
  slideRef,
  cardId,
}) => {
  return <Modal
    noPadding
    wide
    shiftedCloseX
    onClose={closeCardDetails}
  >
    <FirestoreDocument path={`${slideRef.path}/cards/${cardId}`}>
      {({ isLoading, error, data, snapshot }) => {
        if (isLoading) {
          return 'Loading...'
        }
        if (error) {
          LogError('get a card author', error)
          return 'Error showing this card--sorry about that!'
        }
        
        const card = data
        const cardRef = snapshot.ref
      
        return <FirestoreCollection path={`${slideRef.path}/cards`}>
          {({ isLoading, error, data }) => {
            if (isLoading) {
              return 'Loading...'
            }
            if (error) {
              LogError('get cards on a slide', error)
              return 'Error showing this card--sorry about that!'
            }
    
            const cards = data

            // save the children's IDs to the parent
            // TODO - move this logic from two places up to prompt slide
            card.childrenIds = []
            cards.map( potentialChildCard => {
              if (potentialChildCard.parentCard === card.id) {
                card.childrenIds.push(potentialChildCard.id)
              }
              return null
            })
        
            return <FirestoreDocument path={`users/${card.createdBy}`}>
              {({ isLoading, error, data }) => {
                if (isLoading) {
                  return 'Loading...'
                }
                if (error) {
                  LogError('get a card\'s author', error)
                  return 'Error showing this card--sorry about that!'
                }

                const cardAuthor = data

                return <FirebaseAuth>
                  { ({isLoading, error, auth}) => {
                    if (isLoading) {
                      return 'Loading...'
                    }
                    if (error) {
                      LogError('get your user information', error)
                      return 'Error showing this card--sorry about that!'
                    }

                    let userReactions = card._votes && card._votes.filter(v => v.createdBy === auth.uid)
                    if (userReactions) {
                      // we cannot assume the user's vote is on this card--it could be in a child card
                      // TODO - we also cannot assume there is only one relevant user reaction
                      userReactions = userReactions.map(ur => ({
                        ref: Firebase.firestore().doc(ur.path),
                        ...ur,
                      }))
                    }

                    return <CardDetail
                      isFacilitator={isFacilitator}
                      board={board}
                      slides={slides}
                      slideRef={slideRef}
                      card={card}
                      cardRef={cardRef}
                      cardAuthor={cardAuthor}
                      userIsOwner={auth.uid === card.createdBy}
                      userReactions={userReactions}
                    />
                
                  }}
                </FirebaseAuth>
                
              }}
            </FirestoreDocument>

          }}
        </FirestoreCollection>

      }}
    </FirestoreDocument>

  </Modal>
}

export default CardDetailContainer
