import React from 'react'
import styled from 'styled-components/macro'

import Dropdown from 'views/_components/Dropdown'
import AddSlide from 'views/board/settings/AddSlide'
import ShareAndExport from 'views/board/settings/ShareAndExport'
import LeaveBoard from 'views/board/settings/LeaveBoard'
import LoggedInAs from 'views/board/settings/LoggedInAs'
import ManageSlides from 'views/board/settings/ManageSlides'
import ShowCards from 'views/board/settings/ShowCards'
import ShowCardAuthors from 'views/board/settings/ShowCardAuthors'
import AllowVotingAnytime from 'views/board/settings/AllowVotingAnytime'
import ParticipantRoaming from 'views/board/settings/ParticipantRoaming'
import ShowTour from 'views/board/settings/ShowTour'
import GetHelp from 'views/board/settings/GetHelp'
import ChangeName from 'views/board/settings/ChangeName'
import LogOut from 'views/board/settings/LogOut'
import RenameBoard from 'views/board/settings/RenameBoard'
import {
  HoverTarget,
  NavTooltip,
} from 'styles/buttons'

const BoardSettings = ({
  user,
  board,
  boardRef,
  isFacilitator,
  slides,
  goToPrevSlide,
  boardOrgId,
  isNotSubscribed,
  addSlide,
  leaveBoard,
  shareBoard,
}) => {

  return <Dropdown wide lowered
    toggle={
      <HoverTarget>
        <NavTooltip top="top">
          { isFacilitator ? 'Board settings' : 'Board actions' }
        </NavTooltip>
        <BoardSettingsButton>
          {isFacilitator ?
            <i className="material-icons-round">settings</i>
          :
            <i className="material-icons-round">more_vert</i>
          }
        </BoardSettingsButton>
      </HoverTarget>
    }>
      { ({close}) => {
        return <div>
          
          {isFacilitator &&
            <>
              <ManageSlides
                boardRef={boardRef}
                isFacilitator={isFacilitator}
                slides={slides}
                goToPrevSlide={goToPrevSlide}
                close={close}
              />

              <AddSlide
                board={board}
                boardRef={boardRef}
                isFacilitator={isFacilitator}
                close={close}
                isNotSubscribed={isNotSubscribed}
                slides={slides}
                addSlide={addSlide}
              />

              <RenameBoard
                board={board}
                boardOrgId={boardOrgId}
                isFacilitator={isFacilitator}
              />

              <ShareAndExport
                shareBoard={shareBoard}
                close={close}
              />
 
              <hr />

              {/* <ToggleQR
                board={board}
                boardRef={boardRef}
                close={close}
              /> */}
    
              <ShowCards
                board={board}
                boardRef={boardRef}
              />
    
              <ShowCardAuthors
                board={board}
                boardRef={boardRef}
              />
        
              <AllowVotingAnytime
                board={board}
                boardRef={boardRef}
              />
        
              <ParticipantRoaming
                board={board}
                boardRef={boardRef}
              />
        
              <hr />
       
              <ShowTour
                close={close}
              />

            </>
          }

          <GetHelp />

          <LeaveBoard
            leaveBoard={leaveBoard}
            close={close}
          />

          {!isFacilitator &&
            <>
              <ShareAndExport
                shareBoard={shareBoard}
                close={close}
              />
              <ChangeName
                user={user}
                close={close}
              />
              <LogOut
                close={close}
              />
            </>
          }

          <LoggedInAs />

        </div>
      }}
    </Dropdown>
}

const BoardSettingsButton = styled.div`
  color: var(--text-tertiary);
  opacity: .8;
  transition: opacity .2s ease;
  cursor: pointer;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;  
  user-select: none;

  &:hover {
    background: var(--hover);
    opacity: 1;
  }
`

export default BoardSettings