import React from 'react'
import styled from 'styled-components/macro'
import { useDocument } from 'react-firebase-hooks/firestore'
import Firebase from 'firebase/app'
import Tippy from '@tippyjs/react'

import BubbleHeadListItem from 'views/_components/BubbleHeadListItem'

const ParticipantListItem = ({
  participant,
  isFacilitator,
}) => {

  const [userSnapshot, userLoading, userError] = useDocument(
    Firebase.firestore().doc(`/users/${participant.id}`)
  )

  let user
  if (userSnapshot) {
    user = userSnapshot.data()
  }

  return <>
    {userLoading && '...'}
    {userError && 'Whoops--could not load this user'}
    {userSnapshot && 
      <ParticipantListItemWrapper>
        <BubbleHeadListItem
          userId={participant.id}
          absent={participant.absent}
          isFacilitator={participant.isFacilitator}
        />
        <ParticipantName>{user.displayName}</ParticipantName>
        <Tippy
          interactive
          disabled={!isFacilitator}
          maxWidth={300}
          content={participant.isFacilitator ?
            <TooltipWrapper>
              <TooltipText>This person is a facilitator because they're a member of your organization</TooltipText>
              <TooltipLink href="/settings" target="_blank">Manage facilitators</TooltipLink>
            </TooltipWrapper>
            :
            <TooltipWrapper>
              <TooltipText>Add this person to your organization to make them a facilitator</TooltipText>
              <TooltipLink href="/settings" target="_blank">Add facilitator</TooltipLink>
            </TooltipWrapper>
          }
        >
          <ParticipantRole>
            {participant.isFacilitator ? 'Facilitator' : 'Participant'}
            {isFacilitator &&
              <HoverIcon>
                <i className="material-icons-round">help</i>
              </HoverIcon>
            }
          </ParticipantRole>
        </Tippy>
      </ParticipantListItemWrapper>
    }
  </>
}


const ParticipantListItemWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr 1fr;
  grid-column-gap: 1rem;
  padding: .5rem 0;
  align-items: center;
`
const ParticipantName = styled.div`
  font-size: var(--m);
  font-weight: 500;
  color: var(--text-primary);
  line-height: 1;
  `
const ParticipantRole = styled.div`
  font-size: var(--s);
  color: var(--text-secondary);
  line-height: 1;
`
const HoverIcon = styled.span`
  position: relative;
  top: 2px;
  left: 5px;
  
  i {
    font-size: 14px;
    color: var(--text-tertiary);
  }
`
const TooltipWrapper = styled.div`
  padding: .5rem;
`
const TooltipText = styled.p`
  color: inherit;
`
const TooltipLink = styled.a`
  text-decoration: underline;
  font-weight: 500;
`

export default ParticipantListItem
