import React from 'react'
import styled from 'styled-components/macro'

const CIRCLE = 10;
const PADDING = 3;

const ToggleSwitch = ({ on }) => <ToggleSwitchWrapper isOn={on} />

const ToggleSwitchWrapper = styled.div`
  display: inline-block;
  width: ${CIRCLE * 2 + PADDING * 4}px;
  height: ${CIRCLE + PADDING * 2}px;
  /* background: ${p => p.isOn ? 'var(--brand-primary)' : 'var(--background-item)'}; */
  background: var(--brand-primary);
  opacity: ${p => p.isOn ? 1 : .3};
  border-radius: 1000px;
  position: absolute;
  right: .5rem;
  top: .75rem;
  transition: opacity .2s ease;

  &:after {
    content: '';
    display: block;
    width: ${CIRCLE}px;
    height: ${CIRCLE}px;
    
    position: absolute;
    top: 0;
    left: ${p => p.isOn ? '50%' : 0};
    transform: translate( ${PADDING}px, ${PADDING}px);
    
    background: white;
    z-index: 10;
    border-radius: 1000px;
    transition: left .2s ease;
  }
`
export default ToggleSwitch
