import React, { useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import styled from 'styled-components/macro'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import createCard from 'actions/createCard'
import updateUser from 'actions/updateUser'
import BoardTourInputStep from 'views/board/onboarding_tour/BoardTourInputStep'

const SOFT_WARNING_THRESHOLD = 150
const HARD_WARNING_THRESHOLD = 200

const InputBox = ({
  isFacilitator,
  user,
  board,
  slideRef,
}) => {

  const [value, setValue] = useState('')

  const submitDisabled = value.length < 1

  const onFocus = () => {
    if (isFacilitator && !user.hasClosedBoardTourInputStep) {
      updateUser(user.id, { hasClosedBoardTourInputStep: true })
        .catch( error => LogError('create this idea', error), true)
    }
  }

  const onTextareaType = event => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault()
      submitIdea()
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    submitIdea()
  }
  
  const submitIdea = () => {
    if (submitDisabled) {
      return
    }

    LogEvent('create-card')

    if (isFacilitator && board.isDemoBoard && !user.hasSubmittedDemoBoardIdea) {
      updateUser(user.id, { hasSubmittedDemoBoardIdea: true })
        .catch( error => LogError('create this idea', error), true)
    }

    createCard({
      slideRef,
      values: {
        content: value,
      }
    })
      .then( () => setValue(''))
      .catch( error => LogError('create this idea', error) )
  }

  const softWarning = value.length >= SOFT_WARNING_THRESHOLD && value.length < HARD_WARNING_THRESHOLD
  const hardWarning = value.length >= HARD_WARNING_THRESHOLD

  return <>
    { user &&
      isFacilitator &&
      board.isDemoBoard &&
      user.hasClosedFirstBoardModal &&
      !user.hasClosedBoardTourInputStep &&
      <BoardTourInputStep user={user} />
    }

    <IdeaInputForm
      onSubmit={handleSubmit}
      isFacilitator={isFacilitator}
    >
      <InputTwoByTwoLayout>
        <IdeaInput
          type="text"
          name="content"
          maxLength={1000}
          value={value}
          onChange={event => setValue(event.target.value)}
          onKeyDown={onTextareaType}
          onFocus={onFocus}
          placeholder="Type your answer..."
          autoFocus={!isFacilitator}
          autoComplete="off"
          required
          tabIndex="310"
          data-test-id="input-box"
        />
        <IdeaButton
          type="submit"
          tabIndex="310"
          data-test-id="input-box-submit"
        >
          <i className="material-icons-round">send</i>
        </IdeaButton>
      </InputTwoByTwoLayout>
      <InputLengthWarningWrapper softWarning={softWarning} hardWarning={hardWarning}>
        <InputLengthWarning softWarning={softWarning} hardWarning={hardWarning}>
          { softWarning ? 'This is a long answer' : hardWarning ? 'Your answer is very long' : ''}
        </InputLengthWarning>
      </InputLengthWarningWrapper>
    </IdeaInputForm>
  </>
}

const IdeaInputForm = styled.form`

  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%);

  max-width: calc(100% - 2rem);
  width: 24rem;
  margin: 0 auto;
  border-radius: 2rem;
  z-index: 100;

  border: 2px solid transparent;
  background: var(--blue-96);
  transition: all .2s ease;

  &:focus-within,
  &:active {
    border-color: var(--blue-70);
    background: var(--blue-98);
  }

  @media (max-width: 599px) {
    bottom: ${p => p.isFacilitator ? '5.5rem' : '1rem'};
  }
`
const InputTwoByTwoLayout = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
`
const IdeaInput = styled(TextareaAutosize)`
  color: var(--text-primary);
  border: none;
  font-family: var(--font-family-sans);
  line-height: 1.4;
  font-size: var(--m);
  padding: 1rem 1.25rem;
  cursor: pointer;
  margin: 0;
  resize: none;
  background: transparent;

  // focus style is taken care of by the parent
  outline: none;

  &::placeholder {
    color: var(--blue-85);
  }
`
const InputLengthWarningWrapper = styled.div`
  text-align: center;
  height: ${p => (p.softWarning || p.hardWarning) ? '1.5rem' : '0rem' };
  margin: ${p => (p.softWarning || p.hardWarning) ? '-.75em 0 0' : '0' };
  overflow: hidden;
  transition: all .4s ease;
`
const InputLengthWarning = styled.div`
  display: inline-block;
  padding: 0 .75em;

  color: ${p => p.softWarning ? 'var(--red-50)' : 'white' };
  background: ${p => p.hardWarning ? 'var(--red-60)' : 'transparent'};
  font-size: var(--xs);
  font-weight: 500;
  line-height: 1.2rem;
  border-radius: 100px;
`
const IdeaButton = styled.button`
  background: transparent;
  border: none;
  font-family: var(--font-family-sans);
  padding: 0 1rem;

  font-size: var(--m);
  cursor: pointer;

  // focus style is taken care of by the parent
  outline: none;

  color: var(--blue-60);
  &:hover,
  &:focus {
    color: var(--blue-50);
  }
`

export default InputBox
