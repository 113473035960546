import React from 'react'
import { FirestoreCollection } from 'react-firestore'

import LogError from 'actions/LogError'
import BoardListItem from 'views/boards/BoardListItem'

const BoardListItemContainer = ({
  board,
  orgRef,
}) => {
  return <FirestoreCollection path={`${board.ref.path}/members/`}>
    {({ isLoading, error, data }) => {
      if (isLoading) {
        return <BoardListItem board={board} orgRef={orgRef} loading />
      }
      if (error) {
        LogError('get your board\'s members', error)
        return 'error' // TODO
      }
      
      // https://stackoverflow.com/a/10124053
      const boardMembers = data.sort( (a,b) => {
        const aDate = a.createdOn || a.updatedOn
        const bDate = b.createdOn || b.updatedOn
        return aDate.toMillis() - bDate.toMillis()
      }).map(member => ({
        isFacilitator: member.id === board.createdBy,
        ...member,
      }))

      return <BoardListItem
        board={board}
        orgRef={orgRef}
        boardMembers={boardMembers}
      />
    }}
  </FirestoreCollection>
}

export default BoardListItemContainer
