import React, { useState, useEffect } from 'react'

import LogError from 'actions/LogError'
import OnboardingTourPopup from 'views/_components/OnboardingTourPopup'
import updateUser from 'actions/updateUser'
import { Button } from 'styles/buttons'

const BoardTourSettingsStep = ({
  user,
}) => {
  
  const [visible, setVisible] = useState(false)
  useEffect( () => {
    setTimeout( () => setVisible(true), 2*1000)
  })

  return <OnboardingTourPopup left="left" visible={visible}>
    <div style={{padding: '1.5rem 2rem'}} onClick={ () => {
      updateUser(user.id, { hasClosedBoardTourSettingsStep: true })
        .catch( error => LogError('end onboarding', error) )
    }}>
      <p>
        Use these buttons to:
      </p>
      <ul>
        <li>
          Show a timer
        </li>
        <li>
          Request votes
        </li>
        <li>
          Sort ideas by most voted
        </li>
      </ul>
        
      <Button dark="dark" wide>Got it</Button>
    </div>
  </OnboardingTourPopup>
}

export default BoardTourSettingsStep
