import React from 'react'

const SortableItemDragOverlay = ({
  children,
}) => {
  return <div
    style={style}
  >
    {children}
  </div>
}

const style = {
  boxShadow: '0 12px 12px var(--shadow-overlay)',
}

export default SortableItemDragOverlay