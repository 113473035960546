import { prepareDocForUpdate } from './helpers/firestoreHelpers'

const updateBoard = (boardRef, values) => {
  return boardRef
    .set(prepareDocForUpdate(values), {merge: true})

    // after updating, return the document's value
    // firestore normally returns a promise void, but we need to return the data (for createBoard)
    .then(() => {
      return boardRef
        .get()
        .then(boardSnap => ({
          id: boardSnap.id,
          ...boardSnap.data(),
        }))
    })
}

export default updateBoard
