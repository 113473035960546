import React from 'react'

import FirebaseAuth from 'views/_util/FirebaseAuth'
import PresenceWatcher from 'views/board/watchers/PresenceWatcher'
import LogError from 'actions/LogError'

const PresenceWatcherContainer = ({boardId}) => {
  return <FirebaseAuth>
    { ({isLoading, error, auth}) => {
      if (isLoading) {
        return ''
      }
      if (error || !auth) {
        LogError('get auth for presence system', error, true)
        return ''
      }
      return <PresenceWatcher userId={auth.uid} boardId={boardId} />
    }}
  </FirebaseAuth>
}

export default PresenceWatcherContainer
