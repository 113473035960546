import React from 'react'
import { useDroppable } from '@dnd-kit/core'

const CombineItemDropArea = ({
  id,
  combinable,
}) => {

  const {
    isOver,
    setNodeRef,
  } = useDroppable({
    id,
  })
  
  const style = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    touchAction: 'none',
    background: isOver ? 'hsla(50, 80%, 60%, .8)' : 'hsla(50, 80%, 60%, .1)',
    zIndex: 1000,
  }

  return <div
    ref={setNodeRef}
    style={style}
  />
}

export default CombineItemDropArea