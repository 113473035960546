import React from 'react'
import { FirestoreDocument } from 'react-firestore'
import styled from 'styled-components/macro'
import Firebase from 'firebase/app'

import LogError from 'actions/LogError'
import FirebaseAuth from 'views/_util/FirebaseAuth'
import Card from 'views/board/cards/Card'

const CardContainer = ({
  board,
  cards,
  cardRef,
  card,
  isFacilitator,
  isHoveredOver,
  isBeingDragged,
  openCardDetail,
}) => {
  return <FirestoreDocument path={`users/${card.createdBy}`}>
    {({ isLoading, error, data }) => {
      if (isLoading) {
        return <CardLoadingWrapper>...</CardLoadingWrapper>
      }
      if (error) {
        LogError('get a card\'s author', error)
        return 'error'
      }

      const cardAuthor = data

      return <FirebaseAuth>
        { ({isLoading, error, auth}) => {
          if (isLoading) {
            return <CardLoadingWrapper>...</CardLoadingWrapper>
          }
          if (error) {
            LogError('get your user information', error)
            return 'error'
          }

          let userReactions = card._votes && card._votes.filter(v => v.createdBy === auth.uid)
          if (userReactions) {
            // we cannot assume the user's vote is on this card--it could be in a child card
            // TODO - we also cannot assume there is only one relevant user reaction
            userReactions = userReactions.map(ur => ({
              ref: Firebase.firestore().doc(ur.path),
              ...ur,
            }))
          }

          return <Card
            board={board}
            cards={cards}
            card={card}
            cardRef={cardRef}
            cardAuthor={cardAuthor}
            isFacilitator={isFacilitator}
            userIsOwner={auth.uid === card.createdBy}
            userReactions={userReactions}
            isHoveredOver={isHoveredOver}
            isBeingDragged={isBeingDragged}
            openCardDetail={openCardDetail}
            />
      
        }}
      </FirebaseAuth>
      
    }}
  </FirestoreDocument>
}

const CardLoadingWrapper = styled.div`
  padding: .5rem 2rem .5rem .66rem;
  border-radius: 4px;
  background: white;
  color: var(--text-tertiary);
  box-shadow: 0px 3px 6px var(--shadow);
  width: 250px;
  margin: 0 1rem .5rem 0;
`

export default CardContainer
