const defaultCards = [
  {
    id:    'homer',
    color: 'homer',
  },
  {
    id:    'marge',
    color: 'marge',
  },
  {
    id:    'lisa',
    color: 'lisa',
  },
  {
    id:    'bart',
    color: 'bart',
  },
  {
    id:    'maggie',
    color: 'maggie',
  },
  {
    id:    'lou',
    color: 'lou',
  },
  {
    id:    'lenny',
    color: 'lenny',
  },
  {
    id:    'barney',
    color: 'barney',
  },
  {
    id:    'edna',
    color: 'edna',
  },
  {
    id:    'itchy',
    color: 'itchy',
  },
  {
    id:    'scratchy',
    color: 'scratchy',
  },
  {
    id:    'moe',
    color: 'moe',
  },
  {
    id:    'ralph',
    color: 'ralph',
  },
  {
    id:    'seymour',
    color: 'seymour',
  },
]

export default defaultCards