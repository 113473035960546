import React from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet'

const OnboardingLayout = ({
  title,
  children,
}) => {
  return <OnboardingLayoutWrapper>
    <Helmet>
      <title>{title || 'Welcome'} | Shuffleboard</title>
    </Helmet>
    <OnboardingLayoutBox>
      {children}
    </OnboardingLayoutBox>
  </OnboardingLayoutWrapper>
}

const OnboardingLayoutWrapper = styled.div`
  padding: 2vh .5rem;
  background: var(--background-page);
  min-height: 100%;
  
  /*
    This gets overwritten by some other thing unless it's !important...
    Don't judge me. I can feel you judging me.
  */
  height: auto !important;

  @media(max-width: 599px) {
    background: white;
    padding: 1rem .5rem;
  }
`
const OnboardingLayoutBox = styled.div`
  margin: 0 auto;
  padding: 2rem;
  max-width: 34rem;

  @media(max-width: 599px) {
    padding: 1rem;
  }

  p.reply {
    margin: 0 0 .5rem;
    font-size: var(--m);
  }
  h2 {
    margin: 0 0 1.5rem;
    font-size: var(--xl);
    font-weight: 600;
  }
`

export default OnboardingLayout
