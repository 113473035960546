import React, { useEffect, useState } from 'react'
import Firebase from 'firebase/app'

import LogError from 'actions/LogError'
import updateBoard from 'actions/updateBoard'
import updateUser from 'actions/updateUser'
import PromptSlideContainer from 'views/board/slides/PromptSlideContainer'
import TextSlideContainer from 'views/board/slides/TextSlideContainer'
import { LoadingPage } from 'styles/loading'
import { Button } from 'styles/buttons'

const SlideManager = ({
  user,
  boardRef,
  board,
  slides,
  children,
  addSlide,
  isFacilitator,
}) => {
  
  const [ roamSlideId, setRoamSlideId ] = useState()

  const participantsMayRoam = board.displayOptions && board.displayOptions.participantRoaming

  useEffect( () => {
    if (slides.length === 0) {
      return
    }
    if (!isFacilitator) {
      return
    }
    const currentSlideId = board.currentSlide
    const noSlideSelected = !currentSlideId
    const selectedSlideDoesNotExist = slides.findIndex(s => s.id === currentSlideId) === -1
    if (noSlideSelected || selectedSlideDoesNotExist) {
      console.log(`no default slide, attempting to update board now\nUser id: ${user.id}\nisFacilitator: ${isFacilitator}\nSlide count: ${slides.length}\nBoard id: ${board.id}`)
      updateBoard(boardRef, {
        lastSlideAction: Firebase.firestore.Timestamp.now(),
        currentSlide: slides[0].id,
      })
        .catch( error => LogError('set default current slide', error) )
    }
  })

  // When the facilitator 1) disables roaming or 2) changes slides with roaming off,
  // set everyone's local roaming slide to the shared facilitator's current slide.
  // This way, when roaming is re-enabled, nobody will "jump"
  // to the slide they were on while roaming previously.
  useEffect( () => {
    if (!participantsMayRoam) {
      setRoamSlideId(board.currentSlide)
    }
  }, [
    participantsMayRoam,
    setRoamSlideId,
    board.currentSlide,
  ])

  const boardHasSlides = slides.length > 0

  if (!boardHasSlides) {
    // TODO - add link to return home? no other way to get back
    return <div style={{textAlign: 'center', margin: '30vh'}}>
      <p>No slides on this board yet</p>
      {isFacilitator &&
        <Button onClick={addSlide}>Add a slide</Button>
      }
    </div>
  }

  const currentSlideId = (participantsMayRoam && roamSlideId) ? roamSlideId : board.currentSlide

  if (!currentSlideId) {
    // just for a sec while we wait for slide to be set (above)
    return <LoadingPage>Loading</LoadingPage>
  }
  
  const currentSlideIndex = slides.findIndex(slide => slide.id === currentSlideId)

  if (currentSlideIndex === -1) {
    // just for a sec while we wait for slide to be set (above)
    return <LoadingPage>Loading</LoadingPage>
  }

  const currentSlide = slides.find(slide => slide.id === currentSlideId)

  const onFirstSlide = currentSlideId === slides[0].id
  const onLastSlide = currentSlideId === slides[slides.length - 1]?.id

  const nextSlide = slides[currentSlideIndex + 1]
  const nextSlideTitle = nextSlide?.prompt

  const prevSlide = slides[currentSlideIndex - 1]
  const prevSlideTitle = prevSlide?.prompt

  const SlideComponent = currentSlide?.type === 'cards' ? PromptSlideContainer :
                         currentSlide?.type === 'text'  ? TextSlideContainer :
                                                          PromptSlideContainer

  const changeSlide = direction => {
    if (!user.hasClosedBoardTourNavStep || !user.hasClosedBoardTourSettingsStep) {
      updateUser(user.id, {
        hasClosedBoardTourNavStep: true,
        hasClosedBoardTourSettingsStep: true,
        hasClosedBoardTourInputStep: true,
      })
        .catch( error => LogError('update your user', error, true) )
    }

    if ( (direction === 1 && !nextSlide) || (direction === -1 && !prevSlide) ) {
      return
    }
    
    const newSlideId = slides[currentSlideIndex + direction].id

    // set locally for roamers
    if (participantsMayRoam) {
      setRoamSlideId(newSlideId)
    }

    // set for everyone
    // this way, when the option is turned off, everyone returns to the most
    // recent slide a facilitator navigated to
    if (isFacilitator) {
      updateBoard(boardRef, {
        lastSlideAction: Firebase.firestore.Timestamp.now(),
        currentSlide: newSlideId,
      })
        .catch( error => LogError('change the slide', error) )
    }
  }

  const slideRef = boardRef.collection('slides').doc(currentSlideId)

  return children({
    SlideComponent,
    goToPrevSlide: () => changeSlide(-1),
    goToNextSlide: () => changeSlide(1),
    nextSlideTitle,
    prevSlideTitle,
    boardHasSlides,
    slideType: currentSlide?.type || 'text',
    onFirstSlide,
    onLastSlide,
    numberOfSlides: slides.length,
    currentSlideIndex,
    currentSlide,
    slideRef,
    currentSlideId,
  })

}

export default SlideManager
