import React, { useState } from 'react'
import Firebase from 'firebase/app'
import { Redirect } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import styled from 'styled-components/macro'

import LogEvent from 'actions/LogEvent'
import LogError from 'actions/LogError'
import TakeoverLayout from 'views/_layouts/TakeoverLayout'
import { Button, AppLink } from 'styles/buttons'
import { Banner } from 'styles/misc'
import Chatbox from 'views/_components/Chatbox'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const Subscribe = ({ subscribed, orgId }) => {
  
  const [buttonWorking, setButtonWorking] = useState(false)

  const subscribeStatus = URLSearchParams &&
    window.location.search &&
    new URLSearchParams(window.location.search).get('status')

  const upgrade = async () => {

    console.log('subscribing...')
    
    setButtonWorking(true)
    
    LogEvent('subscribed')
    
    console.log('getting a session ID from the backend...')
    const origin = window.location.origin
    const getCheckoutSession = Firebase.functions().httpsCallable('createStripeCheckoutSession')
    
    const sessionId = await getCheckoutSession({ origin, orgId })
      .then( result => result.data.sessionId )
      .catch( error => LogError('get Stripe checkout session', error, true) )
      
    if (!sessionId) {
      alert(`Sorry, we couldn't subscribe you right now. It's our fault.\n\nPlease try again soon!`)
      setButtonWorking(false)
      return
    }

    console.log('...got session id', sessionId)

    const stripe = await stripePromise

    stripe
      .redirectToCheckout({
        sessionId,
      })
      .then( result => {
        if (result.error) {
          const error = result.error
          LogError('create your subscription', error)
        }
      })
      .catch( error => LogError('create your subscription', error) )
      .finally( () => {
        setButtonWorking(false)
      })

  }

  if (subscribed && !subscribeStatus) {
    return <Redirect to="/settings" />
  }

  return <TakeoverLayout allowBack title="Subscribe">
    
    <SubscribeWrapper>

      {/* We're returning from an failed subscribe */}
      { subscribeStatus === 'cancelled' &&
        <Banner>Whoops--looks like you cancelled your payment. Try again to subscribe.</Banner>
      }

      <SubscribeHero>
        <h1>
          <span role="img" aria-label="sparkle">✨</span>
          {' '}
          Never miss another great idea
          {' '}
          <span role="img" aria-label="sparkle">✨</span>
        </h1>
        <p>Give your team the gift of great discussions</p>
      </SubscribeHero>

      {/* KEEP IN SYNC WITH PRICING PAGE */}
      <PlansWrapper>
        <Plan>
          <PlanTitle>Premium</PlanTitle>
          <PlanSub>for small teams</PlanSub>
          <PlanPrice>
            <PlanPriceCurrency>$</PlanPriceCurrency>
            <PlanPriceAmount>29</PlanPriceAmount>
            <PlanPriceFrequency>per month</PlanPriceFrequency>
          </PlanPrice>
          <Button
            big
            wide
            working={buttonWorking}
            onClick={upgrade}
          >
            Subscribe now
          </Button>
          <hr />
          <PlanFeatures>
            <strong>
              Unlimited participants<br/>
              Unlimited boards<br/>
              Unlimited slides<br/>
              Full PDF reports<br/>
            </strong>
            Up to 5 facilitators
          </PlanFeatures>
        </Plan>
        
        <Plan>
          <PlanTitle>Pro</PlanTitle>
          <PlanSub>for large teams</PlanSub>
          <PlanPrice>
            <PlanPriceText>Contact us</PlanPriceText>
          </PlanPrice>
          <Button
            big
            wide
            onClick={() => window.location = "https://getshuffleboard.com/contact"}
            secondary="secondary"
          >
            Talk to sales
          </Button>
          <hr />
          <PlanFeatures>
            <strong>
              Unlimited participants<br/>
              Unlimited boards<br/>
              Unlimited slides<br/>
              Full PDF reports<br/>
              Unlimited facilitators
            </strong>
          </PlanFeatures>
        </Plan>
      </PlansWrapper>

      <SubscribeColumns>

        <SubscriptionLetter>
          <p>
            Hi,
          </p>
          <p>
            I'm on a mission to fix bad communication at work. If you've made it this far, you probably care about that too.
          </p>
          <p>
            Shuffleboard is my passion project. If you find it useful, please consider supporting me by becoming a subscriber.
          </p>
          <p>
            Thank you!
          </p>
          <p>
            Sam
          </p>
        </SubscriptionLetter>

        <SubscriptionInfo>
          <p>Includes a 30-day, money-back, no-questions-asked guarantee.</p>
          <p>Your subscription will renew automatically every month until you cancel.</p>
          <p>You'll get an email receipt every month.</p>
          <p>Cancel anytime in <AppLink to="/settings">account settings</AppLink>.</p>
          <p>Questions? Need help? <AppLink to="/support">Contact our support team</AppLink>.</p>
        </SubscriptionInfo>

      </SubscribeColumns>

    </SubscribeWrapper>

    <Chatbox />
  </TakeoverLayout>
}


const SubscribeWrapper = styled.div`
  max-width: 40rem;
  margin: 0 auto;
`
const SubscribeHero = styled.div`
  padding: 1rem;
  text-align: center;
  margin: 2rem 0 0;
`

// plans

const PlansWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  justify-content: center;
  margin: 2rem 0;

  @media (max-width: 599px) {
    grid-template-columns: 1fr;
  }
`
const Plan = styled.div`
  padding: 2rem;
  border-radius: 8px;
  border: 1px solid var(--hairline);
  box-shadow: 0 2px 4px var(--shadow);
`
const PlanTitle = styled.div`
  font-size: var(--l);
  font-weight: 600;
  color: var(--text-primary);
`
const PlanSub = styled.div`
  font-size: var(--s);
  margin: 0 0 1rem;
`
const PlanPrice = styled.div`
  height: 3rem;
  margin: 0 0 .5rem;
`
const PlanPriceCurrency = styled.div`
  display: inline;
  font-size: var(--xs);
  position: relative;
  top: -.5rem;
`
const PlanPriceAmount = styled.div`
  display: inline;
  font-weight: 600;
  color: var(--text-primary);
  font-size: var(--xxl);
  margin: 0 .15rem 0 0;
  line-height: 3rem;
`
const PlanPriceFrequency = styled.div`
  display: inline;
  font-size: var(--s);
`
const PlanPriceText = styled.div`
  font-weight: 600;
  color: var(--text-primary);
  font-size: var(--l);
  line-height: 3rem;
`
const PlanFeatures = styled.div`
  font-weight: normal;
  line-height: 2rem;

  strong {
    font-weight: 600;
  }
`

// misc

const SubscribeColumns = styled.div`
  border-top: 2px solid var(--hairline);
  margin: 2rem 0;
  padding: 2rem 0;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-column-gap: 4rem;
  grid-row-gap: 2rem;

  @media(max-width: 599px) {
    grid-template-columns: auto;
  }
`
const SubscriptionLetter = styled.div`
  p {
    color: var(--text-primary);
  }
`
const SubscriptionInfo = styled.div`
  font-size: var(--s);

  p {
    margin: 0 0 .5em;
  }
`

export default Subscribe
