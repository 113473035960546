import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import createOrg from 'actions/createOrg'
import OnboardingLayout from 'views/_layouts/OnboardingLayout'
import { Button } from 'styles/buttons'
import { OnboardingInput, InputNote } from 'styles/forms'

const CreateOrg = ({
  auth,
  displayName,
  switchOrg,
}) => {

  const history = useHistory()

  const [ name, setName ] = useState('')

  const handleSubmit = event => {
    event.preventDefault()
    LogEvent('create-org')
    createOrg(auth.uid, { name })
      .then(org => switchOrg(org.id) )
      .then( () => history.push('/boards') ) // not necessary?
      .catch( error => LogError('create the organization', error) )
  }

  return <OnboardingLayout>
    <p className="reply">Hi {displayName.split(' ')[0]}!</p>
    <h2>What's the name of your company or organization?</h2>

    <form onSubmit={handleSubmit} data-test-id="onboarding-org">
      <OnboardingInput
        type="text"
        name="orgName"
        value={name}
        onChange={event => setName(event.target.value)}
        placeholder="Acme, Inc"
        maxLength={1000}
        required
        autoFocus
      />
      <InputNote>This will be visible when you invite people to a session</InputNote>

      <div style={{textAlign: 'right', margin: '2rem 0 0'}}>
        <Button big="big" type="submit">
          Next
        </Button>
      </div>

    </form>
  </OnboardingLayout>
}

export default CreateOrg
