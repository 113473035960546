const INTERNAL_EXTERNAL_OPTIONS = [
  {
    id: 'internal',
    label: org => `${org.name || `Our company's`} employees, mostly`,
  },
  {
    id: 'external',
    label: org => `Our clients, customers, or other external folks`,  
  },
  {
    id: 'both',
    label: org => `We do a lot of both`,
  },
]

export default INTERNAL_EXTERNAL_OPTIONS
