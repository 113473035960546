import React, { useState, useEffect } from 'react'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import {
  restrictToWindowEdges,
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers'

import SortableItem from './SortableItem'
import SortableItemDragOverlay from './SortableItemDragOverlay'

const SortableList = ({
  defaultItems,
  ItemComponent,
  combineItems,
  persistItemPositions,
  canDrag,
  combinable,
  displayType,
  itemMargin,
  vertOnly,
  parentOnly,
}) => {

  const [items, setItems] = useState(defaultItems)
  const [activeId, setActiveId] = useState(null)
  const [overId, setOverId] = useState(null)

  // we need to update the draggable cards when we get new card props (ie on some db change)
  useEffect( () => {
    setItems(defaultItems)
  }, [defaultItems])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  // modifiers
  const modifiers = [restrictToWindowEdges]
  if (vertOnly) {
    modifiers.push(restrictToVerticalAxis)
  }
  if (parentOnly) {
    modifiers.push(restrictToParentElement)
  }
  
  const handleDragStart = event => {
    setActiveId(event.active.id)
  }
  
  const handleDragOver = event => {
    const { active, over } = event

    setOverId(over.id)

    if (active.id !== over.id) {
      setItems( items => {
        const oldIndex = items.findIndex(i => i.id === active.id)
        const newIndex = items.findIndex(i => i.id === over.id)
        const newItems = arrayMove(items, oldIndex, newIndex)
        return newItems
      })
    }
  }
  
  const handleDragEnd = event => {
    setActiveId(null)
    setOverId(null)
    persistItemPositions(items)
  }
  
  return <DndContext
    sensors={sensors}
    collisionDetection={closestCenter}
    modifiers={modifiers}
    onDragStart={handleDragStart}
    onDragOver={handleDragOver}
    onDragEnd={handleDragEnd}
  >
    <SortableContext
      items={items.map(i => i.id)}
      strategy={ () => {}}
    >
      {
        items.map(item => (
          <SortableItem
            key={item.id}
            id={item.id}
            canDrag={canDrag}
            displayType={displayType}
            itemMargin={itemMargin}
            isHoveredOver={item.id === overId}
          >
            <ItemComponent
              id={item.id}
              {...item.props}
            />
          </SortableItem>
        ))
      }
    </SortableContext>
    <DragOverlay>
      <SortableItemDragOverlay>
        { activeId &&
          <ItemComponent
            id={activeId}
            {...items.find( i => i.id === activeId).props}
          />
        }
      </SortableItemDragOverlay>
    </DragOverlay>
  </DndContext>
}

export default SortableList