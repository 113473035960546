// TODO - empty should not be a template
const BOARD_TEMPLATES = [
  {
    id: 'demo',
    name: `Getting started`,
    description: `A quick guided tour of Shuffleboard. Perfect for first-timers.`,
    materialIcon: 'auto_fix_high',
    slides: [
      {
        type: 'cards',
        prompt: `What's going well?`,
      },
      {
        type: 'text',
        prompt: `That's it!`,
        subPrompt: `This is a text-only slide. Only the facilitator can edit these.\n\nClick the board name in the upper left to leave the board.`,
      },
    ],
  },
  {
    id: 'empty',
    name: `Empty board`,
    description: `Start from scratch`,
    materialIcon: 'flare',
    slides: [
      {
        type: 'cards',
        prompt: ``,
      },
    ],
    website: {
      order: 100,
      intro: `
        <p>This is an empty Shuffleboard template to let you build any session you want.</p>
        <p>All templates are editable, so if you start with another template, you can always change the text and slides.</p>
      `,
      // agendaIntro: `
      //   <p></p>
      // `,
      conclusion: `
        <p>Good luck!</p>
      `,
    },
  },
  {
    id: 'sprint-retrospective',
    name: `Sprint retrospective`,
    description: `What went well and not so well? What will you change?`,
    materialIcon: 'history',
    slides: [
      {
        type: 'text',
        prompt: 'Retrospective',
        subPrompt: `How are we doing lately?`,
      },
      {
        type: 'text',
        prompt: 'The Prime Directive',
        subPrompt: `"Regardless of what we discover, we understand and truly believe that everyone did the best job they could, given what they knew at the time, their skills and abilities, the resources available, and the situation at hand."\n\n- Norm Kerth`,
      },
      {
        type: 'cards',
        prompt: `What went well?`,
      },
      {
        type: 'cards',
        prompt: `What didn't go so well?`,
      },
      {
        type: 'text',
        prompt: `That's it!`,
        subPrompt: 'Thanks for joining us :)',
      },
    ],
    website: {
      order: 50,
      intro: `
        <p>I was shocked when I attended my first sprint retrospective.</p>
        <p>Our software development team had been struggling to ship good features quickly. Worse, we had been struggling to communicate and coordinate with other teams. It felt like we were wading through mud, and we couldn't make progress we wanted to make—despite having a team of seven people.</p>
        <p>Our team lead Becky led us in our first retro. She started with the same Prime Directive in this template, and guided us through the positives, negatives, and fixes for our next sprint. We had a chance to share problems in a constructive way with each other, and our communication got better every week.</p>
        <p>Ever since I've been a huge believer in the power of retros, especially for highly collaborative teams.</p>
        <p>In my opinion, there's no better way to create a culture of improvement than taking the time with these three questions every two weeks.</p>
      `,
      agendaIntro: `
        <p>A retro usually has two big pieces: what's going well and what's not. The last, shorter slide is for the team to agree on a few things they'll start doing differently.</p>
      `,
      conclusion: `
        <p>If you're a team of close collaborators—and especially if you're developing software—try a retrospective after your next deadline. It could make a world of difference for your team.</p>
        <p> - Sam</p>
      `,
    },
  },
  {
    id: 'purpose-workshop',
    name: `Purpose workshop`,
    description: `Origin, vision, mission, strategy, tactics`,
    materialIcon: 'filter_hdr',
    slides: [
      {
        type: 'text',
        prompt: 'Defining our purpose',
        subPrompt: `Who are we, where are we going, and why?`,
      },
      {
        type: 'cards',
        prompt: 'What is our origin story?',
        subPrompt: 'Who created this organization? And more importantly, why?',
      },
      {
        type: 'cards',
        prompt: 'What is our vision?',
        subPrompt: 'What do we think a better world would look like?',
      },
      {
        type: 'cards',
        prompt: 'What is our mission?',
        subPrompt: 'What will we do in the next 50 years to make that vision come true?',
      },
      {
        type: 'cards',
        prompt: 'What is our strategy?',
        subPrompt: 'What unique way are we going to go about accomplishing that goal?',
      },
      {
        type: 'cards',
        prompt: 'What are our tactics?',
        subPrompt: 'What specific activities do we need to perform to get there?',
      },
      {
        type: 'text',
        prompt: `That's it!`,
        subPrompt: 'Thanks for joining us :)',
      },
    ],
    website: {
      order: 20,
      intro: `
        <p>As a consultant, I worked with a lot of companies trying to define their products and brands.</p>
        <p>The first place to start is usually understanding the company's "DNA". Who are they, and what are they really trying to accomplish?</p>
      `,
      agendaIntro: `
        <p>The purpose workshop starts out with a discussion of the founding of your organization. After that it zooms out to the big vision you have for the world, and incrementally focuses the team on concrete plans and next steps to get there.</p>
      `,
      conclusion: `
        <p>If your team is getting to the size where misalignment is creating conflict, consider an exercise like this to help everyone agree on your purpose and goals.</p>
        <p> - Sam</p>
      `,
    },
  },
  {
    id: 'brand-strategy',
    name: `Brand strategy`,
    description: `Brand personality, emotion, and differentiators`,
    materialIcon: 'remove_red_eye',
    slides: [
      {
        type: 'text',
        prompt: 'Defining our brand',
        subPrompt: `Get those creative juices flowing...`,
      },
      {
        type: 'cards',
        prompt: 'Who is your audience?',
      },
      {
        type: 'cards',
        prompt: 'What emotions do you want to evoke?',
      },
      {
        type: 'cards',
        prompt: 'What personality traits do you want your brand to have?',
      },
      {
        type: 'cards',
        prompt: 'If your brand was a person, who would it be?',
      },
      {
        type: 'cards',
        prompt: 'What makes you different from your competitors?',
      },
      {
        type: 'text',
        prompt: `That's it!`,
        subPrompt: 'Thanks for joining us :)',
      },
    ],
    website: {
      order: 20,
      intro: `
        <p>A brand is a hard thing to pin down.</p>
        <p>Getting at the heart of a brand requires creativity, taste, open-mindedness, and deep intuition for what your customers want.</p>
        <p>A brand strategy workshop doesn't guarantee an amazing brand, but it's a great starting place.</p>
      `,
      agendaIntro: `
        <p>The brand strategy workshop consists of a series of prompts:</p>
      `,
      conclusion: `
        <p>Good luck!</p>
      `,
    },
  },
  {
    id: 'swot-analysis',
    name: `SWOT analysis`,
    description: `Strengths, weaknesses, opportunities, threats`,
    materialIcon: 'compare_arrows',
    slides: [
      {
        type: 'text',
        prompt: 'SWOT Analysis',
        subPrompt: `Evaluating our strengths, weaknesses, opportunities, and threats`,
      },
      {
        type: 'cards',
        prompt: 'What are your strengths?',
        subPrompt: `Helpful and internal`,
      },
      {
        type: 'cards',
        prompt: 'What are your weaknesses?',
        subPrompt: `Harmful and internal`,
      },
      {
        type: 'cards',
        prompt: 'What are your opportunities?',
        subPrompt: `Helpful and external`,
      },
      {
        type: 'cards',
        prompt: 'What are your threats?',
        subPrompt: `Harmful and external`,
      },
      {
        type: 'text',
        prompt: `That's it!`,
        subPrompt: 'Thanks for joining us :)',
      },
    ],
    website: {
      order: 30,
      intro: `
        <p>The SWOT analysis is one of the most fundamental strategic exercises you can run for an organization.</p>
        <p>SWOT stands for Strengths, Weaknesses, Opportunities, and Threats.</p>
      `,
      agendaIntro: `
        <p>First we'll introduce the concept of a SWOT analysis to our attendees.</p>
        <p>Then we'll brainstorm areas for each of the four categories:</p>
      `,
      conclusion: `
        <p>Good luck!</p>
      `,
    },
  },
  {
    id: 'question-and-answer',
    name: `Simple Q&A`,
    description: `Take questions from the audience`,
    materialIcon: 'question_answer',
    slides: [
      {
        type: 'text',
        prompt: 'Welcome',
        subPrompt: `We'll get started soon!`,
      },
      {
        type: 'cards',
        prompt: 'What questions do you have?',
      },
      {
        type: 'text',
        prompt: `That's it!`,
        subPrompt: 'Thanks for joining us :)',
      },
    ],
    website: {
      order: 80,
      intro: `
        <p>There's no simpler way to engage a live audience than taking questions.</p>
        <p>Unfortunately, opening up the floor has a few downsides. Long-winded questions waste time, and awkward or off-topic questions can disrupt your session.</p>
        <p>A bigger problem is that questions from random outspoken individuals rarely reflect the questions of the audience as a whole.</p>
        <p>One way to solve this is having people submit questions on their phones. That way, you can "read the room" before answering and focus on the best questions.</p>
      `,
      agendaIntro: `
        <p>There's really only one prompt you need for a simple Q&A:</p>
      `,
      conclusion: `
        <p>In Shuffleboard, audience members can even vote on the questions they like, making the best of your time in the spotlight.</p>
      `,
    },
  },
  {
    id: 'simple-brainstorm',
    name: `Simple brainstorm`,
    description: `Come up with as many ideas as you can`,
    materialIcon: 'flash_on',
    slides: [
      {
        type: 'text',
        prompt: `Brainstorm`,
        subPrompt: `Brainstorm rules:\n\n1. Quantity over quality - come up with as many ideas as you can\n\n2. "Yes and" - don't reject ideas until the end of the session`,
      },
      {
        type: 'cards',
        prompt: `What ideas do you have?`,
      },
    ],
    website: {
      order: 60,
      intro: `
        <p>Everyone knows what a brainstorm is. And yet, few people actually follow the brainstorm best practices.</p>
        <p>One important practice is to focus on quantity of ideas—<em>not</em> quality, as you might expect. The idea is to stretch the edges of your imagination and push people into unexplored territory.</p>
        <p>Another important piece of a brainstorm is to try not to reject any idea until after the brainstorm is over. There will be plenty of time for rational decisions later. Give your attendees the freedom to think bigger than they normally do, if only for a single session.</p>
      `,
      agendaIntro: `
        <p>For this template, we kept things simple with just one question.</p>
        <p>You can edit any template in Shuffleboard, so feel free to adjust it for the needs of your session.</p>
      `,
      // conclusion: `
      // `,
    },
  },
  {
    id: 'icebreaker',
    name: `Icebreaker`,
    description: `Favorite songs, favorite projects`,
    materialIcon: 'sentiment_satisfied_alt',
    slides: [
      {
        type: 'text',
        prompt: 'Icebreaker time :)',
        subPrompt: `We'll get started soon!`,
      },
      {
        type: 'cards',
        prompt: `What's something you've been interested in lately?`,
      },
      {
        type: 'cards',
        prompt: `What's something you're really proud of working on?`,
      },
      {
        type: 'cards',
        prompt: `What song are you listening to on repeat?`,
      },
      {
        type: 'text',
        prompt: `That's it!`,
        subPrompt: 'Thanks for joining us :)',
      },
    ],
    website: {
      order: 90,
      intro: `
        <p>Nothing induces eye-rolls quite like the prospect of an icebreaker exercise.</p>
        <p>At their worst, they're boring or even cringe-worthy. But when done right, they can quickly create much-needed rapport in new groups.</p>
      `,
      agendaIntro: `
        <p>In this session template, we focus on topics that people actually care about: their work, their proudest moments, and a playful music question at the end:</p>
      `,
      conclusion: `
        <p>Good luck!</p>
      `,
    },
  },
  {
    id: 'start-stop-continue',
    name: `Start/stop/continue`,
    description: `What should we start doing? Stop doing? Continue doing?`,
    materialIcon: 'traffic',
    slides: [
      {
        type: 'text',
        prompt: 'Start/stop/continue',
        subPrompt: `What should we start doing? Stop doing? Continue doing?`,
      },
      {
        type: 'text',
        prompt: 'The Prime Directive',
        subPrompt: `"Regardless of what we discover, we understand and truly believe that everyone did the best job they could, given what they knew at the time, their skills and abilities, the resources available, and the situation at hand."\n\n- Norm Kerth`,
      },
      {
        type: 'cards',
        prompt: `What should we start doing?`,
      },
      {
        type: 'cards',
        prompt: `What should we stop doing?`,
      },
      {
        type: 'cards',
        prompt: `What should we continue doing?`,
      },
      {
        type: 'text',
        prompt: `That's it!`,
        subPrompt: 'Thanks for joining us :)',
      },
    ],
    website: {
      order: 60,
      intro: `
        <p>I was shocked when I attended my first sprint retrospective.</p>
        <p>Our software development team had been struggling to ship good features quickly. Worse, we had been struggling to communicate and coordinate with other teams. It felt like we were wading through mud, and we couldn't make progress we wanted to make—despite having a team of seven people.</p>
        <p>Our team lead Becky led us in our first retro. She started with the same Prime Directive in this template, and guided us through the positives, negatives, and fixes for our next sprint. We had a chance to share problems in a constructive way with each other, and our communication got better every week.</p>
        <p>Ever since I've been a huge believer in the power of retros, especially for highly collaborative teams.</p>
        <p>In my opinion, there's no better way to create a culture of improvement than taking the time with these three questions every two weeks.</p>
    `,
      agendaIntro: `
      <p>This template is a variation on a standard sprint retrospective, but with a more forward-looking stance:</p>
      `,
      conclusion: `
        <p>Good luck!</p>
      `,
    },
  },
  {
    id: 'daily-standup',
    name: `Daily standup`,
    description: `What we did yesterday, plans for today, and blockers`,
    materialIcon: 'accessibility_new',
    slides: [
      {
        type: 'text',
        prompt: 'Standup',
        subPrompt: `What we did yesterday, plans for today, and blockers`,
      },
      {
        type: 'cards',
        prompt: `What did you do yesterday?`,
      },
      {
        type: 'cards',
        prompt: `What will you do today?`,
      },
      {
        type: 'cards',
        prompt: `Is anything blocking you?`,
      },
      {
        type: 'text',
        prompt: `That's it!`,
        subPrompt: 'Thanks for joining us :)',
      },
    ],
    website: {
      order: 50,
      intro: `
        <p>Daily standup meetings are at the heart of most agile and lean teams. They are often held at the beginning of the work day, and often with attendees literally standing up. This keeps the updates short and to the point.</p>
      `,
      agendaIntro: `
        <p>The format for a standup is simple (it has to be—otherwise you would need to sit down). Standups should take only 5-10 minutes, which means everyone needs to be concise, clear, and prepared.</p>
        <p>There are only three questions for each attendee:</p>
      `,
      conclusion: `
        <p>One of the most important aspects of facilitating a standup is to <strong>keep everyone's answers short</strong>. If someone goes off on a tangent, they should save their discussion for after the standup. That way, anyone who doesn't need to listen in can skip the update.</p>
      `,
    },
  },
  {
    id: 'how-might-we',
    name: `How might we...`,
    description: `Inspire ideas without getting lost in the details`,
    materialIcon: 'contact_support',
    slides: [
      {
        type: 'cards',
        prompt: `How might we...`,
      },
    ],
    website: {
      order: 40,
      intro: `
        <p>How Might We sessions ask participants to brainstorm ideas by filling in the phrase "How might we ________"?</p>
        <p>These are one of the most popular design thinking exercises, and for good reason. Brainstorming ideas in this format is easy for almost any participant—it requires no drawing skills and no technical knowledge.</p>
        <p>Participants can give broad answers if they choose ("how might we make our first-time custom experience better?") or get very specific ("how might we encourage new enterprise customers to explore our case studies?")</p>
        <p>The format encourages ideas that are specific, but not so specific that they limit our thinking. Compared to a prototype or an elaborate sketch, How Might We's are fast to write and easy to understand.</p>
      `,
      agendaIntro: `
        <p>A How Might we session usually starts after . For example, after a journey map is complete. It's usually a good idea to give participants at least a few minutes of individual brainstorming answers before coming together as a group and discussing the answers.</p>
        <p>The shortest version of a How Might We includes only one slide with the prompt:</p>
      `,
      conclusion: `
        <p>How might we sessions are perfect to collect votes on because it can help you prioritize which of the ideas you want your team to focus on.</p>
      `,
    },
  },
  {
    id: 'quarterly-planning',
    name: `Quarterly planning`,
    description: `What will we do this year?`,
    materialIcon: 'calendar_today',
    slides: [
      {
        type: 'text',
        prompt: `Quarterly planning`,
        subPrompt: `What will we do this year?`,
      },
      {
        type: 'cards',
        prompt: `Q1 - what will you accomplish in the first quarter?`,
      },
      {
        type: 'cards',
        prompt: `Q2- what will you accomplish in the second quarter?`,
      },
      {
        type: 'cards',
        prompt: `Q3 - what will you accomplish in the third quarter?`,
      },
      {
        type: 'cards',
        prompt: `Q4 - what will you accomplish in the fourth quarter?`,
      },
      {
        type: 'text',
        prompt: `That's it!`,
        subPrompt: 'Thanks for joining us :)',
      },
    ],
    website: {
      order: 20,
      intro: `
        <p>A quarterly plan is the bread-and-butter of many operational teams.</p>
        <p>Making a quarterly plan isn't hard. But making a <strong>good</strong> plan is difficult, and making a plan that everyone agrees on can be nearly impossible.</p>
        <p>This is when a collaborative planning session can make a big difference.</p>
      `,
      agendaIntro: `
        <p>The agenda itself is simple:</p>
        <p>(If you're using Shuffleboard to run your session, you can edit, delete, or add questions to fit your group.)</p>
      `,
      conclusion: `
        <p>The difficult part of great planning is making sure you get as many ideas as possible and foster healthy debate.</p>
        <p>Running the meeting in Shuffleboard let's you easily gather ideas from your attendees, request votes, and share the results of your session.</p>
      `,
    },
  },
  {
    id: 'business-model-canvas',
    name: `Business model canvas`,
    description: `Plan your startup's business model`,
    materialIcon: 'work',
    slides: [
      {
        type: 'text',
        prompt: `Business model canvas`,
        subPrompt: `Plan your startup's business model`,
      },
      {
        type: 'cards',
        prompt: `What are our value propositions?`,
        subPrompt: `What value are you providing to your customers? What distinguishes you from your competitors?`,
      },
      {
        type: 'cards',
        prompt: `What are our key activities?`,
        subPrompt: `What do you need to do to create that value?`,
      },
      {
        type: 'cards',
        prompt: `What are our key resources?`,
        subPrompt: `What will you need to perform your key activities?`,
      },
      {
        type: 'cards',
        prompt: `Who are our key partners?`,
        subPrompt: `What people or organizations should you work with to get those resources and perform those activities?`,
      },
      {
        type: 'cards',
        prompt: `What are our customer segments?`,
        subPrompt: `Who is your customer? What market are you selling into?`,
      },
      {
        type: 'cards',
        prompt: `What are our channels?`,
        subPrompt: `How will you reach these customers? How and where will you market/sell to them?`,
      },
      {
        type: 'cards',
        prompt: `What are our customer relationships?`,
        subPrompt: `How will you acquire customers, retain them, and grow your business with them?`,
      },
      {
        type: 'cards',
        prompt: `What are our cost structures?`,
        subPrompt: `How will you determine your prices?`,
      },
      {
        type: 'cards',
        prompt: `What are our revenue streams?`,
        subPrompt: `How will you charge money (fees, assets, subscriptions, licenses etc)?`,
      },
      {
        type: 'text',
        prompt: `That's it!`,
        subPrompt: 'Thanks for joining us :)',
      },
    ],
    website: {
      order: 30,
      intro: `
        <p>The Business Model canvas is the go-to format for planning new companies.</p>
        <p>Sometimes it's easy for founders to imagine new exciting products, but hard to be clear on everything else: what will the business actually do day-to-day, what specifically will it offer? How will it connect to the outside world, and how will it make money?</p>
        <p>Originally created by in 2005 Alexander Osterwalder (and since reproduced and expanded many times), the Business Model Canvas is helps founders fill in these blind spots.</p>
      `,
      agendaIntro: `
        <p>The Business Model Canvas is made of nine "building blocks":</p>
      `,
      conclusion: `
        <p>If you're starting a new venture, try running your founding team through a business model exercise</p>
      `,
    },
  },
  {
    id: 'eisenhower-matrix',
    name: `Eisenhower matrix`,
    description: `What's urgent vs what's important`,
    materialIcon: 'warning_amber',
    slides: [
      {
        type: 'text',
        prompt: `Eisenhower matrix`,
        subPrompt: `"I have two kinds of problems, the urgent and the important. The urgent are not important, and the important are never urgent." - Dwight D. Eisenhower`,
      },
      {
        type: 'cards',
        prompt: `What's important and urgent?`,
        subPrompt: `Things we should do now`,
      },
      {
        type: 'cards',
        prompt: `What's important but not urgent?`,
        subPrompt: `Things we should schedule`,
      },
      {
        type: 'cards',
        prompt: `What's urgent but not important?`,
        subPrompt: `Things we should delegate`,
      },
      {
        type: 'cards',
        prompt: `What's neither important nor urgent?`,
        subPrompt: `Things we should skip altogether`,
      },
      {
        type: 'text',
        prompt: `That's it!`,
        subPrompt: 'Thanks for joining us :)',
      },
    ],
    website: {
      order: 40,
      intro: `
        <p>This template is named after Dwight Eisenhower, the American President whose quote below popularized the format.</p>
        <p>An Eisenhower matrix is the perfect session to run with teams who are struggling with their priorities.</p>
        <p>The idea is to categorize areas of work into four groups, focusing attention away from the urgent tasks and toward the tasks which aren't immediately urgent, but are very important.</p>
      `,
      agendaIntro: `
        <p>Ask your attendees to brainstorm ideas in four categories:</p>
      `,
      conclusion: `
        <p>Good luck!</p>
      `,
    },
  },
  {
    id: 'okrs',
    name: `OKRs`,
    description: `Objectives and key results`,
    materialIcon: 'outlined_flag',
    slides: [
      {
        type: 'text',
        prompt: `Objectives and key results`,
        subPrompt: `https://www.whatmatters.com/resources/google-okr-playbook/`,
      },
      {
        type: 'cards',
        prompt: `What is our objective?`,
        subPrompt: `What is our goal? What are we trying to accomplish?`,
      },
      {
        type: 'cards',
        prompt: `What should be our key results?`,
        subPrompt: `What specific measures will we use to decide whether we achieved our goal?`,
      },
      {
        type: 'text',
        prompt: `That's it!`,
        subPrompt: 'Thanks for joining us :)',
      },
    ],
    website: {
      order: 20,
      intro: `
        <p>Objectives and Key Results, or OKRs, are a goal-setting and accountability format popularized at Google and through the book Measure What Matters, by John Doerr.</p>
      `,
      agendaIntro: `
        <p>This meeting starts at the teams big hairy goal, and then asks attendees to brainstorm ways of measuring their progress toward that goal:</p>
      `,
      conclusion: `
        <p>Good luck!</p>
      `,
    },
  },
  {
    id: 'journey-map',
    name: `Journey map`,
    description: `Our user's journey as they discover and use our product`,
    materialIcon: 'map',
    slides: [
      {
        type: 'text',
        prompt: `Journey map`,
        subPrompt: `Our user's journey as they discover and use our product`,
      },
      {
        type: 'cards',
        prompt: `What are our users struggling with now?`,
      },
      {
        type: 'cards',
        prompt: `What's it like for them to find our product?`,
      },
      {
        type: 'cards',
        prompt: `What's it like for them to start using our product?`,
      },
      {
        type: 'cards',
        prompt: `What's it like when they are successful?`,
      },
      {
        type: 'text',
        prompt: `That's it!`,
        subPrompt: 'Thanks for joining us :)',
      },
    ],
    website: {
      order: 40,
      intro: `
        <p>The journey map is one of the most the common artifacts of a user experience design process—and yet, one of the most underrated.</p>
        <p>The main idea is to create a complete, "zoomed out" vision of the entire customer experience in one place. Usually this starts at the beginning of the marketing funnel, with problem awareness, and can flow all the way through toward purchase, upsell, and recommendation.</p>
      `,
      // agendaIntro: `
      //   <p></p>
      // `,
      conclusion: `
        <p>Remember: building a single feature is never enough. Great products support their customers from the moment they arrive on their site until they get value, upgrade, recommend, and beyond.</p>
      `,
    },
  },
  {
    id: 'lunch-spot-picker',
    name: `Lunch spot picker`,
    description: `Where should we go for lunch?`,
    materialIcon: 'restaurant',
    slides: [
      {
        type: 'cards',
        prompt: `Where should we eat?`,
      },
    ],
    website: {
      order: 100,
      intro: `
        <p>It's a question that haunts every modern office.</p>
        <p>Where should we go to eat? Who should decide? Will we EVER leave, or will we debate until we give up and decide eat at our desks?</p>
        <p>Well, now there's a good way to solve it: an interactive poll with Shuffleboard.</p>
      `,
      agendaIntro: `
        <p>This agenda is pretty simple. There's only one question:</p>
      `,
      conclusion: `
        <p>Your hungry guests can add any lunch option they want. You can combine duplicate answers and take a vote.</p>
        <p>Maybe now you'll finally leave the office on time and eat...</p>
      `,
    },
  },
]

export default BOARD_TEMPLATES
