import React from 'react'
import styled from 'styled-components/macro'

import TakeoverLayout from 'views/_layouts/TakeoverLayout'
import defaultCards from './defaultCards'
import CardLayout from '../CardLayout'

const CardLayoutDemo = () => {
  return <TakeoverLayout>
    <CardListWrapper>
      <CardLayout
        cardData={defaultCards}
        ParentComponent={CardWrapper}
      />
    </CardListWrapper>
  </TakeoverLayout>
}

const CardListWrapper = styled.div`
  position: relative;
  padding: 1rem;
  background: #eee;
  width: 100%;
  height: 80vh;
  overflow-x: auto;
  overflow-y: hidden; /* shouldn't need to hide it anyway */
`
const CardWrapper = styled.div`
  background: white;
  padding: 1rem;
  position: absolute;
  border: 1px solid black;
`

export default CardLayoutDemo