import TextareaAutosize from 'react-textarea-autosize'
import styled from 'styled-components/macro'

const Label = styled.div`
  margin: 0 0 .5rem 0;
  font-family: var(--font-family-sans);
  color: var(--text-primary);
  font-size: var(--s);
  font-weight: 500;
`
const Input = styled.input`
  display: inline-block;
  color: var(--text-primary);
  border: 1px solid var(--gray-70);
  font-family: var(--font-family-sans);
  font-size: var(--m);
  padding: ${p => p.big ? '1rem' : p.small ? '.5rem' : '.66rem'};
  border-radius: 3px;
  cursor: pointer;
  margin: ${p => p.wide ? '0 0 1rem' : '0 1rem 0 0'};
  width: ${p => p.wide && '100%'};

  &:hover {
    background: var(--gray-98);
  }
  
  outline: none;
  &:focus,
  &:active {
    border-color: var(--brand-primary);
  }
`
const OnboardingInput = styled.input`
  display: block;
  color: var(--text-primary);
  font-family: var(--font-family-sans);
  font-size: var(--m);
  padding: 1rem;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  margin: 0 0 1rem;
  width: 100%;
  box-shadow: 0 2px 4px var(--shadow);
  background: white;
  outline: none;
`
const SelectWrapper = styled.div`
  display: block;
  margin: 0 0 1rem;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 1rem;
    top: calc(50% - 6px);
    width: 4px;
    height: 4px;
    border-style: solid;
    border-width: 2px;
    border-color: transparent transparent var(--gray-70) var(--gray-70);
    border-radius: 2px;
    transform: rotate(-45deg) translate(-50%, -50%);
  }
`
const Select = styled.select`
  appearance: none;
  display: inline-block;
  color: var(--text-primary);
  border: 1px solid var(--gray-70);
  font-family: var(--font-family-sans);
  font-size: var(--m);
  padding: ${p => p.big ? '1rem' : p.small ? '.5rem' : '.66rem'};
  border-radius: 3px;
  cursor: pointer;
  width: ${p => p.wide && '100%'};
  /* width: 100%; */

  &:hover {
    background: var(--gray-98);
  }
  
  outline: none;
  &:focus,
  &:active {
    border-color: var(--brand-primary);
  }
`

const Textarea = styled.textarea`
  display: inline-block;
  color: var(--text-primary);
  border: 1px solid var(--gray-70);
  font-family: var(--font-family-sans);
  font-size: var(--m);
  padding: .66rem;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 0 1rem;

  &:hover {
    background: var(--gray-98);
  }

  outline: none;
  &:focus,
  &:active {
    border-color: var(--brand-primary);
  }
`
const TextareaAuto = styled(TextareaAutosize)`
  width: 100%;

  display: inline-block;
  color: var(--text-primary);
  border: 1px solid var(--gray-70);
  font-family: var(--font-family-sans);
  line-height: 1.4;
  font-size: var(--m);
  padding: .66rem;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 0 1rem;

  &:hover {
    background: var(--gray-98);
  }

  outline: none;
  &:focus,
  &:active {
    border-color: var(--brand-primary);
  }
`
const InputNote = styled.div`
  margin: -.5rem 0 1rem;
  color: var(--text-secondary);
  font-size: var(--s);
`
const InlineForm = styled.form`
  @media(max-width: 599px) {
    Input {
      width: 100%;
      max-width: initial;
      margin: 0 0 .5rem;
    }
  }
`
const BigRadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 1rem;
`
const BigRadioItem = styled.div`
  position: relative;
  margin: 0 0 .5rem;
`
const BigRadioLabel = styled.label`
  display: block;
  padding: 1rem 1.5rem 1rem 3rem;

  color: var(--brand-primary);
  font-weight: 500;
  background: white;
  box-shadow: 0 2px 4px var(--shadow);
  border-radius: 6px;
  cursor: pointer;
  outline: none;

  &:hover {
    box-shadow: 0 2px 4px var(--shadow), 0 0 0 2px var(--blue-90);
  }
`
const BigRadioCircle = styled.div`
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  left: 1.5rem;

  // This centers the dot vertically.
  // I don't know why, leave me alone.
  top: 53%;
  
  width: 6px;
  height: 6px;
  background: var(--gray-96);
  box-shadow: 0 0 0 4px var(--gray-96);
  border-radius: 50%;
  z-index: 1;
`
const BigRadioInput = styled.input`
  display: block;
  width: 0;
  height: 0;

  // Big radio and circle states here
  // Yeah I know it's weird... CSS, man
  // Trying to be accessible and semantic
  &:focus + ${BigRadioLabel},
  &:checked + ${BigRadioLabel} {
    box-shadow: 0 2px 4px var(--shadow), 0 0 0 2px var(--blue-70);
  }
  &:checked + ${BigRadioLabel} ${BigRadioCircle} {
    background: var(--brand-primary);
    box-shadow: 0 0 0 4px var(--blue-90);
  }
`

export {
  Label,
  Input,
  OnboardingInput,
  
  Select,
  SelectWrapper,

  Textarea,
  TextareaAuto,
  
  InputNote,
  
  InlineForm,
  
  BigRadioWrapper,
  BigRadioItem,
  BigRadioLabel,
  BigRadioCircle,
  BigRadioInput,
}