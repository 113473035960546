import React from 'react'
import { FirestoreCollection, FirestoreDocument } from 'react-firestore'

import PromptSlide from 'views/board/slides/PromptSlide'
import { LoadingPage } from 'styles/loading'
import ErrorPage from 'views/_components/ErrorPage'
import LogError from 'actions/LogError'

const PromptSlideContainer = ({
  user,
  board,
  boardRef,
  boardOrgId,
  slides,
  isFacilitator,
  isNotSubscribed,
  participants,
  goToPrevSlide,
  goToNextSlide,
  prevSlideTitle,
  nextSlideTitle,
  currentSlideId,
}) => {
  
  return <FirestoreDocument path={`${boardRef.path}/slides/${currentSlideId}`}>
    {({isLoading, error, data, snapshot }) => {
      if (isLoading) {
        return <LoadingPage>Loading</LoadingPage>
      }
      if (error) {
        LogError('get this slide', error)
        return `Sorry, there was an error getting this slide: ${error}`
      }
      
      const slide = data
      const slideRef = snapshot.ref

      return <FirestoreCollection path={`${slideRef.path}/cards`}>
        {({isLoading, error, snapshot }) => {
          if (isLoading) {
            return <LoadingPage>Loading</LoadingPage>
          }
          if (error) {
            return <ErrorPage error={error} />
          }
          
          const cardSnaps = snapshot.docs

          return <PromptSlide
            user={user}
            board={board}
            boardRef={boardRef}
            boardOrgId={boardOrgId}
            slideRef={slideRef}
            slide={slide}
            slides={slides}
            cardSnaps={cardSnaps}
            isFacilitator={isFacilitator}
            isNotSubscribed={isNotSubscribed}
            participants={participants}
            goToPrevSlide={goToPrevSlide}
            goToNextSlide={goToNextSlide}
            prevSlideTitle={prevSlideTitle}
            nextSlideTitle={nextSlideTitle}
            />
  
        }}
      </FirestoreCollection>

    }}
  </FirestoreDocument>
}

export default PromptSlideContainer
