import React, { useContext } from 'react'
import { FirestoreDocument } from 'react-firestore'

import FirebaseAuth from 'views/_util/FirebaseAuth'
import SubscriptionStatus from 'views/_util/SubscriptionStatus'
import { AppStateContext } from 'views/AppState'
import ErrorPage from 'views/_components/ErrorPage'
import Settings from 'views/settings/Settings'
import { LoadingPage } from 'styles/loading'
import EnsureLoggedInAndInAnOrg from 'views/EnsureLoggedInAndInAnOrg'
import { DebugContext } from 'views/DebugState'

const SettingsContainer = () => {

  const debugging = useContext(DebugContext)

  return <EnsureLoggedInAndInAnOrg>
    <FirebaseAuth>
      { ({isLoading, error, auth}) => {
        if (isLoading) {
          return <LoadingPage>Loading</LoadingPage>
        }
        if (error) {
          return <ErrorPage error={error} />
        }

        return <SubscriptionStatus>
          { ({isLoading, error, subscribed, freeAccount}) => {
            if (isLoading) {
              return <LoadingPage>Loading</LoadingPage>
            }
            if (error) {
              return <ErrorPage error={error} />
            }
            
            return <AppStateContext.Consumer>
              { ({orgId}) => {

                return <FirestoreDocument path={`/organizations/${orgId}/members/${auth.uid}`}>
                  {({isLoading, error, data}) => {
                    if (isLoading) {
                      return <LoadingPage>Loading</LoadingPage>
                    }
                    if (error) {
                      return <ErrorPage error={error} />
                    }

                    const isOwner = data.level === 'owner'

                    return <Settings
                      auth={auth}
                      subscribed={subscribed}
                      freeAccount={freeAccount}
                      orgId={orgId}
                      isOwner={isOwner}
                      debugging={debugging}
                    />

                  }}
                </FirestoreDocument>

              }}
            </AppStateContext.Consumer>

          }}
        </SubscriptionStatus>
      }}
    </FirebaseAuth>
  </EnsureLoggedInAndInAnOrg>
}

export default SettingsContainer
