import React, { useState } from 'react'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import updateUser from 'actions/updateUser'
import OnboardingLayout from 'views/_layouts/OnboardingLayout'
import { Button } from 'styles/buttons'
import { Label, OnboardingInput, InputNote } from 'styles/forms'

const ChooseDisplayName = ({ userId }) => {

  const [displayName, setDisplayName] = useState('')

  const onSubmit = event => {
    event.preventDefault()
    LogEvent('choose-name')
    updateUser(userId, { displayName })
      .catch( error => LogError('update your display name', error) )
    }

  return <OnboardingLayout>
    <p className="reply">Welcome aboard</p>
    <h2>What should we call you?</h2>

    <form onSubmit={onSubmit} data-test-id="onboarding-user">
      <Label>Full name</Label>
      <OnboardingInput
        type="text"
        name="displayName"
        value={displayName}
        onChange={event => setDisplayName(event.target.value)}
        placeholder="Jane Doe"
        maxLength={1000}
        required
        autoFocus
      />
      <InputNote>This will appear on your boards and reports. You can always change it later.</InputNote>

      <div style={{textAlign: 'right', margin: '2rem 0 0'}}>
        <Button big="big" type="submit">
          Next
        </Button>
      </div>

    </form>
  </OnboardingLayout>
}

export default ChooseDisplayName
