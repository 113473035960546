import React from 'react'
import styled from 'styled-components/macro'

import LogError from 'actions/LogError'
import updateBoard from 'actions/updateBoard'
import { Button } from 'styles/buttons'

const VoteBar = ({
  board,
  boardRef,
  isFacilitator,
  slideType,
}) => {

  return <VotePrompt visible={slideType === 'cards' && board.isInVoteMode}>
    <div>
      <VotePromptTitle>Voting is on</VotePromptTitle>
      <VotePromptSub>Tap your favorite ideas</VotePromptSub>
    </div>
    { isFacilitator &&
      <Button ghost fullRound onClick={ () => {
        updateBoard(boardRef, { isInVoteMode: false })
          .catch( error => LogError('end vote mode', error) )
      }}>
        End voting
      </Button>
    }
  </VotePrompt>
}

const VotePrompt = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  transition: all .4s ease;
  bottom: ${p => p.visible ? '3rem' : '-8rem'};
  opacity: ${p => p.visible ? 1 : 0};
  z-index: 10000;

  min-width: 20rem;
  background: var(--brand-primary);
  border-radius: 1000px;
  padding: .5rem .5rem .5rem 1.5rem;
  margin: -1rem 0;
  display: grid;
  grid-template-columns: auto max-content;
  grid-column-gap: 1rem;
`
const VotePromptTitle = styled.div`
  font-weight: 500;
  color: white;
  font-size: var(--m);
`
const VotePromptSub = styled.div`
  font-size: var(--xs);
  color: white;
  font-weight: 500;
  opacity: .5;
`

export default VoteBar
