import React, { useState } from 'react'
import Tippy from '@tippyjs/react'
import styled from 'styled-components/macro'

import LogError from 'actions/LogError'
import updateUser from 'actions/updateUser'
import BOARD_TEMPLATES from 'data/BoardTemplates'
import Chatbox from 'views/_components/Chatbox'
import InteractiveSlideBadge from 'views/_components/InteractiveSlideBadge'
import TemplatePreview from 'views/board/new_board/TemplatePreview'
import {
  AppLayoutHeader,
} from 'styles/layout'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-away-subtle.css'

const NewBoard = ({
  org,
  orgRef,
  user,
  boards,
  popup,
  Parent,
}) => {

  const demoTemplate = BOARD_TEMPLATES.find(bt => bt.id === 'demo')
  const normalBoardTemplates = BOARD_TEMPLATES.filter(bt => bt.id !== 'demo')

  const [ popupIsOpen, setPopupIsOpen ] = useState(false)
  const [ isPreviewingTemplate, setIsPreviewingTemplate ] = useState(false)
  const [ templateToPreview, setTemplateToPreview ] = useState(undefined)

  return <Parent prevScreen="/boards" title="Templates">

    <NewBoardListWrapper>

      {popup ?
        <>
          <h1 data-test-id="template-picker">Choose a template</h1>
          <p>What kind of session are you running? You can always edit these slides later.</p>

          <br />
          <br />
        </>
        :
        <AppLayoutHeader>
          <h1>
            Templates
          </h1>
        </AppLayoutHeader>
      }

      {!org.hasAddedDemoBoard && !user.hasDismissedDemoPrompt &&
        <>
          <h3>Recommended</h3>
          <br />
          <BoardTemplateWrapper
            key={demoTemplate.name}
            onClick={ event => {
              event.stopPropagation()
              setIsPreviewingTemplate(true)
              setTemplateToPreview(demoTemplate)
            }}
          >
            <BoardTemplateImage orange>
              <i className="material-icons-round">{demoTemplate.materialIcon}</i>
            </BoardTemplateImage>
            <div data-test-id="template-name">
              <BoardTemplateTitle>{demoTemplate.name}</BoardTemplateTitle>
              <BoardTemplateSubtitle>{demoTemplate.description}</BoardTemplateSubtitle>
              { boards.length > 0 &&
                <DismissLink
                  onClick={ event => {
                    event.stopPropagation()
                    updateUser(user.id, { hasDismissedDemoPrompt: true })
                      .catch( error => LogError('dismiss demo board prompt', error) )
                  }}
                >
                  Hide forever
                </DismissLink>
              }
            </div>
          </BoardTemplateWrapper>

          <br />
          <br />
          <br />

          <h3>More templates</h3>
          <br />
        </>
      }

      <BoardTemplatesWrapper>
        {normalBoardTemplates
        .filter( template => template.id !== 'empty' || popup)
        .map( template => (
          <BoardTemplateWrapper
            key={template.name}
            onClick={ event => {
              event.stopPropagation()
              if (popupIsOpen) {
                return
              }
              setIsPreviewingTemplate(true)
              setTemplateToPreview(template)
            }}
          >
            <BoardTemplateImage>
              <i className="material-icons-round">{template.materialIcon}</i>
            </BoardTemplateImage>
            <div data-test-id="template-name">
              { template.new && <BoardTemplateBadge>New</BoardTemplateBadge> }
              <BoardTemplateTitle>{template.name}</BoardTemplateTitle>
              <BoardTemplateSubtitle>{template.description}</BoardTemplateSubtitle>
              { template.id !== 'empty' && template.id !== 'demo' &&
                <div onClick={event => event.stopPropagation()}>
                  {/* https://atomiks.github.io/tippyjs/v6/all-props/#onshown */}
                  {/* https://popper.js.org/docs/v2/modifiers/flip/ */}
                  <Tippy
                    maxWidth={500}
                    theme="light"
                    trigger="click"
                    placement="right"
                    animation="shift-away-subtle"
                    onShown={ () => setPopupIsOpen(true) }
                    onHidden={ () => setPopupIsOpen(false) }
                    popperOptions ={{
                      placement: 'right',
                      modifiers: [
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['left'],
                          },
                        },
                      ],
                    }}
                    content={
                      <TooltipWrapper>
                        <BoardTemplateSlides>
                          {template.slides.map( slide => (
                            <BoardTemplateSlide key={slide.prompt}>
                              <BoardTemplateSlidePrompt>
                                {slide.prompt}
                                {slide.type === 'cards' && <>{' '}<InteractiveSlideBadge /></>}
                              </BoardTemplateSlidePrompt>
                              {/* <BoardTemplateSlideSubprompt>{slide.subPrompt}</BoardTemplateSlideSubprompt> */}
                            </BoardTemplateSlide>
                          ))}
                        </BoardTemplateSlides>
                      </TooltipWrapper>
                    }
                  >
                    <BoardTemplateSlidePreviewTarget>Preview agenda</BoardTemplateSlidePreviewTarget>
                  </Tippy>
                </div>
              }
            </div>
          </BoardTemplateWrapper>
        ))}
      </BoardTemplatesWrapper>

    </NewBoardListWrapper>
    
    { isPreviewingTemplate &&
      <TemplatePreview
        template={templateToPreview}
        orgRef={orgRef}
        user={user}
        closeModal={ () => {
          setIsPreviewingTemplate(false)
          setTemplateToPreview(undefined)
        }}
      />
    }
    
    <Chatbox />

  </Parent>
}

// regular templates
const NewBoardListWrapper = styled.div`
  max-width: 54rem;
  margin: 2rem auto;
`
const BoardTemplatesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  padding: 0 0 8rem;

  @media(max-width: 599px) {
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
  }
`
const BoardTemplateWrapper = styled.div`
  cursor: pointer;
  user-select: none;

  color: var(--brand-primary);
  opacity: .9;

  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 1.5rem;
  align-items: center;

  &:hover {
    opacity: 1;
  }
`
const DismissLink = styled.span`
  color: var(--text-tertiary);
  font-size: var(--xs);
  font-weight: 400;

  &:hover {
    text-decoration: underline;
  }
`
const BoardTemplateImage = styled.div`
  height: 7rem;
  width: 7rem;
  border-radius: 4px;
  padding: 1.5rem;
  background: ${p => p.orange ? 'var(--orange-96)' : 'var(--blue-96)'};
  color:      ${p => p.orange ? 'var(--orange-50)' : 'var(--blue-60)'};
  display: grid;
  align-items: center;
  justify-content: center;

  i {
    font-size: 2.4rem;
  }
`
const BoardTemplateTitle = styled.div`
  color: var(--text-primary);
  font-weight: 600;
  font-size: var(--l);
  margin: 0 0 .25rem;
`
const BoardTemplateSubtitle = styled.div`
  color: var(--text-secondary);
  font-size: var(--s);
  margin: 0 0 .25rem;
`
const BoardTemplateSlidePreviewTarget = styled.div`
  display: inline-block;
  color: var(--text-primary);
  font-size: var(--s);
  margin: 0 0 .25rem;
  border-bottom: 1px dotted;
`
const BoardTemplateBadge = styled.div`
  display: inline-block;
  background: ${p => p.yellow ? 'var(--yellow-60)'    : 'var(--green-96)'};
  color:      ${p => p.yellow ? 'var(--text-primary)' : 'var(--green-60)'};
  padding: 1px 8px 0px;
  font-size: var(--xs);
  border-radius: 100px;
  margin: 0 0 .5rem;
  font-weight: 500;
`

// Tooltip
const TooltipWrapper = styled.div`
  padding: .5rem;
`
const BoardTemplateSlides = styled.div`
  border-radius: 4px;
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: .75rem;
`
const BoardTemplateSlide = styled.div`
`
const BoardTemplateSlidePrompt = styled.div`
  color: var(--text-primary);
  font-weight: 600;
  font-size: var(--s);
`
// const BoardTemplateSlideSubprompt = styled.div`
//   color: var(--text-secondary);
//   font-size: var(--xs);

//   // don't show really long shit
//   // https://css-tricks.com/line-clampin/
//   display: -webkit-box;
//   -webkit-line-clamp: 2;
//   -webkit-box-orient: vertical;
//   overflow: hidden;
// `

export default NewBoard
