import React from 'react'
// import React, { useState } from 'react'
import { useDroppable } from '@dnd-kit/core'

// const now = () => (new Date()).getTime()

const MoveItemDropArea = ({
  id,
  index,
  moveItemToPosition,
  dragItemType,
}) => {

  const {
    isOver,
    setNodeRef,
  } = useDroppable({
    id,
  })

  //     if (droppedItem.index === index) {
  //       return
  //     }

  //     // if a card is dropped on another item, move the second to the first's index position
  //     moveItemToPosition(droppedItem.index, index)
  //     combineItems(droppedItem.id, id)

  // const [lastUpdate, setLastUpdate] = useState()

            
      // PREVENT OCCASIONAL INFINITE LOOP
      // console looks like this:

      // 2 is hovering on 5
      // 5 is hovering on 2
      // 2 is hovering on 5
      // ...
    
      // Maybe here's what's happening:
      // 1. When a card hovers over another...
      // 2. the cards reorder in local state...
      // 3. so the index for our current card changes and the cards switch places...
      // 4. which triggers a new version of this hover function to run... 
      // 5. which calls the function to reorder cards in local state...
      // etc etc infinite loop
      // interrupt between 4 and 5 ????????
      // let's try it...
      
      // // if the order was updated less than 200ms ago, don't bother
      // if ( lastUpdate > now() + 200) {
      //   return
      // }

      // if (!ref.current) {
      //   return
      // }

      // if (hoveringItem.index === index) {
      //   return
      // }
      
      // moveItemToPosition(hoveringItem.index, index)

      // // REMOVING THIS LINE CAUSED AN INFINITE LOOP AND A DAY'S WORTH OF DEBUGGING
      // // Note: we're mutating the monitor item here!
      // // Generally it's better to avoid mutations,
      // // but it's good here for the sake of performance
      // // to avoid expensive index searches.
      // hoveringItem.index = index

      // setLastUpdate(now())


  // TINY MOVE ITEM AREA
  // If you move a small card over a big card,
  // the big card will shift out of the way.
  // But because the moving card is small, it won't shift much.
  // Since the target card is big, the drop area is big,
  // and when it moves, your cursor can still be on the drop area.
  // This triggers another move, then an infinite loop.
  // Hacky fix: make the move item area small,
  // so pretty much any shift will get it out from under the cursor.
  
  const style = {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '50%',
    width: '100%',
    height: '3px',
    transform: 'translateY(-50%)',
    touchAction: 'none',
    background: isOver ? 'red': 'yellow',
    zIndex: 1100,
  }

  return <div
    ref={setNodeRef}
    style={style}
  />
}

export default MoveItemDropArea
