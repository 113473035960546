import React from 'react'
import { FirestoreDocument } from 'react-firestore'
import FirebaseAuth from 'views/_util/FirebaseAuth'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import updateUser from 'actions/updateUser'
import {
  DropdownMenuAction,
} from 'styles/dropdown'

const ShowTour = ({close}) => {

  return <FirebaseAuth>
    { ({isLoading, error, auth}) => {
      if (isLoading || error || !auth) {
        return null
      }

      return <FirestoreDocument path={`users/${auth.uid}/`}>
        {({ isLoading, error, data }) => {
          if (isLoading || error || !data) {
            return null
          }
          const user = data
        
          return <DropdownMenuAction onClick={() => {
            close && close()
            LogEvent('restart-tour')
            updateUser(user.id, {
              hasClosedFirstBoardModal: false,
              hasClosedBoardTourNavStep: false,
              hasClosedBoardTourSettingsStep: false,
              hasClosedBoardTourInputStep: false,
              hasSubmittedDemoBoardIdea: false,
              hasDismissedDemoPrompt: false,
            })
              .catch( error => LogError('end the tour', error) )
          }}>
            <i className="material-icons-round">tour</i>
            Restart tour
          </DropdownMenuAction>

        }}
      </FirestoreDocument>

    }}
  </FirebaseAuth>
}

export default ShowTour