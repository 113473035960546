/*
  ENSURES THAT
   - user is logged in
   - if there's no org id, a user has a chance to switch to one or create an org 

  PSEUDO CODE
  
  If not logged in
    Require login
  If logged in
    If no appstate org
      If there's a most recent org
        Auto-set your org based on most recent org
      If there is NO most recent org
        If there are previous known orgs
          Auto-join the first known org
        If there are NO previous known orgs or recent orgs
          Say "hey make an org"
    If there IS an appstate org, try and load it
      If you're a member of that org
        If you know you're a member
          Show the page like normal w/ that org's info
        But you DON'T know you're a member
          Let them "join" that org (TODO) - HOW WOULD APP STATE GET SET THEN? still worth doing as edge case IMO
      If you're NOT a member (ie they got removed)
        Show "don't have access" page
        Let them clear their appstate org and update known member list (so they can make their own org or switch to others)

  SHOULD WORK FOR
  TODO - UI tests for this so it's actually testable

  - User who just signed up and has no orgs
  - User who's returning to an org page they already have access to
  - User who's returning to an org page they JUST GOT have access to
  - User who's returning to an org page they NO LONGER have access to
  - User who is actively using the app
*/

import React from 'react'
import { Redirect } from 'react-router-dom'

import FirebaseAuth from 'views/_util/FirebaseAuth'
import ChooseDisplayName from 'views/account/ChooseDisplayName'
import ErrorPage from 'views/_components/ErrorPage'
import { LoadingPage } from 'styles/loading'
import { AppStateContext } from 'views/AppState'
import CreateOrg from 'views/account/CreateOrg'
import AutoJoinOrg from 'views/_util/AutoJoinOrg'
import EnsureHasUserLoaded from './EnsureHasUserLoaded'
import EnsureHasOrgLoaded from './EnsureHasOrgLoaded'

const EnsureLoggedInAndInAnOrg = ({ children }) => {

  return <FirebaseAuth>
    {({isLoading, error, auth}) => {
      if (isLoading) {
        return <LoadingPage>Loading</LoadingPage>
      }
      if (error) {
        return <ErrorPage error={error} />
      }
      if (!auth) {
        return <Redirect
          to={{
            pathname: "/login",
            search: `?redirectTo=${encodeURIComponent(window.location.href)}`,
            state: { message: "You have to log in to see this page"}
          }}
        />
      }
      if (auth.isAnonymous) {
        return <Redirect
          to={{
            pathname: "/require_account",
            search: `?redirectTo=${encodeURIComponent(window.location.href)}`,
            state: { message: "You'll have to create an account (or log in) to see this page."}
          }}
        />
      }

      return <EnsureHasUserLoaded userId={auth.uid}>
        { ({user}) => {
          
          if (!user || !user.displayName) {
            // ONBOARDING STEP 1 (email auth only)
            return <ChooseDisplayName userId={auth.uid} />
          }

          return <AppStateContext.Consumer>
            {({orgId, switchOrg}) => {
                  
              if (!orgId) {
                if (!user.knownMemberOrgIds || user.knownMemberOrgIds.length < 1) {
                  // ONBOARDING STEP 2
                  return <CreateOrg
                    auth={auth}
                    displayName={user.displayName}
                    switchOrg={switchOrg}
                  />
                }
                return <AutoJoinOrg
                  user={user}
                  switchOrg={switchOrg}
                />
              }

              // TODO - pass org info to children via render props or context?
              return <EnsureHasOrgLoaded
                orgId={orgId}
                switchOrg={switchOrg}
                user={user}
                userId={auth.uid}
              >
                {children}
              </EnsureHasOrgLoaded>

            }}
          </AppStateContext.Consumer>

        }}
      </EnsureHasUserLoaded>

    }}
  </FirebaseAuth>
}

export default EnsureLoggedInAndInAnOrg