import React, { useState } from 'react'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import updateOrg from 'actions/updateOrg'
import INTERNAL_EXTERNAL_OPTIONS from 'data/InternalExternalOptions'
import OnboardingLayout from 'views/_layouts/OnboardingLayout'
import { Button } from 'styles/buttons'
import {
  BigRadioWrapper,
  BigRadioItem,
  BigRadioLabel,
  BigRadioCircle,
  BigRadioInput,
  InputNote,
} from 'styles/forms'

const ChooseInternalExternal = ({
  orgRef,
  org,
}) => {

  const [ value, setValue ] = useState({})

  const handleSubmit = event => {
    event.preventDefault()
    LogEvent('choose-internal-external')
    updateOrg(orgRef, {
      onboardingInternalExternal: value.id,
    })
      .catch( error => LogError('save your attendee type choice', error) )
  }

  return <OnboardingLayout>
    <p className="reply">Last question!</p>
    <h2>Who will you invite to your sessions?</h2>

    <form onSubmit={handleSubmit} data-test-id="onboarding-internal-external">
      <BigRadioWrapper>
        {INTERNAL_EXTERNAL_OPTIONS.map(option => {
          return <BigRadioItem key={option.id}>
            <BigRadioInput
              id={`attendeeType-${option.id}`}
              type="radio"
              name="attendeeType"
              value={option.id}
              onChange={ () => setValue(option)}
              required
            />
            <BigRadioLabel htmlFor={`attendeeType-${option.id}`}>
              <BigRadioCircle />
              {option.label(org)}
            </BigRadioLabel>
          </BigRadioItem>
        })}
      </BigRadioWrapper>
      <InputNote>You'll see template recommendations based on your answer</InputNote>

      <div style={{textAlign: 'right', margin: '2rem 0 0'}}>
        <Button big="big" type="submit" disabled={!value.id}>
          All done!
        </Button>
      </div>

    </form>
  </OnboardingLayout>
}

export default ChooseInternalExternal
