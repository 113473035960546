import React from 'react'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import logInAnonymously from 'actions/logInAnonymously'
import logInWithGoogle from 'actions/logInWithGoogle'
import OrBar from 'views/_components/OrBar'
import AccountLayout from 'views/_layouts/AccountLayout'
import { Button, ButtonSubText, AppLink } from 'styles/buttons'

const Join = () => {

  return <AccountLayout title="Join meeting">
    
    <h2 data-test-id="board-welcome">Welcome!</h2>

    {/* TODO - show board name here */}
    <p>You've been invited to join a discussion in Shuffleboard.</p>
    <br />

    <Button
      wide
      block
      destructive="destructive"
      onClick={ () => {
        logInWithGoogle()
          .then( () => {
            LogEvent('log-in-google')
          })
          .catch( error => {
            if (error.code === 'auth/popup-closed-by-user') {
              console.log('user closed the auth popup')
              return null
            }
            LogError('sign in', error)
          })
      }}
    >
      Log in with Google
    </Button>

    <ButtonSubText>
      Your name and picture will automatically appear, no typing required
    </ButtonSubText>

    <OrBar />

    <Button
      wide
      block
      tertiary="tertiary"
      data-test-id="join-without-logging-in"
      onClick={() => {
        logInAnonymously()
          .then( () => {
            LogEvent('log-in-anon')      
          })
          .catch( error => LogError('sign in', error) )
      }}
    >
      Join without logging in
    </Button>

    <br />

    <ButtonSubText>
      By signing up you agree to our
      {' '}
      <AppLink to="/terms">terms of service</AppLink>
    </ButtonSubText>

  </AccountLayout>
}

export default Join
