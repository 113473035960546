import { prepareDocForCreate } from './helpers/firestoreHelpers'

const createBoard = async ({orgRef, values, isDemoBoard}) => {
  
  values.isDemoBoard = !!isDemoBoard

  return orgRef
    .collection('boards')
    .add(prepareDocForCreate(values))
}

export default createBoard
