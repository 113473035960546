import React from 'react'
import styled from 'styled-components/macro'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import updateBoard from 'actions/updateBoard'
import deleteSlide from 'actions/deleteSlide'
import Dropdown from 'views/_components/Dropdown'
import {
  DropdownMenuAction,
  DropdownUpperRightWrapper,
  DropdownToggleWrapper,
} from 'styles/dropdown'

const SlideListItem = ({
  boardRef,
  isFacilitator,
  goToPrevSlide,
  slide,
  close,
}) => {

  if (!isFacilitator) {
    return null
  }
  return <SlideListItemWrapper key={slide.id} onClick={() => {
    close && close()
    updateBoard(boardRef, {
      currentSlide: slide.id,
    })
      .catch( error => LogError('change the slide', error) )
  }}>
    <SlideListItemTitle>
      {slide.prompt || 'Unnamed slide'}
    </SlideListItemTitle>

    <DropdownUpperRightWrapper>
      <Dropdown
        toggle={
          <DropdownToggleWrapper>
            <i className="material-icons-round">more_horiz</i>
          </DropdownToggleWrapper>
        }
      >
        { () => (
          <div>
            <DropdownMenuAction destructive="destructive" onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              if (window.confirm(`Are you sure you want to delete this slide and all its cards? You can't undo this.`)) {
                LogEvent('delete-slide')
                if (goToPrevSlide) {
                  goToPrevSlide()
                }
                deleteSlide(slide.ref)
                  .catch( error => LogError('delete this slide', error) )
              }
            }}>
              <i className="material-icons-round">delete_outline</i>
              Delete slide
            </DropdownMenuAction>
          </div>
        )}
      </Dropdown>
    </DropdownUpperRightWrapper>
  </SlideListItemWrapper>
}

const SlideListItemWrapper = styled.div`
  display: grid;
  align-items: center;
  position: relative;
  grid-template-columns: auto max-content;
  
  cursor: move;
  padding: 0.5rem;
  border-radius: 3px;
  margin: 0 -.5rem;
  border: 1px solid transparent;
  background: white;

  &:hover {
    border: 1px solid var(--hairline);
    box-shadow: 0 2px 4px var(--shadow);
  }
`
const SlideListItemTitle = styled.div`
  font-weight: 500;
  font-size: var(--s);
  color: var(--text-primary);

  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 2rem);
  text-overflow: ellipsis;
`

export default SlideListItem
