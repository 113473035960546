import React from 'react'
import styled from 'styled-components/macro'

import AppLayout from 'views/_layouts/AppLayout'
import ChangeName from 'views/settings/ChangeName'
import ChangePassword from 'views/settings/ChangePassword'
import ChangeOrgName from 'views/settings/ChangeOrgName'
import ManageOrgMembers from 'views/settings/ManageOrgMembers'
import AddOrg from 'views/settings/AddOrg'
import InviteMember from 'views/settings/InviteMember'
import SettingsSubscription from 'views/settings/SettingsSubscription'
// import ManageNotifications from 'views/settings/ManageNotifications'
import {
  AppLayoutHeader,
} from 'styles/layout'
import Chatbox from 'views/_components/Chatbox'

const Settings = ({
  subscribed,
  freeAccount,
  orgId,
  auth,
  isOwner,
  debugging,
}) => {

  return <AppLayout title="Settings">

    <AppLayoutHeader data-test-id="settings">
      <h1>
        Settings
      </h1>
    </AppLayoutHeader>

    <SettingsWrapper>
      <SettingsHeader>Profile</SettingsHeader>
      <ChangeName auth={auth} />
      <hr />
      <ChangePassword />
    </SettingsWrapper>
    
    <SettingsWrapper>
      <SettingsHeader>Organization</SettingsHeader>
      <ChangeOrgName
        orgId={orgId}
        isOwner={isOwner}
      />
      <hr />
      <AddOrg auth={auth} />
    </SettingsWrapper>

    <SettingsWrapper>
      <SettingsHeader>Members</SettingsHeader>
      <ManageOrgMembers
        orgId={orgId}
        auth={auth}
        debugging={debugging}
        />
      <InviteMember
        orgId={orgId}
        debugging={debugging}
      />
    </SettingsWrapper>

    <SettingsWrapper>
      <SettingsHeader>Subscription</SettingsHeader>
      <SettingsSubscription
        subscribed={subscribed}
        freeAccount={freeAccount}
        orgId={orgId}
        isOwner={isOwner}
        debugging={debugging}
      />
    </SettingsWrapper>

    {/* <SettingsWrapper>
      <SettingsHeader>Email notifications</SettingsHeader>
      <ManageNotifications auth={auth} />
    </SettingsWrapper> */}

    <SettingsFooter>
      <a href="https://getshuffleboard.com/" target="_blank" rel="noreferrer">Home</a>
      {' · '}
      © {new Date().getFullYear()}
      {' '}
      <a href="https://directedworks.com/" target="_blank" rel="noopener noreferrer">Directed Works LLC</a>
    </SettingsFooter>

    <SettingsBasement>
      org {orgId}
      {' · '}
      auth {auth.uid}
    </SettingsBasement>

    <Chatbox />

  </AppLayout>
}

const SettingsWrapper = styled.div`
  margin: 0 0 4rem;
`
const SettingsHeader = styled.h2`
  margin: 0 0 1rem;
  padding: 0 0 .5rem;
  border-bottom: 2px solid var(--hairline);
`
const SettingsFooter = styled.p`
  padding: 4rem 0 1rem;
  color: var(--text-secondary);
  font-size: var(--xs);
  
  a {
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`
const SettingsBasement = styled.p`
  color: var(--text-tertiary);
  font-size: var(--xs);
  
  a {
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`

export default Settings