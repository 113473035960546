import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

const ButtonIconWrapper = styled.div`
  display: grid;
  grid-column-gap: ${p => p.padded ? '1rem' : '.5rem'};
  grid-template-columns: max-content max-content;
  align-items: center;
  margin-left:  ${ p => p.right ? '0'      : '-.5rem'};
  margin-right: ${ p => p.right ? '-.5rem' : '0'     };

  i {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const Button = styled.button`
  vertical-align: top;
  border: none;
  font-family: var(--font-family-sans);
  font-weight: 500;
  letter-spacing: 0.015rem;
  border-radius: ${ p => p.fullRound ? '1000px' : '4px'};
  cursor: ${p => p.working ? 'auto' : p.disabled ? 'auto' : 'pointer'};
  opacity: ${p => p.working ? .5 : .95};
  max-width: 25rem;
  text-align: center;
  user-select: none;
  margin-left: ${p => p.padLeft && '1rem'};  /* needs to be above other margin def */
  margin-right: ${p => p.padRight && '1rem'};  /* needs to be above other margin def */
  font-size: ${p => p.small ? 'var(--s)' : 'var(--m)'};
  position: relative;
  
  /* block */
  display: ${p => p.block ? 'block' : 'inline-block'};
  margin: ${p => p.block ? '0 auto' : p.vertSpace ? '0 auto .5rem' : 'auto' };

  width: ${p => p.wide && '100%'};
  
  /* color */
  background: ${p => p.tertiary ? 'white' : 
                     p.green ? 'var(--brand-secondary)' : 
                     p.secondary ? 'var(--background-item)' : 
                     p.dark ? 'var(--blue-40)' : 
                     p.yellow ? 'var(--yellow-60)' : 
                     p.ghost ? 'hsl(0, 0%, 100%, .2)' : 
                     p.destructive ? 'var(--destructive)' : 
                     'var(--brand-primary)'};
  color: ${p =>      p.tertiary && p.destructive ? 'var(--destructive)' : 
                     p.secondary ? 'var(--brand-primary)' : 
                     p.tertiary ? 'var(--brand-primary)' : 
                     p.yellow ? 'var(--orange-20)' : 
                     'white'};
  box-shadow: ${p => p.tertiary ? '0 4px 10px var(--shadow)' : 
                     p.secondary ? 'none' : 
                     p.ghost ? 'none' : 
                     p.dark ? 'none' : 
                     p.yellow ? 'none' : 
                     p.destructive ? '0 20px 30px -25px var(--destructive)' : 
                     '0 20px 30px -25px var(--brand-primary)'};

  /* size */
  padding: ${p => p.big ? '1rem 2rem' : p.small ? '.5rem 1rem' : '.66rem 1.33rem'};
  padding-right: ${p => (p.big && p.working) ? '3.5rem' :
                        (p.small && p.working) ? '2.75rem' :
                        p.working ? '3rem' :
                        undefined };


  transition: opacity .1s ease;
  &:hover {
    opacity: ${p => p.working ? .5 : 1};
  }

  outline: none;
  &:focus {
    box-shadow: inset 0 0 0 2px var(--blue-80);
  }

  &:disabled {
    opacity: .3;
    cursor: auto;
  }

  &:before {
    display: ${p => p.working ? 'block' : 'none'};
    content: '';
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 14px;
    height: 14px;
    margin-top: -8px;
    margin-left: -8px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: var(--white);
    animation: spinner .6s linear infinite;
  }

  ${ButtonIconWrapper} {
    grid-template-columns: ${p => p.wide && 'max-content auto'};
  }
`
const ButtonSubText = styled.div`
  font-size: var(--xs);
  margin: .5rem 0;
`

const AppLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: ${p => p.destructive ? 'var(--destructive)' : 'var(--brand-primary)'};

  outline: none;
  &:focus {
    border-bottom: 2px solid var(--blue-80);
  }

  &:hover {
    text-decoration: underline;
  }
`

const NavTooltip = styled.div`
  position: fixed; /* TODO - smarter positioning library? */
  bottom: ${p => p.top ? 'auto' : '5.5rem'};
  top: ${p => p.top ? '4.5rem' : 'auto'};
  right: ${p => p.left ? 'auto' : '1.5rem'};
  left: ${p => p.left ? '3rem' : 'auto'};
  z-index: 10000;
  pointer-events: none;
  max-width: 70vw;

  border-radius: 4px;
  padding: .5rem 1rem;
  background: white;
  color: var(--text-secondary);
  box-shadow: 0px 10px 20px var(--shadow-overlay);
`
const NavTooltipEyebrow = styled.div`
  color: var(--text-tertiary);
  font-size: var(--xs);
`

const HoverTarget = styled.div`
  display: inline-block;

  ${NavTooltip} {
    opacity: 0;
    transition: opacity .2s ease;
  }
  
  &:hover ${NavTooltip} {
    opacity: 1;
  }
`
const ToolbarButton = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  padding: ${p => p.participant ? '.5rem 1rem' : '1rem 1.33rem'};

  cursor: ${p => p.participant ? 'auto' : 'pointer'};
  user-select: none;

  background: ${p => p.done ? 'var(--red-50)' : 'white'};
  color:      ${p => p.done ? 'white' : p.almostDone ? 'var(--red-60)' : 'var(--blue-55)'};
  border-radius: 4px;
  border: none;

  transition: all .2s ease;

  box-shadow: ${p => p.participant ? 'none' : '0 3px 6px var(--shadow)'};
  opacity: ${p => p.participant ? 1 : .9};

  outline: none;
  &:focus {
    box-shadow: inset 0 0 0 2px var(--blue-80);
  }

  &:hover {
    box-shadow: ${p => p.participant ? 'none' : '0 4px 8px var(--shadow)'};
    opacity: 1;
  }
  &:disabled {
    opacity: .3;
    cursor: auto;
  }

  // hide timer on mobile for participants
  @media(max-width: 599px) {
    display: ${p => p.participant ? 'none' : 'flex'};
  }
`
const ToolbarButtonLabel = styled.span`
  padding: 0 0 0 .5rem;
  font-family: var(--font-family-sans);
  font-weight: 500;
  font-size: var(--m);
  letter-spacing: 0.015rem;
  display: none; /* for now */
  
  // hide on mobile
  @media(max-width: 599px) {
    display: none;
  }
`

export {
  Button,
  ButtonIconWrapper,
  ButtonSubText,
  AppLink,
  HoverTarget,
  NavTooltip,
  NavTooltipEyebrow,
  ToolbarButton,
  ToolbarButtonLabel,
}
