import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import TextareaAutosize from 'react-textarea-autosize'
import Linkify from 'linkifyjs/react'
import { useDebouncedCallback } from 'use-debounce'

import LogEvent from 'actions/LogEvent'
import LogError from 'actions/LogError'
import updateSlide from 'actions/updateSlide'
import CardList from 'views/board/cards/CardList'
import CardDetailContainer from 'views/board/card_detail/CardDetailContainer'

import { AppLink } from 'styles/buttons'

const PromptSlide = ({
  user,
  board,
  slide,
  slides,
  slideRef,
  cardSnaps,
  isFacilitator,
  isNotSubscribed,
}) => {

  const history = useHistory()

  const [ promptState,    setPromptState    ] = useState(slide.prompt)
  const [ subPromptState, setSubPromptState ] = useState(slide.subPrompt)

  const updatePromptInDatabaseDebounced = useDebouncedCallback( () => {
    console.log('update prompt in db')
    LogEvent('change-slide-prompt')
    updateSlide(slideRef, { prompt: promptState })
      .catch( error => LogError('update this slide prompt', error) )
  }, 1000)

  const updateSubPromptInDatabaseDebounced = useDebouncedCallback( () => {
    console.log('update subprompt in db')
    LogEvent('change-slide-subprompt')
    updateSlide(slideRef, { subPrompt: subPromptState })
      .catch( error => LogError('update this slide subprompt', error) )
  }, 1000)

  // if we get new info from the database, set the state right away
  useEffect( () => {
    setPromptState(slide.prompt)
  }, [slide.prompt])
  useEffect( () => {
    setSubPromptState(slide.subPrompt)
  }, [slide.subPrompt])

  // show/hide subprompt logic
  const [ titleIsFocused, setTitleIsFocused ] = useState(false)
  const [ subPromptIsFocused, setSubPromptIsFocused ] = useState(false)
  const [ subPromptHeight, setSubPromptHeight ] = useState(null)
  const slideHasSubPromptText = slide.subPrompt && slide.subPrompt.trim() !== ''

  // card detail
  const [openedCard, openCardDetail] = useState(false)

  return <PromptSlideWrapper>
    <div>
      {isFacilitator ?
        <PromptSlideTitleEditable
          value={promptState}
          maxLength={1000}
          placeholder="Type a question..."
          onFocus={ () => setTitleIsFocused(true) }
          onBlur={ () => setTitleIsFocused(false) }
          onChange={event => {
            console.log('input in prompt')
            const prompt = event.target.value
            setPromptState(prompt)
            updatePromptInDatabaseDebounced(prompt)
          }}
          tabIndex="100"
        />
        :
        <PromptSlideTitle>{slide.prompt}</PromptSlideTitle>
      }

      {isFacilitator &&
        <PromptSlideSubPromptEditableWrapper
          visible={slideHasSubPromptText || titleIsFocused || subPromptIsFocused }
          subPromptHeight={subPromptHeight}
        >
          <PromptSlideSubPromptEditable
            value={subPromptState}
            maxLength={3000}
            placeholder="Add more (optional)"
            onHeightChange={ height => setSubPromptHeight(height) }
            onFocus={ () => setSubPromptIsFocused(true) }
            onBlur={ () => setSubPromptIsFocused(false) }
            onChange={event => {
              console.log('input in subprompt')
              const subPrompt = event.target.value
              setSubPromptState(subPrompt)
              updateSubPromptInDatabaseDebounced(subPrompt)
            }}
            tabIndex="100"
          />
        </PromptSlideSubPromptEditableWrapper>
      }

      {!isFacilitator && slideHasSubPromptText &&
        <PromptSlideSubPrompt>
          <Linkify
            tagName="span"
            options={{
              className: false,
            }}
          >
            {slide.subPrompt || ""}
          </Linkify>
        </PromptSlideSubPrompt>
      }

    </div>

    <PromptSlideContent isFacilitator={isFacilitator}>

      {
        cardSnaps.length === 0 ?
        <CardEmptyStateWrapper>Waiting for answers...</CardEmptyStateWrapper>
        :
        <CardList 
          board={board}
          isFacilitator={isFacilitator}
          slideRef={slideRef}
          cardSnaps={cardSnaps}
          openCardDetail={openCardDetail}
        />
      }
      
      {isNotSubscribed &&
        <PromptSlideContentUpgradePrompt>
          <i className="material-icons-round" style={{fontSize: 14, marginLeft: '.33rem', position: 'relative', top: '2px'}}>lock</i>
          {' '}
          { isFacilitator ? 'You are' : 'This team is' } limited to 2 boards, 3 slides, and 3 participants
          { isFacilitator &&
            <>
              {' - '}
              <AppLink
                as="span"
                to=""
                onClick={ () => {
                  LogEvent('click-subscription')
                  history.push("/subscribe")
                }}
              >
                See upgrade options
              </AppLink>
            </>
          }
        </PromptSlideContentUpgradePrompt>
      }

    </PromptSlideContent>

    {openedCard &&
      <CardDetailContainer
        closeCardDetails={() => openCardDetail(false)}
        isFacilitator={isFacilitator}
        board={board}
        slides={slides}
        slideRef={slideRef}
        cardId={openedCard}
      />
    }

  </PromptSlideWrapper>
}

const PromptSlideWrapper = styled.div`
  display: grid;
  // https://css-tricks.com/preventing-a-grid-blowout/
  grid-template-rows: max-content minmax(0, auto);
  grid-gap: 0.5rem;
`
const PromptSlideTitle = styled.h1`
  // preserve line breaks for text areas
  // https://stackoverflow.com/questions/40417527/how-do-i-preserve-line-breaks-when-getting-text-from-a-textarea/40426477#40426477
  white-space: pre-wrap;

  // keep long links from busting out the side and breaking layout
  overflow: hidden;
  text-overflow: ellipsis;
  
  display: block;
  color: var(--text-primary);
  font-size: var(--xxl);
  line-height: 1.1;
  font-weight: 600;
  font-family: var(--font-family-serif) !important;
  width: 100%;

  padding: .5rem 0;
`
const PromptSlideTitleEditable = styled(TextareaAutosize)`
  display: block;
  color: var(--text-primary);
  font-size: var(--xxl);
  line-height: 1.1;
  font-weight: 600;
  font-family: var(--font-family-serif) !important;
  width: calc(100% + 1rem);

  margin: 0rem -0.5rem 0;
  border: none;
  padding: .25rem .5rem;
  border-radius: 4px;
  resize: none;
  cursor: pointer;
  
  &:hover {
    box-shadow: inset 0 0 0 1px var(--hairline);
  }

  outline: none;
  &:focus {
    box-shadow: inset 0 0 0 1px var(--hairline-dark);
  }
`
const PromptSlideSubPrompt = styled.div`
  // preserve line breaks for text areas
  // https://stackoverflow.com/questions/40417527/how-do-i-preserve-line-breaks-when-getting-text-from-a-textarea/40426477#40426477
  white-space: pre-wrap;

  // keep long links from busting out the side and breaking layout
  overflow: hidden;
  text-overflow: ellipsis;
  
  color: var(--text-secondary);
  font-weight: 500;
  font-size: var(--m);
  width: 100%;

  margin: .25rem 0;

  a {
    color: var(--blue-70);

    &:hover {
      text-decoration: underline;
    }
  }
`
const PromptSlideSubPromptEditableWrapper = styled.div`
  height: ${p => p.visible ? p.subPromptHeight+'px' : 0 };
  opacity: ${p => p.visible ? 1 : 0 };
  transition: all .1s ease;
`
const PromptSlideSubPromptEditable = styled(TextareaAutosize)`
  color: var(--text-secondary);
  font-weight: 500;
  font-size: var(--m);
  width: calc(100% + 1rem);
  font-family: var(--font-family-sans) !important;
  line-height: 1.4;

  margin: 0rem -0.5rem 0;

  display: block;
  border: 1px solid transparent;
  padding: .25rem .5rem;
  border-radius: 4px;
  resize: none;
  cursor: pointer;
  
  &:hover {
    box-shadow: inset 0 0 0 1px var(--hairline);
  }
  
  outline: none;
  &:focus {
    box-shadow: inset 0 0 0 1px var(--hairline-dark);
  }
`
const PromptSlideContent = styled.div`
  display: grid;
  grid-template-rows: auto max-content;

  background: var(--background-page-dark);
  border-radius: 4px;

  // logic for prompt to fit
  padding: 0 0 ${p => p.isFacilitator ? '0' : '3rem'};

  @media (max-width: 599px) {
    padding: 0 0 ${p => p.isFacilitator ? '3rem' : '1rem'};
  }
`
const PromptSlideContentUpgradePrompt = styled.div`
  padding: .5rem;
  text-align: center;
  font-size: var(--xs);
  border-radius: 4px;
  background: var(--background-page-dark);
  box-shadow: 0 -10px var(--background-page-dark);
  font-weight: 500;
  color: var(--text-secondary);

  ${AppLink} {
    color: inherit;
    text-decoration: underline;
  }
`

const CardEmptyStateWrapper = styled.div`
  color: var(--text-secondary);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem;
  height: 100%;
`

export default PromptSlide
