import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import LogEvent from 'actions/LogEvent'
import CopyLink from 'views/_components/CopyLink'
import ParticipantListItem from 'views/_components/ParticipantListItem'
import {
  Button,
} from 'styles/buttons'

const MAX_PARTICIPANTS = 3

const InviteMenu = ({
  participants,
  isNotSubscribed,
  isFacilitator,
}) => {
  const history = useHistory()

  useEffect(() => {
    LogEvent('show-board-invite-links')
  }, [])

  return <div>

    <h2>Participants</h2>

    <CopyLink disabled={isNotSubscribed && participants.length >= MAX_PARTICIPANTS} />

    {
      isNotSubscribed &&
      <div>
        <h3 style={{margin: '2rem 0 0.5rem'}}>Your free trial is limited to 3 people per board</h3>
        <Button
          green="green"
          as="span"
          to=""
          wide
          onClick={ () => {
            LogEvent('click-subscription')
            history.push("/subscribe")
          }}
        >
          Upgrade options
        </Button>
        <hr />
      </div>
    }

    {participants.map(participant => (
      <ParticipantListItem
        key={participant.id}
        participant={participant}
        isFacilitator={isFacilitator}
      />
    ))}

    <p style={{ fontSize: 'var(--s)', margin: '1rem 0 0' }}>
      Anyone can join, add ideas and vote.
      Only facilitators can switch slides or edit the board.
    </p>
  </div>
}

export default InviteMenu
