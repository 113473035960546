import React from 'react'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import updateBoard from 'actions/updateBoard'
import ToggleSwitch from 'views/_components/ToggleSwitch'
import {
  DropdownMenuAction,
} from 'styles/dropdown'

const AllowVotingAnytime = ({
  board,
  boardRef,
}) => {
  const votingAlwaysAllowed = board.displayOptions && board.displayOptions.allowVotingAnytime

  return <DropdownMenuAction onClick={() => {
    LogEvent('voting-always-allowed')
    updateBoard(boardRef, {displayOptions: {allowVotingAnytime: !votingAlwaysAllowed}})
      .catch( error => LogError('change voting anytime status', error) )
  }}>
    <i className="material-icons">thumb_up_off_alt</i>
    Allow voting anytime
    <ToggleSwitch on={votingAlwaysAllowed} />
  </DropdownMenuAction>
}

export default AllowVotingAnytime