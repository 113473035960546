const getCardPositions = ({ cards, containerWidth, containerHeight }) => {

  const marginBottom = 10
  const marginRight = 20

  // set the card width based on container width only
  const cardWidth = containerWidth < 600 ? containerWidth :
    containerWidth < 900 ? (containerWidth / 2) - marginRight :
    300

  // state for our layout calculations
  let currentColumn = 0
  let lastCardBottom = 0

  // calculate for each card...
  return cards.map( (card, index ) => {
    
    // if we haven't rendered?
    // do something here to optimize?
    const cardHeight = card.height || 100

    let cardTop
    let cardLeft

    const potentialCardBottom = lastCardBottom + marginBottom + cardHeight

    if (potentialCardBottom > containerHeight) {
      // start of a new column (but not the first column)
      currentColumn = currentColumn + 1
      cardTop = 0
    } else {
      cardTop = index === 0 ? 0 : lastCardBottom + marginBottom
    }

    cardLeft = (currentColumn * cardWidth) + (currentColumn * marginRight)

    lastCardBottom = cardTop + cardHeight

    return {
      top: cardTop,
      left: cardLeft,
      width: cardWidth,
      ...card,
    }
  })

}

export default getCardPositions