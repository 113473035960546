const TIMER_PRESETS = [
  {
    text: '10 seconds',
    duration: 10,
    warnAfter: 3,
  },
  {
    text: '30 seconds',
    duration: 30,
    warnAfter: 5,
  },
  {
    text: '1 minute',
    duration: 1*60,
    warnAfter: 10,
  },
  {
    text: '3 minutes',
    duration: 3*60,
    warnAfter: 30,
  },
  {
    text: '5 minutes',
    duration: 5*60,
    warnAfter: 60,
  },
  {
    text: '10 minutes',
    duration: 10*60,
    warnAfter: 2*60,
  },
  {
    text: '30 minutes',
    duration: 30*60,
    warnAfter: 5*60,
  },
  {
    text: '1 hour',
    duration: 60*60,
    warnAfter: 10*60,
  },
]

export default TIMER_PRESETS
