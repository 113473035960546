import React, { useState } from 'react'
import Firebase from 'firebase/app'
import { useDocument } from 'react-firebase-hooks/firestore'

import updateUser from 'actions/updateUser'
import LogError from 'actions/LogError'
import ErrorPage from 'views/_components/ErrorPage'
import { LoadingPage } from 'styles/loading'
import ErrorLayout from 'views/_layouts/ErrorLayout'
import ChooseInternalExternal from 'views/account/ChooseInternalExternal'
import ChooseRoleType from 'views/account/ChooseRoleType'
import Chatbox from 'views/_components/Chatbox'
import { AppLink, Button } from 'styles/buttons'

const EnsureOrgHasLoaded = ({
  orgId,
  switchOrg,
  user,
  userId,
  children,
}) => {

  const [isWorking, setIsWorking] = useState(false)
  
  const leaveOrg = () => {
    setIsWorking(true)
    const knownMemberOrgIds = user.knownMemberOrgIds.filter(oid => oid !== orgId)
    return updateUser(userId, {
      knownMemberOrgIds,
      mostRecentOrgId: Firebase.firestore.FieldValue.delete(),
    })
      .then( () => {
        setIsWorking(false)
      
        // don't switch the org to null until the user's known orgs is officially updated,
        // otherwise there's an infinite loop where the app will immediately re-join the old org
        switchOrg(null)
      })
      .catch( error => {
        setIsWorking(false)
        LogError('update your current organization', error)
      })
  }

  const orgRef = Firebase.firestore().doc(`/organizations/${orgId}`)

  const [orgSnapshot, orgLoading, orgError] = useDocument(
    orgRef,
    { snapshotListenOptions: { includeMetadataChanges: true }, }
  )

  if (orgLoading) {
    return <LoadingPage>Loading</LoadingPage>
  }
  
  if (orgError?.message?.includes('insufficient')) {
    return <ErrorLayout title="No access">
      <h1>Sorry, you don't have access to this organization anymore</h1>
      <br />
      <p>The owner of your team's Shuffleboard account may have removed your access.</p>
      <p>
        If you think this is an error,
        {' '}
        <AppLink to="" as="a" href="https://getshuffleboard.com/contact" target="_blank" rel="noreferrer">contact our support team</AppLink>
        {' '}
        and we'll get it straighted out right away.
      </p>

      <br />

      <div>
        <Button working={isWorking} onClick={leaveOrg}>Leave organization</Button>
      </div>
      
      <Chatbox />
    </ErrorLayout>
  }

  if (orgError) {
    return <ErrorPage error={orgError} />
  }

  const org = orgSnapshot.data()
  const isFromCache = orgSnapshot.metadata && orgSnapshot.metadata.fromCache

  if (isFromCache) {
    return <LoadingPage>Loading</LoadingPage>
  }

  if (!org) {
    // if the org was deleted?
    // MAYBE this cannot happen because it would mean that the user has access to an empty org,
    // but access requires that org to have members...
    // although deleting an org may not delete subcollections
    // we don't support org deletes atm though anyway
    // TODO - allow them to leaveOrg here too?
    return <ErrorPage error={new Error(`Cannot find this organization`)} />
  }

  // ONBOARDING STEP 3
  if (!user.onboardingRoleType) {
    return <ChooseRoleType org={org} userId={userId} />
  }
  
  // ONBOARDING STEP 4
  if (!org.onboardingInternalExternal) {
    return <ChooseInternalExternal orgRef={orgRef} org={org} />
  }
  
  return children
}

export default EnsureOrgHasLoaded
