// Single source of truth for analytics events
// needed to generate an event whitelist in ActiveCampaign

const EVENT_TYPES = {

  // accounts
  'log-in-google': {
    category: 'People',
    action: 'Logged in with Google',
  },
  'sign-up-email': {
    category: 'People',
    action: 'Signed up with email',
  },
  'log-in-email': {
    category: 'People',
    action: 'Logged in with email',
  },
  'log-in-anon': {
    category: 'People',
    action: 'Logged in anonymously',
  },
  'log-out': {
    category: 'People',
    action: 'Logged out',
  },
  'log-out-to-create-account': {
    category: 'People',
    action: 'Logged out to create a real account',
  },
  'edit-notifications': {
    category: 'People',
    action: 'Changed notification settings',
  },
  'request-password-reset': {
    category: 'People',
    action: 'Requested password reset',
  },
  'change-password': {
    category: 'People',
    action: 'Changed password',
  },
  'choose-name': {
    category: 'People',
    action: 'Chose a user name',
  },
  'choose-job-role': {
    category: 'People',
    action: 'Chose a job role',
  },
  
  // orgs
  'create-org': {
    category: 'Organizations',
    action: 'Created an organization',
  },
  'change-org-name': {
    category: 'Organizations',
    action: 'Changed an organization name',
  },
  'choose-internal-external': {
    category: 'Organizations',
    action: 'Chose internal or external attendees',
  },

  // boards
  'add-board': {
    category: 'Boards',
    action: 'Created new board',
  },
  'duplicate-board': {
    category: 'Boards',
    action: 'Duplicated a board',
  },
  'rename-board': {
    category: 'Boards',
    action: 'Renamed a board',
  },
  'move-board': {
    category: 'Boards',
    action: 'Moved a board to a different organization',
  },
  'delete-board': {
    category: 'Boards',
    action: 'Deleted a board',
  },
  'show-board-invite-links': {
    category: 'Boards',
    action: 'Showed board invite links',
  },
  'share-pdf': {
    category: 'Boards',
    action: 'Shared PDF file',
  },
  'share-docx': {
    category: 'Boards',
    action: 'Shared MS Word doc',
  },
  'share-csv': {
    category: 'Boards',
    action: 'Shared CSV file',
  },
  'toggle-hide-cards-until-clicked': {
    category: 'Boards',
    action: 'Toggled hide cards until clicked',
  },
  'show-hide-card-authors': {
    category: 'Boards',
    action: 'Showed or hid card authors',
  },
  'voting-always-allowed': {
    category: 'Boards',
    action: 'Toggled voting always allowed option',
  },
  'participants-may-roam': {
    category: 'Boards',
    action: 'Toggled participant roaming',
  },
  'restart-tour': {
    category: 'Boards',
    action: 'Restarted board onboarding tour',
  },

  // slides
  'create-slide': {
    category: 'Slides',
    action: 'Created a slide',
  },
  'choose-slide-type': {
    category: 'Boards',
    action: 'Chose new slide type',
  },
  'change-slide-prompt': {
    category: 'Slides',
    action: `Changed a slide prompt`,
  },
  'change-slide-subprompt': {
    category: 'Slides',
    action: `Changed a slide subprompt`,
  },
  'delete-slide': {
    category: 'Slides',
    action: 'Deleted a slide',
  },
  'request-votes': {
    category: 'Slides',
    action: 'Requested votes',
  },
  'start-timer': {
    category: 'Slides',
    action: 'Started slide timer',
  },

  // cards
  'create-card': {
    category: 'Cards',
    action: 'Created a card',
  },
  'change-card-contents': {
    category: 'Cards',
    action: `Changed a card contents`,
  },
  'change-card-description': {
    category: 'Cards',
    action: `Changed a card description`,
  },
  'move-card': {
    category: 'Cards',
    action: `Moved card to new slide`,
  },
  'delete-card': {
    category: 'Cards',
    action: 'Deleted a card',
  },
  'stack-card': {
    category: 'Cards',
    action: 'Stacked a card',
  },
  'unstack-card': {
    category: 'Cards',
    action: 'Unstacked a card',
  },
  'add-card-reaction': {
    category: 'Card reactions',
    action: 'Reacted to a card',
  },
  'remove-card-reaction': {
    category: 'Card reactions',
    action: 'Removed a reaction',
  },
  'show-hide-card': {
    category: 'Cards',
    action: 'Showed or hid a card',
  },
  'sort-cards': {
    category: 'Cards',
    action: 'Sorted cards by vote count',
  },

  // subscriptions
  'click-subscription': {
    category: 'Subscriptions',
    action: 'Clicked upgrade button',
  },
  'subscribed': {
    category: 'Subscriptions',
    action: 'Subscribed to a plan',
  },
  'manage-subscription': {
    category: 'Subscriptions',
    action: 'Managed a subscription',
  },


  // members
  'invite-member': {
    category: 'People',
    action: 'Invited someone to an organization',
  },
  'remove-org-member': {
    category: 'People',
    action: 'Removed an org member',
  },
  'delete-invite': {
    category: 'People',
    action: 'Deleted an invite',
  },

}

export default EVENT_TYPES
