import React from 'react'
import { FirestoreDocument } from 'react-firestore'

import LogError from 'actions/LogError'
import {
  DropdownMenuAction,
} from 'styles/dropdown'

const OrgSwitcher = ({
  user,
  orgId,
  switchOrg,
}) => {

  if (user.knownMemberOrgIds.length <= 1) {
    return null
  }

  return <>
    <hr />
    { user.knownMemberOrgIds.map(knownMemberOrgId => {
      return <FirestoreDocument key={knownMemberOrgId} path={`/organizations/${knownMemberOrgId}`}>
        {({isLoading, error, data}) => {
          if (isLoading) {
            return '...'
          }
          if (error) {
            // likely because they don't have permission
            LogError('get your user information', error, true)
            return null
          }

          const organization = data
          
          return <DropdownMenuAction onClick={() => switchOrg(knownMemberOrgId) }>
            <i className="material-icons-round">business</i>
            {organization.name || 'Unknown org'}
            {orgId === knownMemberOrgId && ' (current)'}
          </DropdownMenuAction>

        }}
      </FirestoreDocument>
    })}
  </>
}

export default OrgSwitcher
