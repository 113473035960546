import { prepareDocForCreate } from './helpers/firestoreHelpers'

const createReaction = cardRef => {
  return cardRef
    .collection('reactions')
    .add(prepareDocForCreate({
      type: 'like',
    }))
}

export default createReaction
