import { prepareDocForUpdate } from './helpers/firestoreHelpers'

const updateBoardMember = ({boardRef, userId, values}) => {
  console.log('updating board member', boardRef.id, userId)

  return boardRef
    .collection('members')
    .doc(userId)
    .set(prepareDocForUpdate(values), {merge: true})
}

export default updateBoardMember
