import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

import KeepUserUpdated from 'views/_util/KeepUserUpdated'
import PageChangeListener from 'views/_util/PageChangeListener'
import SortableCombinableDemo from 'views/_util/SortableCombinable/demo'
import SortableCombinableDndDemo from 'views/_util/SortableCombinableDndKit/demo'
import SortableDemo from 'views/_util/Sortable/demo'
import CardLayoutDemo from 'views/_util/CardLayout/demo'

import ErrorBoundary from 'views/ErrorBoundary'
import websiteRedirects from 'data/websiteRedirects'
import ErrorPage from 'views/_components/ErrorPage'
import ReportContainer from 'views/reports/ReportContainer'
import BoardsContainer from 'views/boards/BoardsContainer'
import BoardContainer from 'views/board/BoardContainer'
import NewBoardContainer from 'views/board/new_board/NewBoardContainer'
import LogIn from 'views/account/LogIn'
import SignUp from 'views/account/SignUp'
import RequireAccount from 'views/account/RequireAccount'
import ResetPassword from 'views/account/ResetPassword'
import User from 'views/admin/User'
import Org from 'views/admin/Org'
import Board from 'views/admin/Board'
import AcceptInviteContainer from 'views/account/AcceptInviteContainer'
import SettingsContainer from 'views/settings/SettingsContainer'
import EmailNotifications from 'views/settings/EmailNotifications'
import SubscribeContainer from 'views/subscribe/SubscribeContainer'
import ThankYou from 'views/subscribe/ThankYou'
import ScrollToTop from 'views/_components/ScrollToTop'

// https://stackoverflow.com/questions/49130876/how-to-integrate-error-boundary-in-components-routed-using-react-router
const RouteEB = props => (
  <ErrorBoundary key={props.location?.pathname}>
      <Route {...props} />
  </ErrorBoundary>
)

const AppRouter = () => {
  return <Router>
  
    <ScrollToTop />
  
    <Switch>

      {/* login/signup */}
      <RouteEB path="/login" component={LogIn} />
      <RouteEB path="/signup" component={SignUp} />
      <RouteEB path="/signin" component={SignUp} />
      <RouteEB path="/require_account" component={RequireAccount} />
      <RouteEB path="/reset_password" component={ResetPassword} />
      <RouteEB path="/accept_invite" component={AcceptInviteContainer} />

      <RouteEB path="/new" render={() => <NewBoardContainer popup />} />

      {/* sharable routes - anyone can view these screens w/ a link */}
      <RouteEB path="/b/:orgId/:boardId" component={BoardContainer} />

      {/* private app routes */}
      <RouteEB path="/boards" component={BoardsContainer} />
      <RouteEB path="/templates" render={() => <NewBoardContainer />} />

      {/* TODO - remove this entirely once report PDFs come in emails */}
      <RouteEB path="/report/:orgId/:boardId" component={ReportContainer} />

      <RouteEB path="/settings" component={SettingsContainer} />
      <RouteEB path="/email_notifications" component={EmailNotifications} />

      <RouteEB path="/subscribe" component={SubscribeContainer} />
      <RouteEB path="/thankyou" component={ThankYou} />

      {/* admin */}
      <RouteEB path="/admin/user" component={User} />
      <RouteEB path="/admin/org" component={Org} />
      <RouteEB path="/admin/board" component={Board} />
      <RouteEB path="/admin" component={User} />

      {/* internal tests */}
      <RouteEB path="/dnd-kit" component={SortableDemo} />
      <RouteEB path="/dnd-kit-orig" component={SortableCombinableDndDemo} />
      <RouteEB path="/react-dnd" component={SortableCombinableDemo} />
      <RouteEB path="/card-layout" component={CardLayoutDemo} />

      {/* old paths */}
      <RouteEB exact path="/">
        <Redirect to="/boards" />
      </RouteEB>

      {/* website redirects */}
      {websiteRedirects.map( ({ path, url }) => (
        <RouteEB
          key={url}
          path={path}
          component={ () => { 
            // https://stackoverflow.com/a/44563899/1061063
            const subdomain = process.env.REACT_APP_ENV === 'dev' ? 'dev' :
                              process.env.REACT_APP_ENV === 'stage' ? 'stage' :
                              'live'
            window.location.href = `https://${subdomain}.getshuffleboard.com${url}`
            return null
          }}
        />
      ))}
      
      {/* fallback */}
      <RouteEB render={() => <ErrorPage error={new Error('Page not found')} /> } />

    </Switch>

    {/* bonus stuff */}
    <KeepUserUpdated />
    <RouteEB path="/" component={PageChangeListener}/>

  </Router>
}

export default AppRouter
