import React from 'react'
import styled from 'styled-components/macro'

import TakeoverLayout from 'views/_layouts/TakeoverLayout'
import SortableList from '../SortableList'
import Card from './Card'
import defaultCards from './defaultCards'

const DndKitDemo = () => {
  const combineItems = () => console.log('combineItems!')
  const persistCardPositions = () => console.log('persistCardPositions!')

return <TakeoverLayout>
  <CardsWrapper>
    <SortableList
      defaultItems={defaultCards}
      ItemComponent={Card}
      combineItems={combineItems}
      persistItemPositions={persistCardPositions}
      canDrag={true}
      combinable={true}
      displayType={`inline-block`}
      itemMargin={'0 1rem .5rem 0'}
      />
    </CardsWrapper>
  </TakeoverLayout>
}

/* https://css-tricks.com/auto-sizing-columns-css-grid-auto-fill-vs-auto-fit/ */
const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start;
  background: #eee;
  padding: .5rem;
  height: 100%;
  max-height: 30rem;

  /* move? */
  /* overflow-x: auto; */
  /* should never happen */
  /* overflow-y: hidden; */
`

export default DndKitDemo
