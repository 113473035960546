import { prepareDocForCreate } from './helpers/firestoreHelpers'

const createInvite = ({ orgRef, inviteeEmail }) => {
  return orgRef
    .collection('invites')
    .add(prepareDocForCreate({
      inviteeEmail,
    }))
}

export default createInvite
