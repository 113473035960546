import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import Clipboard from 'react-clipboard.js'

const AutoCopyLink = ({
  isFacilitator,
  participants,
  board,
  user,
}) => {
  const [text, setText] = useState('Copy link')
  const [hasShownPrompt, setHasShownPrompt] = useState(false)
  const [promptIsHidden, setPromptIsHidden] = useState(false)

  // need to save timer in state (tried a const in useEffect but didn't work)
  const [timer, setTimer] = useState()

  useEffect( () => {

    // don't copy link for participants
    if (!isFacilitator) {
      return
    }

    // don't copy link if people already joined
    if (participants.length > 1) {
      return
    }

    // this happened, not sure why
    // https://sentry.io/organizations/directed-works/issues/2309409579/?environment=live&project=5242249&referrer=alert_email
    if (!board.createdOn) {
      return
    }

    // don't show for boards created more than X seconds ago
    if (board.createdOn.toMillis() < (new Date()).getTime() - 20 * 1000 ) {
      return
    }
    
    // don't do it twice
    if (hasShownPrompt) {
      return
    }

    setHasShownPrompt(true)

    setTimer(setTimeout( () => setPromptIsHidden(true), 10 * 1000))
    
    return () => clearTimeout(timer)

  }, [isFacilitator, participants.length, timer, hasShownPrompt, setTimer, board.createdOn])

  if (!user.hasClosedBoardTourNavStep || !user.hasClosedBoardTourSettingsStep || !user.hasClosedBoardTourInputStep) {
    return null
  }

  return <AutoCopyLinkWrapper visible={hasShownPrompt && !promptIsHidden}>

    <span>Your board is ready to go!</span>

    {/* browsers require us to click a button to copy */}
    <Clipboard
      data-clipboard-text={window.location.href}
      component={CopyButton}
      onSuccess={() => {
        setText('Copied!')
        setTimeout(() => setPromptIsHidden(true), .33 * 1000)
      }}
    >
      {text}
    </Clipboard>

    <Closer onClick={() => setPromptIsHidden(true)}>✕</Closer>
  </AutoCopyLinkWrapper>
}

const CopyButton = styled.button`
  background: var(--green-60);
  border-radius: 4px;
  border: none;
  color: white;
  padding: .25rem .5rem;
  /* margin: 0 0 0 3rem; */
  font-size: var(--s);
  font-family: var(--font-family-sans);
  font-weight: 600;
  outline: none;
  cursor: pointer;
  opacity: .9;

  &:hover {
    opacity: 1;
  }
`
const Closer = styled.span`
  font-size: var(--m);
  cursor: pointer;
  opacity: .9;
  margin: 0 0 0 .66rem;

  &:hover {
    opacity: 1;
  }
`
const AutoCopyLinkWrapper = styled.div`
  position: fixed;
  top: ${p => p.visible ? '1rem' : '-3rem'};
  left: 50%;
  transform: translateX(-50%);
  transition: all .2s ease;
  
  display: grid;
  grid-template-columns: auto max-content max-content;
  grid-column-gap: 1rem;

  opacity: ${p => p.visible ? 1 : 0};
  padding: .5rem .66rem;
  font-weight: 500;
  background: var(--green-96);
  color: var(--green-60);
  border-radius: 4px;
  z-index: 10000;
`

export default AutoCopyLink
