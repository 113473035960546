import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import TextareaAutosize from 'react-textarea-autosize'
import Linkify from 'linkifyjs/react'
import { useDebouncedCallback } from 'use-debounce'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import updateCard from 'actions/updateCard'
import deleteCard from 'actions/deleteCard'
import createReaction from 'actions/createReaction'
import deleteReaction from 'actions/deleteReaction'
import BubbleHeadList from 'views/_components/BubbleHeadList'
import ChildCardContainer from 'views/board/card_detail/ChildCardContainer'
import Dropdown from 'views/_components/Dropdown'
import MoveCardOption from 'views/board/card_detail/MoveCardOption'
import {
  Button,
  ButtonIconWrapper,
} from 'styles/buttons'

const CardDetail = ({
  isFacilitator,
  board,
  slides,
  slideRef,
  card,
  cardRef,
  cardAuthor,
  userIsOwner,
  userReactions,
}) => {

  const votingAlwaysAllowed = board.displayOptions && board.displayOptions.allowVotingAnytime

  const [ contentState,    setContentState    ] = useState(card.content)
  const [ descriptionState, setDescriptionState ] = useState(card.description)

  const updateContentInDatabaseDebounced = useDebouncedCallback( () => {
    console.log('update content in db')
    LogEvent('change-card-contents')  
    updateCard(cardRef, { content: contentState })
      .catch( error => LogError('update this card content', error) )
  }, 1000)

  const updateDescriptionInDatabaseDebounced = useDebouncedCallback( () => {
    console.log('update description in db')
    LogEvent('change-card-description')  
    updateCard(cardRef, { description: descriptionState })
      .catch( error => LogError('update this card description', error) )
  }, 1000)

  // if we get new info from the database, set the state right away
  useEffect( () => {
    setContentState(card.content)
  }, [card.content])
  useEffect( () => {
    setDescriptionState(card.description)
  }, [card.description])

  const [isLoadingVoteAction, setIsLoadingVoteAction] = useState(false)

  // whenever we have a new card voteCount, assume the vote loading state is not loading anymore
  useEffect( () => {
    setIsLoadingVoteAction(false)
  }, [card._voteCount, setIsLoadingVoteAction])
  
  const deleteUserReactions = () => {
    console.log('deleting reactions', userReactions, 'from card', card)
    userReactions.map(ur => {
      LogEvent('remove-card-reaction')
      return deleteReaction(ur.ref)
        .catch( error => LogError('remove this card reaction', error) )
    })
  }

  // Ensure votes are unique by author
  let votes = []
  if (card._votes) {
    card._votes.forEach(_vote => {
      if ( !votes.find( v => v.createdBy === _vote.createdBy) ) {
        votes.push(_vote)
      }
    })
  }

  return <CardDetailWrapper data-test-id="idea-detail">

    <CardDetailMain>
      
      {(isFacilitator || userIsOwner) ?
        <CardDetailContentEditable
          value={contentState}
          placeholder="Card title..."
          maxLength={1000}
          onChange={event => {
            console.log('input in content')
            const content = event.target.value
            setContentState(content)
            updateContentInDatabaseDebounced(content)
          }}
        />
      :
        <CardDetailContent>{card.content}</CardDetailContent>
      }

      {(isFacilitator || userIsOwner) ?
        <CardDetailDescriptionEditable
          value={descriptionState}
          placeholder="Card description..."
          maxLength={3000}
          onChange={event => {
            console.log('input in description')
            const description = event.target.value
            setDescriptionState(description)
            updateDescriptionInDatabaseDebounced(description)
          }}
        />
      :
        <CardDetailDescription>
          <Linkify
            tagName="span"
            options={{
              className: false,
            }}
          >
            {card.description}
          </Linkify>
        </CardDetailDescription>
      }

      <CardDetailBottomBar>
        {/* AUTHOR */}
        {
          cardAuthor &&
          <CardAuthorWrapper>
            Added by
            {' '}
            <CardAuthorName>{cardAuthor.displayName || 'Anonymous'}</CardAuthorName>
          </CardAuthorWrapper>
        }
      </CardDetailBottomBar>


      {(card.childrenIds && card.childrenIds.length > 0) &&
        <div>
          <br />
          {card.childrenIds.map(id => (
            <ChildCardContainer
              key={id}
              cardId={id}
              slideRef={slideRef}
              isFacilitator={isFacilitator}
              />
          ))}
        </div>
      }

    </CardDetailMain>

    <CardDetailSidebar>

      { votingAlwaysAllowed &&
        <Button
          wide
          vertSpace
          tertiary="tertiary"
          working={isLoadingVoteAction}
          onClick={event => {
            event.stopPropagation()
            if (isLoadingVoteAction) {
              return
            }      
            if (userReactions && userReactions.length > 0) {
              setIsLoadingVoteAction(true)
              deleteUserReactions()
            } else {
              setIsLoadingVoteAction(true)
              LogEvent('add-card-reaction')
              createReaction(cardRef)
                .catch( error => LogError('react to this card', error) )
            }
          }}
        >
          <ButtonIconWrapper>
            <i className="material-icons">
              { (userReactions && userReactions.length > 0) ? 'thumb_up' : 'thumb_up_off_alt' }
            </i>
            {(userReactions && userReactions.length > 0) ? 'Unvote' : 'Vote' }
          </ButtonIconWrapper>
        </Button>
      }

      {
        isFacilitator &&
        (board.displayOptions && board.displayOptions.hideNewCards) &&
        <Button
          wide
          vertSpace
          tertiary="tertiary"
          onClick={event => {
            LogEvent('show-hide-card')            
            updateCard(cardRef, { contentVisible: !card.contentVisible })
              .catch( error => LogError('show or hide this card', error) )
          }}
        >
          <ButtonIconWrapper>
            <i className="material-icons-round">{!card.contentVisible ? 'visibility' : 'visibility_off'}</i>
            {card.contentVisible ? 'Hide' : 'Show'} card
          </ButtonIconWrapper>
        </Button>
      }

      { (isFacilitator || userIsOwner) &&
        <Dropdown
          lowered
          toggle={
            <Button
              wide
              vertSpace
              tertiary="tertiary"
            >
              <ButtonIconWrapper>
                <i className="material-icons-round">exit_to_app</i>
                Move...
              </ButtonIconWrapper>
            </Button>
          }
        >
          { () => {
            return slides.map(slide => {
              return <MoveCardOption
                key={slide.id}
                slide={slide}
                slideRef={slideRef}
                cardRef={cardRef}
              />
            })
          }}
        </Dropdown>
      }

      { (isFacilitator || userIsOwner) &&
        <Button
          wide
          vertSpace
          tertiary="tertiary"
          destructive="destructive"
          onClick={ event => {
            const reallyDelete = window.confirm(`Are you sure you want to delete this card? You can't undo this.`)
            if (reallyDelete) {
              LogEvent('delete-card')
              deleteCard(cardRef)
                .catch( error => LogError('delete this card', error) )
            }
          }}
        >
          <ButtonIconWrapper>
            <i className="material-icons-round">delete_outline</i>
            Delete card
          </ButtonIconWrapper>
        </Button>
      }

      {card._voteCount > 0 &&
        <div>
          <hr />
          <h3>Votes</h3>
          <BubbleHeadList
            large
            users={votes.map(vote => ({
              id: vote.createdBy,
              createdOn: vote.createdOn,
            }))}
            />
        </div>
      }

    </CardDetailSidebar>
  </CardDetailWrapper>
}

const CardDetailWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;

  @media(max-width: 599px) {
    grid-template-columns: 1fr;
  }
`
const CardDetailMain = styled.div`
  padding: 2rem;
`
const CardDetailSidebar = styled.div`
  background: var(--background-page);
  padding: 1rem;
`
const CardDetailBottomBar = styled.div`
  display: flex;
  align-items: center;
`
const CardDetailContent = styled.h1`
  // preserve line breaks for text areas
  // https://stackoverflow.com/questions/40417527/how-do-i-preserve-line-breaks-when-getting-text-from-a-textarea/40426477#40426477
  white-space: pre-wrap;

  // keep long links from busting out the side and breaking layout
  overflow: hidden;
  text-overflow: ellipsis;
  
  display: block;
  background: none;
  color: var(--text-primary);
  font-size: var(--xl);
  font-weight: 500;
  margin: 0 1rem 1rem 0;
`
const CardDetailContentEditable = styled(TextareaAutosize)`
  display: block;
  background: none;
  color: var(--text-primary);
  font-size: var(--xl);
  font-weight: 500;
  margin: 0rem -0.5rem 1rem;
  
  font-family: var(--font-family-sans);
  line-height: 1.4;
  width: 100%;
  padding: .5rem;
  border: none;
  border-radius: 4px;
  resize: none;
  cursor: pointer;
  
  &:hover {
    box-shadow: inset 0 0 0 1px var(--hairline);
  }

  outline: none;
  &:focus {
    box-shadow: inset 0 0 0 1px var(--hairline-dark);
  }
`
const CardDetailDescription = styled.div`
  // preserve line breaks for text areas
  // https://stackoverflow.com/questions/40417527/how-do-i-preserve-line-breaks-when-getting-text-from-a-textarea/40426477#40426477
  white-space: pre-wrap;

  // keep long links from busting out the side and breaking layout
  overflow: hidden;
  text-overflow: ellipsis;
  
  display: block;
  background: none;
  color: var(--text-secondary);
  font-size: var(--m);
  margin: 0 0 1rem;
  min-height: 10rem;

  a {
    color: var(--blue-70);

    &:hover {
      text-decoration: underline;
    }
  }
`
const CardDetailDescriptionEditable = styled(TextareaAutosize)`
  display: block;
  background: none;
  color: var(--text-secondary);
  font-size: var(--m);
  margin: 0rem -0.5rem 1rem;
  min-height: 10rem;
  
  font-family: var(--font-family-sans);
  line-height: 1.4;
  width: 100%;
  padding: .5rem;
  border: none;
  border-radius: 4px;
  resize: none;
  cursor: pointer;
  
  &:hover {
    box-shadow: inset 0 0 0 1px var(--hairline);
  }
  
  outline: none;
  &:focus {
    box-shadow: inset 0 0 0 1px var(--hairline-dark);
  }
`
const CardAuthorWrapper = styled.div`
  color: var(--text-primary);
  display: flex;
  align-items: center;
`
const CardAuthorName = styled.div`
  margin: 0 0 0 .5rem;
  line-height: 1;
`

export default CardDetail
