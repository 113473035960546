import React, { useEffect, useState } from 'react'
import { PDFDownloadLink } from '@react-pdf/renderer'

import LogError from 'actions/LogError'
import GetSerializedReportData from 'actions/GetSerializedReportData'
import Report from 'views/reports/Report'

const ReportLink = ({
  orgId,
  boardId,
  children,
}) => {

  let [dataLoading, setDataLoading] = useState(true)
  let [dataError, setDataError] = useState(undefined)
  let [reportData, setReportData] = useState(undefined)

  useEffect( () => {
    GetSerializedReportData({
      orgId,
      boardId,
    })
      .then( reportData => {
        setReportData(reportData)
        setDataLoading(false)
      })
      .catch( error => {
        setDataError(error)
        setDataLoading(false)
        LogError('get a PDF report', error, true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (reportData && !reportData.subscribed) {
    reportData.missingSlideCount = reportData.board.slides.length > 1 ? reportData.board.slides.length - 1 : 0
    reportData.board.slides = reportData.board.slides.slice(0, 2)
  }
  
  if (dataLoading || dataError) {
    return children({
      loading: dataLoading,
      error: dataError,
    })
  }
  
  return <PDFDownloadLink document={<Report {...reportData} />} fileName={`${reportData.board.name} | Shuffleboard report.pdf`}>
    {({ blob, url, loading, error }) => {
      return children({
        loading,
        error,
      })
    }}
  </PDFDownloadLink>
}

export default ReportLink
