import React from 'react'
import { FirestoreCollection } from 'react-firestore'
import { useHistory } from 'react-router-dom'

import LogEvent from 'actions/LogEvent'
import LogError from 'actions/LogError'
import FirebaseAuth from 'views/_util/FirebaseAuth'
import SubscriptionStatus from 'views/_util/SubscriptionStatus'
import OnboardingChecklist from './OnboardingChecklist'

const OnboardingChecklistContainer = ({ boards, orgRef }) => {

  const history = useHistory()

  return <FirebaseAuth>
    { ({isLoading, error, auth}) => {
      if (isLoading || !auth) {
        return null
      }
      
      if (error) {
        LogError('get your auth info', error, true)
        return null
      }

      return <SubscriptionStatus>
        { ({isLoading, error, subscribed, boardsRemaining}) => {

          if (isLoading) {
            return null
          }
          if (error) {
            LogError('get your user information', error, true)
            return null
          }

          return <FirestoreCollection path={`organizations/${orgRef.id}/members/`}>
            {({ isLoading, error, snapshot }) => {
              if (isLoading) {
                return null
              }
              if (error) {
                LogError('show your organization\'s members', error, true)
                return null
              }

              const numMembers = snapshot.size

              return <FirestoreCollection path={`organizations/${orgRef.id}/invites/`}>
                {({ isLoading, error, snapshot }) => {
                  if (isLoading) {
                    return null
                  }
                  if (error) {
                    LogError('show your organization\'s members', error, true)
                    return null
                  }
                  
                  const numInvites = snapshot.size

                  const steps = [
                    {
                      name: `Create your account`,
                      cta: `Sign up`,
                      isComplete: Boolean(orgRef),
                      action: () => history.push('/login')
                    },
                    {
                      name: `Create your first board`,
                      cta: `New board`,
                      isComplete: boards.length >= 1,
                      action: () => history.push('/new')
                    },
                    
                    // TODO - invite participants to a board
                    // need to get all the members from a subcollection and check for people who aren't there
                    // another way: trigger user onboard flag when share is clicked

                    {
                      name: `Invite more facilitators`,
                      desc: `Get help during your session by adding more people to your account`,
                      cta: `Invite`,
                      isComplete: numMembers > 1 || numInvites > 0,
                      action: () => history.push('/settings')
                    },
                    {
                      name: `Upgrade your account`,
                      desc: `You have a 3 participant limit, with only ${boardsRemaining} free board${boardsRemaining ===1 ? '' : 's'} remaining`,
                      cta: `Upgrade`,
                      isComplete: Boolean(subscribed),
                      action: () => {
                        LogEvent('click-subscription')
                        history.push('/subscribe')
                      }
                    },
                  ]
                  
                  const numComplete = steps.filter(s => s.isComplete).length
                  const percentComplete = (numComplete / steps.length) * 100
                  
                  return <OnboardingChecklist
                    steps={steps}
                    firstName={auth?.displayName?.split(' ')[0]}
                    numComplete={numComplete}
                    percentComplete={percentComplete}
                  />

                }}
              </FirestoreCollection>
          
            }}
          </FirestoreCollection>

        }}
      </SubscriptionStatus>

    }}
  </FirebaseAuth>
}

export default OnboardingChecklistContainer
