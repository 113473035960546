import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Firebase from 'firebase/app'

import LogError from 'actions/LogError'
import AccountLayout from 'views/_layouts/AccountLayout'
import { Button, AppLink } from 'styles/buttons'
import Chatbox from 'views/_components/Chatbox'

const AcceptInvite = ({
  orgId,
  inviteId,
  inviter,
  switchOrg,
}) => {

  const history = useHistory()
  const [isAccepting, setIsAccepting] = useState(false)

  const acceptInvite = async () => {
    setIsAccepting(true)
    const acceptOrgInvitation = Firebase.functions().httpsCallable('acceptOrgInvitation')
    acceptOrgInvitation({ orgId, inviteId })
    .then( () => {
        setIsAccepting(false)
        switchOrg(orgId)
        history.push('/boards')
      })
      .catch( error => {
        setIsAccepting(false)
        LogError('accept this invitation', error)
      })
  }

  return <AccountLayout title="Accept invitation">

    <h2>Welcome to Shuffleboard!</h2>

    <p>{inviter.displayName || 'Someone'} has invited you to join their team as a facilitator</p>

    <br />
    <Button
      type="submit"
      wide
      block
      big="big"
      onClick={acceptInvite}
      working={isAccepting}
    >
      Accept invitation
    </Button>

    <hr />

    <AppLink to="/boards">Shuffleboard home</AppLink>

    <Chatbox />

  </AccountLayout>
}

export default AcceptInvite
