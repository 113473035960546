import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import Firebase from 'firebase/app'
import Moment from 'moment'
import Clipboard from 'react-clipboard.js'

import LogError from 'actions/LogError'
import AdminLayout from 'views/_layouts/AdminLayout'
import {
  AdminHeader,
  TwoCol,
  Table,
  TBody,
  Row,
  Cell,
  A,
  Empty,
  Copy,
} from 'styles/admin'

// From src/data
// make sure all cards/slides in the app are ordered correctly
const OrderCardsOrSlides = (a, b) => {
  if ( (a.order || a.order === 0) && (b.order || b.order === 0) ) {
    return a.order - b.order
  }

  // any time one item has an order and the other doesn't, show the item with order
  if (a.order || a.order === 0) {
    return a.order
  }
  if (b.order || b.order === 0) {
    return b.order
  }

  // all comparisons that don't have an order, sort by date created but keep them at end
  // https://stackoverflow.com/a/10124053
  if (a.createdOn && b.createdOn) {
    return (a.createdOn.toMillis() - b.createdOn.toMillis()) + 1*1000*1000
  }

  return 1*1000*1000*1000
}


const AdminBoard = () => {

  const history = useHistory()

  const location = useLocation()
  const searchParams = queryString.parse(location.search)
  const orgId = searchParams?.orgId && decodeURIComponent(searchParams.orgId)
  const boardId = searchParams?.boardId && decodeURIComponent(searchParams.boardId)

  const [isLoading, setIsLoading] = useState(null)
  const [boardInfo, setBoardInfo] = useState(null)

  const getBoardInfo = () => {
    setIsLoading(true)
    const adminGetBoardInfo = Firebase.functions().httpsCallable('adminGetBoardInfo')
    adminGetBoardInfo({ boardId, orgId })
      .then(result => {
        setBoardInfo(result.data)
        setIsLoading(false)
        console.log(result.data)
      })
      .catch( error => {
        setBoardInfo(null)
        setIsLoading(false)
        LogError('get board info', error)
      })
  }

  useEffect( () => {
    if (!boardInfo && orgId && boardId) {
      getBoardInfo()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardInfo, orgId, boardId])

  return <AdminLayout title="Board">

    {isLoading ? 'loading' :
    !boardInfo ? '<-- type info' :
      <div>

        <AdminHeader>
          <div>
            🖼
          </div>
          <div>
            <p>Board</p>
            <h1>{boardInfo.name}</h1>
            <Clipboard component={Copy} data-clipboard-text={boardInfo.id}>{boardInfo.id}</Clipboard>
          </div>
        </AdminHeader>

        <TwoCol>
          <div>
            <Table>
              <TBody>
                <Row>
                  <Cell th>Part of org</Cell>
                  <Cell>
                    <A href={`/admin/org?orgId=${boardInfo.orgId}`}>{boardInfo.orgId}</A>
                  </Cell>
                </Row>
                <Row>
                  <Cell th>Demo?</Cell>
                  <Cell>{boardInfo.isDemoBoard ? '🐥 Demo board' : '🐔 Real board'}</Cell>
                </Row>
                <Row>
                  <Cell th>Display options</Cell>
                  <Cell>{boardInfo.displayOptions ? Object.keys(boardInfo.displayOptions).map(key => `${key}: ${boardInfo.displayOptions[key]}`).join('\n') : '(default)'}</Cell>
                </Row>
                <Row>
                  <Cell th>Pending email changes</Cell>
                  <Cell>{boardInfo.hasChangesSinceLastFacilitatorEmailCheck ? 'YES' : 'no'}</Cell>
                </Row>
                <Row>
                  <Cell th>Facilitator email sent</Cell>
                  <Cell>{boardInfo.hasSentFacilitatorSummaryEmail ? 'sent' : 'NO'}</Cell>
                </Row>
              </TBody>
            </Table>
          </div>
          <div>
            <button onClick={() => {
              if (window.confirm('Are you sure? You will be added as a board member...')) {
                history.push(boardInfo.url)
              }
            }}>
              ✋ Join board
            </button>
            <br />
            <A target="_blank" href={`https://console.firebase.google.com/u/0/project/shuffleboard-${process.env.REACT_APP_ENV}/firestore/data~2Forganizations~2F${boardInfo.orgId}~2Fboards~2F${boardInfo.id}`}>🔥 Firestore console</A>
            <br />
          </div>
        </TwoCol>

        <Table>
          <TBody>
            <Row>
              <Cell th>Created&nbsp;on</Cell>
              <Cell>{Moment(boardInfo.createdOn).fromNow()}</Cell>
              <Cell>{boardInfo.createdOn}</Cell>
            </Row>
            <Row>
              <Cell th>Updated&nbsp;on</Cell>
              <Cell>{Moment(boardInfo.updatedOn).fromNow()}</Cell>
              <Cell>{boardInfo.updatedOn}</Cell>
            </Row>
            <Row>
              <Cell th>Last slide action</Cell>
              <Cell>{Moment(boardInfo.lastSlideAction).fromNow()}</Cell>
              <Cell>{boardInfo.lastSlideAction}</Cell>
            </Row>
          </TBody>
        </Table>
        
        <h2>Slides ({boardInfo.slides.length || 0})</h2>

        {(!boardInfo.slides || boardInfo.slides.length === 0) ?
          <Empty>no board slides</Empty>
          :
          <Table>
            <TBody>
              <Row>
                <Cell th>Current</Cell>
                <Cell th>ID</Cell>
                <Cell th>Order</Cell>
                <Cell th>Type</Cell>
                <Cell th>Prompt</Cell>
              </Row>
              {boardInfo.slides.sort(OrderCardsOrSlides).map(slide => <Row key={slide.id}>
                <Cell>{slide.id === boardInfo.currentSlide ? '➡️' : ''}</Cell>
                <Cell>
                  <Clipboard component={Copy} data-clipboard-text={slide.id}>{slide.id}</Clipboard>
                </Cell>
                <Cell>{slide.order + 1}</Cell>
                <Cell>{slide.type}</Cell>
                <Cell>
                  <strong>{slide.prompt}</strong>
                  <br />
                  {slide.subPrompt}
                </Cell>
              </Row>)}
            </TBody>
          </Table>
        }

        <h2>Members ({boardInfo.members.length || 0})</h2>

        {(!boardInfo.members || boardInfo.members.length === 0) ?
          <Empty>no board members</Empty>
          :
          <Table>
            <TBody>
              <Row>
                <Cell th>ID</Cell>
                <Cell th>Creator?</Cell>
                <Cell th>Name</Cell>
                <Cell th>Updated</Cell>
                <Cell th></Cell>
              </Row>
              {boardInfo.members.map(member => <Row key={member.id}>
                <Cell>
                  <Clipboard component={Copy} data-clipboard-text={member.id}>{member.id}</Clipboard>
                </Cell>
                <Cell>{member.id === boardInfo.createdBy && '⭐️'}</Cell>
                <Cell>
                  <A href={`/admin/user?userId=${member.id}`}>{member.displayName}</A>
                </Cell>
                <Cell>{Moment(member.updatedOn).fromNow()}</Cell>
                <Cell>{member.updatedOn}</Cell>
              </Row>)}
            </TBody>
          </Table>
        }

      </div>
    }
  </AdminLayout>
}

export default AdminBoard
