import React from 'react'

import {
  DropdownMenuAction,
} from 'styles/dropdown'

const GetHelp = () => {
  return <DropdownMenuAction
    as="a"
    target="_blank"
    href="https://getshuffleboard.com/support"
  >
    <i className="material-icons-round">support</i>
    Get help
  </DropdownMenuAction>
}

export default GetHelp
