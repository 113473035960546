import React, { useState } from 'react'

import LogError from 'actions/LogError'
import LogEvent from 'actions/LogEvent'
import updateUser from 'actions/updateUser'
import OnboardingLayout from 'views/_layouts/OnboardingLayout'
import { Button } from 'styles/buttons'
import {
  BigRadioWrapper,
  BigRadioItem,
  BigRadioLabel,
  BigRadioCircle,
  BigRadioInput,
  Input,
  InputNote,
} from 'styles/forms'
import ROLE_TYPES from 'data/RoleTypes'

const ChooseRoleType = ({
  userId,
  org,
}) => {

  const [ role, setRole ] = useState({})
  const [ roleOther, setRoleOther ] = useState('')

  const handleSubmit = event => {
    event.preventDefault()
    LogEvent('choose-job-role')
    updateUser(userId, {
      onboardingRoleType: role.id,
      onboardingRoleTypeOther: roleOther,
    })
      .catch( error => LogError('save your role', error) )
  }

  return <OnboardingLayout>
    <p className="reply">Almost there...</p>
    <h2>What do you do at {org.name || 'your company'}?</h2>

    <form onSubmit={handleSubmit} data-test-id="onboarding-role">
      <BigRadioWrapper>
        {ROLE_TYPES.map(roleType => {
          return <BigRadioItem key={roleType.id}>
            <BigRadioInput
              id={`roleType-${roleType.id}`}
              type="radio"
              name="roleType"
              value={roleType.id}
              onChange={ () => setRole(roleType)}
              required
            />
            <BigRadioLabel htmlFor={`roleType-${roleType.id}`}>
              <BigRadioCircle />
              {roleType.label}
            </BigRadioLabel>
          </BigRadioItem>
        })}
      </BigRadioWrapper>
      <InputNote>Your answer will help us build better features for you</InputNote>

      { role.id === 'other' &&
        <div style={{margin: '1rem 0 0 0'}}>
          <Input
            type="text"
            name="roleTypeOther"
            maxLength={1000}
            value={roleOther}
            onChange={event => setRoleOther(event.target.value)}
            placeholder="What role?"
            style={{width: '100%',}}
            required={role.id === 'other'}
          />
        </div>
      }

      <div style={{textAlign: 'right', margin: '2rem 0 0'}}>
        <Button big="big" type="submit" disabled={!role.id}>
          Next
        </Button>
      </div>

    </form>
  </OnboardingLayout>
}

export default ChooseRoleType
